import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { reduxForm } from "redux-form";
import Grid from "@material-ui/core/Grid";
import ReduxButton from "../../components/ReduxButton";
import ReduxInput from "../../components/ReduxInput";
import ReduxSelect from "../../components/ReduxSelect";
import validate from "./validate";
import Error from "../Error";
import Success from "../Success";
import { getDrivers } from "../../actions/driverActions";
import { createEditRequest } from "../../actions/logActions";

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export class ReassignDrivingdModal extends React.Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    this.setState({ errorMessage: "", successMessage: "" });
    this.props.getDrivers({ skip: 0, limit: 250, status: "ACTIVE" });
  }

  getCoDrivers = () => {
    const dailyDiary = this.props.dailyDiary || [];
    let drivers = [];

    for (let i = 0; i < dailyDiary.length; i++) {
      const _d = dailyDiary[i].coDriver || [];
      drivers = [...drivers, ..._d];
    }
    return drivers;
  };

  save = async ({ driverId, note }) => {
    this.setState({ errorMessage: "", successMessage: "", loading: true });
    const { selectedLog, editType } = this.props;
    const payload = {
      note,
      driverId,
      data: null,
      editType,
      originalLog: selectedLog._id,
      accountId: selectedLog.accountId,
    };
    const response = await this.props.createEditRequest(payload);

    this.setState({ loading: false });

    if (response && response._id) {
      this.setState({
        successMessage: "Edit request is send successfully",
      });
      setTimeout(() => {
        this.props.handleClose();
      }, 1000);
    } else if (response && response.message) {
      this.setState({ errorMessage: response.message });
    } else {
      if (typeof response !== String) {
        this.setState({
          errorMessage: "Something went wrong, please try after time.",
        });
      }
    }
  };

  render() {
    // const drivers = this.getCoDrivers();
    const { classes, handleSubmit, editType } = this.props;
    const { errorMessage, successMessage, loading } = this.state;
    const isSmallScreen = window.innerWidth < 400;

    const drivers =
      editType === "REASSIGN" ? this.getCoDrivers() : this.props.drivers;

    return (
      <Dialog
        fullScreen={isSmallScreen}
        fullWidth={true}
        maxWidth={"md"}
        className={classes.wrapper}
        open={!!this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" onClose={this.props.handleClose}>
          <Typography>{"Assign Driver"}</Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <form noValidate autoComplete="off">
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6}>
                <ReduxSelect
                  name="driverId"
                  label="Driver"
                  keyName={"id"}
                  valueName={"firstName"}
                  valueTwoName={"lastName"}
                  valueThreeName={"email"}
                  valueFourName={"status"}
                  items={drivers}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <ReduxInput name="note" label="Note" />
              </Grid>
            </Grid>
            <Error top={20} message={errorMessage} />
            <Success top={20} message={successMessage} />
            <DialogActions className={classes.DialogActions}>
              <ReduxButton
                width
                loading={loading}
                onClick={handleSubmit(this.save)}
                marginTop={25}
                label={"SAVE"}
              />
              <Button
                onClick={this.props.handleClose}
                color="primary"
                autoFocus
              >
                CANCEL
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    );
  }
}

ReassignDrivingdModal = reduxForm({
  form: "reassignDrivingForm",
  validate,
})(ReassignDrivingdModal);

const mapStateToProps = (state) => {
  return {
    drivers: _get(state, "driverData.drivers", []),
    selectedLog: _get(state, "logData.selectedLog", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getDrivers, createEditRequest }, dispatch);

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ReassignDrivingdModal)
);

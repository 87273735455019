import React, { Component } from "react";
import * as d3 from "d3";
import * as constants from "./constants";
import {
    getLineX,
    getChartEvents,
    getChartChangeEvents,
    getTotalTime,
    getChartDurationEvents
} from "./helpers";

class LogChart extends Component {
    getViolationStatus = () => {
        const dailyDiary = this.props.dailyDiary || [];

        let all = {};
        for (var i = 0; i < dailyDiary.length; i++) {
            const sel = dailyDiary[i] || {};

            if (sel.violations && Array.isArray(sel.violations))
                for (var j = 0; j < sel.violations.length; j++) {
                    all[sel.violations[j].statusId] = {};
                }
        }
        return Object.keys(all);
    };

    componentDidMount() {
        const timezone = this.props.timezone;
        const _driverLogs = this.props.driverLogs || [];

        const cycleResetLog = _driverLogs.find(
            item => item.cycleStartTimestamp
        );
        const shiftResetLogs = _driverLogs.filter(
            item => item.shiftReset
        );

        const allowedEvents = [
            "CHANGE_DUTY_STATUS",
            "YARD_MOVES",
            "PERSONAL_USE"
        ];

        const driverLogs = _driverLogs.filter(item => {
            return (
                // (item.recordStatus === "ACTIVE" ||
                //     item.recordStatus === "INACTIVE_CHANGE_REQUEST" ||
                //     item.recordStatus === "INACTIVE_CHANGE_REJECTED") &&
                item.recordStatus === "ACTIVE" &&
                allowedEvents.includes(item.eventType)
            );
        });

        // const vIds = this.getViolationStatus();
        // console.log("vIds :: ", vIds);

        this.drawChart(timezone, driverLogs, cycleResetLog, shiftResetLogs);
    }

    drawChart(timezone, driverLogs, cycleResetLog, shiftResetLogs) {
        const containerId = this.props.containerId;
        const isResetLines = !this.props.hideResetLines;
        const chartHorizontalEvents = getChartEvents(timezone, driverLogs);

        const chartDurationEvents = getChartDurationEvents(
            timezone,
            driverLogs
        );
        const chartChangeEvents = getChartChangeEvents(timezone, driverLogs);
        const totalTime = getTotalTime(timezone, chartHorizontalEvents);

        const border = 1;
        const borderColor = "#bdbdbd";
        const width = (24 * 60) / 2;
        const height = 25 * 4;
        const prefix = 35;
        const textPrefix = 30;
        const topLabels = constants.topLabels;
        const leftLabels = constants.leftLabels;

        //Make an SVG Container
        const mainContainer = d3
            .select(`#${containerId}`)
            .append("svg")
            .attr("width", width + 110)
            .attr("height", height + 60);

        const svgContainer = mainContainer.append("g");
        svgContainer
            .append("rect")
            .attr("x", prefix)
            .attr("y", prefix)
            .attr("width", width)
            .attr("height", height)
            .style("stroke", borderColor)
            .style("fill", "none")
            .style("stroke-width", border);

        const topLabelsGroup = svgContainer.append("g");
        for (let i = 0; i < topLabels.length; i++) {
            const text = topLabels[i];
            topLabelsGroup
                .append("text")
                .attr("dx", textPrefix + 30 * i)
                .attr("dy", "30px")
                .text(text)
                .attr("font-weight", "600")
                .attr("font-size", "12px");
        }

        const leftLabelsGroup = svgContainer.append("g");
        for (let i = 0; i < leftLabels.length; i++) {
            const text = leftLabels[i];
            leftLabelsGroup
                .append("text")
                .attr("dx", "1")
                .attr("dy", 50 + 26 * i)
                .text(text)
                .attr("font-weight", "600")
                .attr("font-size", "12px");
        }

        const boxGroup = svgContainer.append("g");
        for (let i = 0; i < 24; i++) {
            const left = prefix + i * 30;
            for (let j = 0; j < 4; j++) {
                const row = prefix + 25 * j;
                const rowWidth = width / 24;
                const rowHeight = height / 4;

                boxGroup
                    .append("rect")
                    .attr("x", left)
                    .attr("y", row)
                    .attr("width", rowWidth)
                    .attr("height", rowHeight)
                    .style("stroke", borderColor)
                    .style("fill", "none")
                    .style("stroke-width", border);

                const firstLineX1 = 42 + 30 * i;
                const secondLineX1 = 49 + 30 * i;
                const thirdLineX1 = 57 + 30 * i;

                // First row first line
                boxGroup
                    .append("line") // attach a line
                    .style("stroke", borderColor) // colour the line
                    .style("stroke-width", 2) // adjust line width
                    .style("stroke-linecap", "butt") // stroke-linecap type
                    .attr("x1", firstLineX1) // x position of the first end of the line
                    .attr("y1", 35) // y position of the first end of the line
                    .attr("x2", firstLineX1) // x position of the second end of the line
                    .attr("y2", 43); // y position of the second end of the line

                // First row second line
                boxGroup
                    .append("line") // attach a line
                    .style("stroke", borderColor) // colour the line
                    .style("stroke-width", 2) // adjust line width
                    .style("stroke-linecap", "butt") // stroke-linecap type
                    .attr("x1", secondLineX1) // x position of the first end of the line
                    .attr("y1", 35) // y position of the first end of the line
                    .attr("x2", secondLineX1) // x position of the second end of the line
                    .attr("y2", 48); // y position of the second end of the line

                // First row third line
                boxGroup
                    .append("line") // attach a line
                    .style("stroke", borderColor) // colour the line
                    .style("stroke-width", 2) // adjust line width
                    .style("stroke-linecap", "butt") // stroke-linecap type
                    .attr("x1", thirdLineX1) // x position of the first end of the line
                    .attr("y1", 35) // y position of the first end of the line
                    .attr("x2", thirdLineX1) // x position of the second end of the line
                    .attr("y2", 43); // y position of the second end of the line

                // Second row first line
                boxGroup
                    .append("line") // attach a line
                    .style("stroke", borderColor) // colour the line
                    .style("stroke-width", 2) // adjust line width
                    .style("stroke-linecap", "butt") // stroke-linecap type
                    .attr("x1", firstLineX1) // x position of the first end of the line
                    .attr("y1", 60) // y position of the first end of the line
                    .attr("x2", firstLineX1) // x position of the second end of the line
                    .attr("y2", 68); // y position of the second end of the line

                // Second row second line
                boxGroup
                    .append("line") // attach a line
                    .style("stroke", borderColor) // colour the line
                    .style("stroke-width", 2) // adjust line width
                    .style("stroke-linecap", "butt") // stroke-linecap type
                    .attr("x1", secondLineX1) // x position of the first end of the line
                    .attr("y1", 60) // y position of the first end of the line
                    .attr("x2", secondLineX1) // x position of the second end of the line
                    .attr("y2", 73); // y position of the second end of the line

                // First row third line
                boxGroup
                    .append("line") // attach a line
                    .style("stroke", borderColor) // colour the line
                    .style("stroke-width", 2) // adjust line width
                    .style("stroke-linecap", "butt") // stroke-linecap type
                    .attr("x1", thirdLineX1) // x position of the first end of the line
                    .attr("y1", 60) // y position of the first end of the line
                    .attr("x2", thirdLineX1) // x position of the second end of the line
                    .attr("y2", 68); // y position of the second end of the line

                // third row first line
                boxGroup
                    .append("line") // attach a line
                    .style("stroke", borderColor) // colour the line
                    .style("stroke-width", 2) // adjust line width
                    .style("stroke-linecap", "butt") // stroke-linecap type
                    .attr("x1", firstLineX1) // x position of the first end of the line
                    .attr("y1", 102) // y position of the first end of the line
                    .attr("x2", firstLineX1) // x position of the second end of the line
                    .attr("y2", 110); // y position of the second end of the line

                // third row second line
                boxGroup
                    .append("line") // attach a line
                    .style("stroke", borderColor) // colour the line
                    .style("stroke-width", 2) // adjust line width
                    .style("stroke-linecap", "butt") // stroke-linecap type
                    .attr("x1", secondLineX1) // x position of the first end of the line
                    .attr("y1", 96) // y position of the first end of the line
                    .attr("x2", secondLineX1) // x position of the second end of the line
                    .attr("y2", 110); // y position of the second end of the line

                // third row third line
                boxGroup
                    .append("line") // attach a line
                    .style("stroke", borderColor) // colour the line
                    .style("stroke-width", 2) // adjust line width
                    .style("stroke-linecap", "butt") // stroke-linecap type
                    .attr("x1", thirdLineX1) // x position of the first end of the line
                    .attr("y1", 102) // y position of the first end of the line
                    .attr("x2", thirdLineX1) // x position of the second end of the line
                    .attr("y2", 110); // y position of the second end of the line

                // forth row first line
                boxGroup
                    .append("line") // attach a line
                    .style("stroke", borderColor) // colour the line
                    .style("stroke-width", 2) // adjust line width
                    .style("stroke-linecap", "butt") // stroke-linecap type
                    .attr("x1", firstLineX1) // x position of the first end of the line
                    .attr("y1", 128) // y position of the first end of the line
                    .attr("x2", firstLineX1) // x position of the second end of the line
                    .attr("y2", 136); // y position of the second end of the line

                // forth row second line
                boxGroup
                    .append("line") // attach a line
                    .style("stroke", borderColor) // colour the line
                    .style("stroke-width", 2) // adjust line width
                    .style("stroke-linecap", "butt") // stroke-linecap type
                    .attr("x1", secondLineX1) // x position of the first end of the line
                    .attr("y1", 125) // y position of the first end of the line
                    .attr("x2", secondLineX1) // x position of the second end of the line
                    .attr("y2", 136); // y position of the second end of the line

                // forth row third line
                boxGroup
                    .append("line") // attach a line
                    .style("stroke", borderColor) // colour the line
                    .style("stroke-width", 2) // adjust line width
                    .style("stroke-linecap", "butt") // stroke-linecap type
                    .attr("x1", thirdLineX1) // x position of the first end of the line
                    .attr("y1", 128) // y position of the first end of the line
                    .attr("x2", thirdLineX1) // x position of the second end of the line
                    .attr("y2", 136); // y position of the second end of the line
            }
        }

        const totalLine = svgContainer.append("g");

        totalLine
            .append("line")
            .style("stroke", "grey")
            .style("stroke-width", 4)
            .attr("x1", 760)
            .attr("y1", 135)
            .attr("x2", 803)
            .attr("y2", 135);

        if (isResetLines && cycleResetLog && cycleResetLog.cycleStartTimestamp) {
            const cycleResetLine = svgContainer.append("g");

            const cycleResetX = getLineX(
                timezone,
                cycleResetLog.cycleStartTimestamp
            );
            cycleResetLine
                .append("line")
                .style("stroke", "black")
                .style("stroke-width", 2)
                .attr("x1", cycleResetX)
                .attr("y1", 35)
                .attr("x2", cycleResetX)
                .attr("y2", 135);
        }
        if (isResetLines && Array.isArray(shiftResetLogs)) {
            shiftResetLogs.forEach(e => {

                const cycleResetLine = svgContainer.append("g");

                // const cycleResetX = 180;
                const cycleResetX = getLineX(
                    timezone,
                    e.shiftReset
                );
                cycleResetLine
                    .append("line")
                    .style("stroke", "blue")
                    .style("stroke-width", 2)
                    .attr("x1", cycleResetX)
                    .attr("y1", 35)
                    .attr("x2", cycleResetX)
                    .attr("y2", 135);

            })

        }
        const statusLine = svgContainer.append("g");

        chartHorizontalEvents &&
            chartHorizontalEvents.forEach(item => {
                const isPc = item.eventType === "PERSONAL_USE";
                const isYm = item.eventType === "YARD_MOVES";
                const lineType = "line";
                let lineColor = "green";
                // isPc || isYm ? "#feb241" : "green";
                if (item.eventSubType === "EXP:AGIS") {
                    lineColor = "#00000000";
                } else if (item.eventSubType === "EXP:AGIE") {
                    lineColor = "#474747";
                }
                else if (item.eventCode === "SLEEPER") {
                    lineColor = "#FF7E0E";
                }
                else if (item.eventCode === "DRIVING") {
                    lineColor = "#66A104";
                }
                else if (item.eventCode === "ONDUTY_ND") {
                    lineColor = "#0085ec";
                }
                else if (item.eventCode === "OFFDUTY") {
                    lineColor = "#474747";
                } else if (isPc || isYm) {
                    lineColor = "#feb241";
                }

                let lineDash = isPc || isYm ? "6, 2" : "0, 0";

                if (item.eventSubType === "EXP:AGIE") {
                    lineDash = "0,0";
                }

                statusLine
                    .append(lineType)
                    .style("stroke", lineColor)
                    .style("stroke-dasharray", lineDash)
                    .style("stroke-width", 3)
                    .style("stroke-linecap", "butt")
                    .attr("x1", item.start)
                    .attr("y1", item.height)
                    .attr("x2", item.end)
                    .attr("y2", item.height);
            });
        chartDurationEvents &&
            chartDurationEvents.forEach(item => {
                statusLine
                    .append("text")
                    .attr("dx", item.start)
                    .attr("dy", item.height)
                    .text(item.text)
                    .attr("font-weight", "600")
                    .attr("font-size", "10px");
            });

        chartChangeEvents &&
            chartChangeEvents.forEach(item => {
                statusLine
                    .append("line")
                    .style("stroke", "#474747")
                    .style("stroke-width", 1)
                    .style("stroke-linecap", "butt")
                    .attr("x1", item.start)
                    .attr("y1", item.top)
                    .attr("x2", item.start)
                    .attr("y2", item.bottom);
            });

        const rightLabelsGroup = svgContainer.append("g");
        rightLabelsGroup
            .append("text")
            .attr("dx", "760")
            .attr("dy", "53px")
            .text(totalTime.offDuty)
            .attr("font-weight", "600")
            .attr("font-size", "12px");
        rightLabelsGroup
            .append("text")
            .attr("dx", "760")
            .attr("dy", "77px")
            .text(totalTime.sleeper)
            .attr("font-weight", "600")
            .attr("font-size", "12px");
        rightLabelsGroup
            .append("text")
            .attr("dx", "760")
            .attr("dy", "101px")
            .text(totalTime.driving)
            .attr("font-weight", "600")
            .attr("font-size", "12px");
        rightLabelsGroup
            .append("text")
            .attr("dx", "760")
            .attr("dy", "126px")
            .text(totalTime.onDutyND)
            .attr("font-weight", "600")
            .attr("font-size", "12px");
        rightLabelsGroup
            .append("text")
            .attr("dx", "760")
            .attr("dy", "156px")
            .text(totalTime.total)
            .attr("font-weight", "600")
            .attr("font-size", "12px");
    }

    render() {
        return (
            <div
                style={{
                    margin: "5px auto",
                    textAlign: "center",
                    fontWeight: 200,
                    display: "flex",
                    justifyContent: "space-evenly"
                }}
                id={this.props.containerId}
            />
        );
    }
}

export class LogGrid extends React.Component {
    state = {
        open: false
    };

    componentDidMount() { }

    render() {
        const { timezone, driverLogs, dailyDiary } = this.props;
        return (
            <LogChart
                timezone={timezone}
                driverLogs={driverLogs}
                dailyDiary={dailyDiary}
                hideResetLines={this.props.hideResetLines}
                containerId={this.props.containerId}
                formatTime={this.props.formatTime}
            />
        );
    }
}

export default LogGrid;

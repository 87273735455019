import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { getInfoArray } from "./infoHelper";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
    flexGrow: 1,
  },
  table: {
    width: "100%",
    border: "1px solid #f0f0f0",
    padding: 0,
  },
  border: {
    border: "1px solid #f0f0f0",
    textTransform: "capitalize",
  },
  tdOne: {
    width: 150,
    padding: 5,
    backgroundColor: "#f0f0f0",
  },
  tdTwo: {
    padding: 5,
    width: 250,
  },
  tdThree: {
    width: 150,
    padding: 5,
    backgroundColor: "#f0f0f0",
  },
  tdFour: {
    padding: 5,
    width: 250,
  },
  driverHeader: {
    textAlign: "center",
    fontWeight: 500,
  },
  driverTitle: {
    fontWeight: 400,
    fontSize: 12,
    marginTop: 5,
  },
  driverContent: {
    fontSize: 12,
    marginTop: 5,
    paddingLeft: 10,
  },
  carrierHeader: {
    textAlign: "center",
    fontWeight: 500,
    marginTop: 20,
  },
  textRight: {
    textAlign: "right",
  },
  buttonContainer: {
    padding: 8,
  },
  emailStyle: {
    border: "1px solid #f0f0f0",
  },
});

export class DailyStatusDriverInfo extends React.Component {
  render() {
    const { classes, handleClick } = this.props;
    const allItems = getInfoArray(this.props);
    return (
      <table className={`${classes.table}`}>
        <tbody>
          {allItems.map((item) => {
            return (
              <tr key={item.label} className={classes.border}>
                <td className={`${classes.border} ${classes.tdOne}`}>
                  {item.labelLink ? (
                    <span
                      style={{
                        color: "#009ce5",
                        cursor: "pointer",
                      }}
                      onClick={() => handleClick(item.label)}
                    >
                      {item.label}
                    </span>
                  ) : (
                    item.label
                  )}
                </td>
                <td
                  className={`${classes.border} ${classes.tdTwo}`}
                  style={{
                    textTransform:
                      item.label === "EMAIL" || item.label === "TRAILERS"
                        ? "none"
                        : "capitalize",
                  }}
                >
                  {item.value}
                </td>

                <td className={`${classes.border} ${classes.tdOne}`}>
                  {item.labelMiddle}
                </td>
                <td
                  className={`${classes.border} ${classes.tdTwo}`}
                  style={item.valueMiddleStyle}
                >
                  {item.valueMiddle}
                  {item.valueMiddleTwo ? (
                    <small><br/>{item.valueMiddleTwo}</small>
                  ) : (
                    ""
                  )}
                </td>
                <td className={`${classes.border} ${classes.tdThree}`}>
                  {item.labelRight}
                </td>
                <td className={`${classes.border} ${classes.tdFour}`}>
                  {item.valueRight}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default withStyles(styles)(DailyStatusDriverInfo);

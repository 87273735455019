import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ReportNavigator from "../../Navigator/ReportNavigator";

const styles = (theme) => ({});

function Report(props) {
  return (
    <ReportNavigator accountId={props.accountId} settings={props.settings} />
  );
}

const mapStateToProps = (state) => {
  return {
    store: state,
    accountId: _get(state, "authData.accountId"),
    settings: _get(state, "authData.profile.accountProperties.settings", {}),
  };
};

export default withStyles(styles)(connect(mapStateToProps, {})(Report));

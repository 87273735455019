import React from "react";
import _get from "lodash/get";
import { Alert } from "@material-ui/lab";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { VIOLATIONS } from "../../constants";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        margin: "10px auto 15px"
    }
}));

export default function Violations(props) {
    const classes = useStyles();
    const violations = _get(props, "dailyDiary[0].violations", []);

    if (!violations || !Array.isArray(violations) || violations.length === 0) {
        return null;
    }

    return (
        <Grid container spacing={2} className={classes.root}>
            {violations.map((item, idx) => {
                return (
                    <Grid item key={`${item.id}_${idx}`} xs={4} md={4}>
                        <Alert
                            severity="error"
                            style={{ width: "100%", marginTop: 3 }}
                        >
                            {`Violation: ${VIOLATIONS[item.id]}  (${props.formatTime(item.time)})`}
                        </Alert>
                    </Grid>
                );
            })}
        </Grid>
    );
}

import _get from "lodash/get";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  paper: {
    // padding: theme.spacing(1),
    // textAlign: "center",
    // color: theme.palette.text.secondary,\
    height: 14,
  },
  dot: {
    height: 10,
    width: 10,
    backgroundColor: "red",
    borderRadius: "50%",
    display: "inline-block",
    marginRight: 5,
  },
  formsColor: {
    backgroundColor: "#f5cb42",
  },
}));

function TimeCircle({ per, color, time, type, backColor }) {
  return (
    <div className="time-circle svg">
      <h2>{time}</h2>
      <h3>{type}</h3>
      <svg width="160" height="160" xmlns="http://www.w3.org/2000/svg">
        <g>
          <title>{type}</title>
          <circle
            id="circle-back"
            strokeDasharray={440}
            r="69.85699"
            cy="81"
            cx="81"
            strokeWidth="8"
            stroke={backColor}
            fill="none"
          />
          <circle
            id="circle"
            strokeDashoffset={(per * 440) / 100}
            strokeDasharray={440}
            r="69.85699"
            cy="81"
            cx="81"
            strokeWidth="8"
            stroke={color}
            fill="none"
          />
        </g>
      </svg>
    </div>
  );
}

export default function DriverRecap(props) {
  const classes = useStyles();
  const data = props.data || {};
  const violations = data.violations ? parseInt(data.violations) : 0;
  const forms = data.forms ? parseInt(data.forms) : 0;
  const total = violations + forms;
  const violationsPer = data.violations ? Math.round((violations / total) * 100) : 0;
  const formsPer = data.forms ? Math.round((forms / total) * 100) : 0;

  return (
    <div className={classes.root}>
      <div item xs={12}>
        <TimeCircle
          per={formsPer}
          color={"red"}
          time={total}
          type={"Total"}
          backColor={"#f5cb42"}
        />
      </div>
      <div item xs={12}>
        <div>
          <span className={classes.dot}></span>
          {`Violations `}
        </div>
        <div>{`${violations} (${violationsPer}%)`}</div>
        <div>
          <span className={`${classes.dot} ${classes.formsColor}`}></span>
          {`Forms & Signatures `}
        </div>
        <div>{`${forms} (${formsPer}%)`}</div>
      </div>
    </div>
  );
}

import {
    EVENT_TYPE,
    RECORD_ORIGIN_TYPES,
    RECORD_STATUS_TYPE
} from "../../../constantsStatus";
const moment = require("moment-timezone");

export function getPayload(driver, values, selLog, selectedDate) {
    let startdate = moment(selectedDate).startOf("day");
    let midday = startdate.add(moment.duration(6, "hours"));
    let payload = {
        driverId: driver.id,
        data: [
            {
                name: "recordOrigin",
                value: RECORD_ORIGIN_TYPES.EDITED_ENTERED
            },
            {
                name: "recordStatus",
                value: RECORD_STATUS_TYPE.ACTIVE
            },
            {
                name: "eventType",
                value: EVENT_TYPE.CHANGE_DUTY_STATUS
            },
            {
                name: "eventCode",
                value: values.eventCode
            },
            {
                name: "eventTime",
                value: values.eventTime
            },
            {
                name: "annotation",
                value: values.notes
            },
            {
                name: "reason",
                value: values.reason
            },
            {
                name: "odometer",
                value: values.odometer
            },
            {
                name: "state",
                value: values.state
            },
            {
                name: "eventSubType",
                value: "status"
            },
            {
                name: "location",
                value: `${values.location}`
            }
        ],
        status: "NEW",
        note: values.note,
        editType: "ADD",
        edited: midday
    };

    if (selLog && selLog.id) {
        payload.originalLog = selLog.id;
        payload.editType = "EDIT";
    }

    return payload;
}

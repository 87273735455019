import _get from "lodash/get";
import { React, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  TrafficLayer,
  InfoWindow,
} from "react-google-maps";
import DutyStatus from "../../../components/DutyStatus";
import VideocamIcon from "@material-ui/icons/Videocam";
import Button from "@material-ui/core/Button";
import DashCamModal from "./DashCamModal";

const {
  MarkerWithLabel,
} = require("react-google-maps/lib/components/addons/MarkerWithLabel");

const camIconStyle = {
  verticalAlign: "bottom",
  fontSize: "18px !important",
  marginRight: 0,
  width: "1rem",
  height: "1rem",
  cursor: "pointer",
};

function getIntials(item) {
  const driver = item.driver || {};
  const fName = driver.firstName
    ? driver.firstName.slice(0, 1).toUpperCase()
    : "";
  const lName = driver.lastName
    ? driver.lastName.slice(0, 1).toUpperCase()
    : "";

  return fName + lName;
}

function RenderMarker(props) {
  const item = props.driver;
  const [isOpen, setIsOpen] = useState(false);
  const driver = item.driver;
  let heading = parseInt(item.heading || "60");
  const ec = item.eventCode;
  if (ec !== "DRIVING") {
    heading = 0;
  }

  if (heading > 360) {
    heading = heading - 360;
  }

  useEffect(() => {
    if (props.dId) {
      if (props.dId === item.uqId) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }
  });

  const color =
    ec === "DRIVING"
      ? "#1f9a07"
      : ec === "SLEEPER"
      ? "#FF7E0E"
      : ec === "ONDUTY_ND"
      ? "#0085EC"
      : "grey";

  const svgMarker = {
    path:
      ec === "DRIVING"
        ? window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW
        : window.google.maps.SymbolPath.CIRCLE,
    fillColor: color,
    fillOpacity: 1,
    strokeWeight: 0,
    rotation: heading,
    scale: ec === "DRIVING" ? 6 : 10,
  };

  const vehicleName = _get(item, "vehicle.name", "");
  const speed = (_get(item, "telemetrics.speedKm", 0) * 0.621371).toFixed(2);

  return (
    <Marker
      position={{ lat: props.lat, lng: props.lng }}
      icon={svgMarker}
      prerender={false}
      labelAnchor={new window.google.maps.Point(0, 0)}
      labelStyle={{
        backgroundColor: "grey",
        color: "white",
        fontSize: "10px",
        padding: "1px",
      }}
      onClick={() => {
        if (props.dId) {
          props.onInfoWindowClose(item.driverId);
        }
        setIsOpen(true);
        props.onMarkerSelect(item);
      }}
      label={{
        text: getIntials(props.driver),
        fontSize: "9px",
        color: "black",
        fontWeight: "500",
      }}
    >
      {isOpen ? (
        <InfoWindow
          onCloseClick={() => {
            setIsOpen(false);
            props.onInfoWindowClose();
            props.onMarkerReset();
          }}
        >
          <div style={{ textAlign: "center" }}>
            <div
              style={{
                textAlign: "center",
                padding: "5px 2px 0px 2px",
                fontWeight: 450,
                marginTop: "0px",
                fontSize: "12px",
                color: "#333333",
                textTransform: "uppercase",
                cursor: "default",
              }}
            >
              <p style={{ margin: "0", marginBottom: "1px" }}>
                {`${driver?.firstName} ${driver?.lastName}`}
              </p>
              <p style={{ margin: "0", marginBottom: "1px" }}>
                {`Truck# ${vehicleName}`}
                {driver.dcId && (
                  <VideocamIcon
                    onClick={() => props.onDcClick(item)}
                    fontSize="small"
                    style={camIconStyle}
                  />
                )}
              </p>
            </div>

            <div style={{ cursor: "default", fontSize: "12px" }}>
              {`${props.formatDate(item?.eventTime || "")} @ ${props.formatTime(
                item?.eventTime || ""
              )}`}
            </div>
            <div
              style={{
                padding: "5px",
                cursor: "default",
                fontSize: "12px",
              }}
            >
              {item?.location}
            </div>
            {item?.eventCode === "DRIVING" ? (
              <div style={{ cursor: "default", fontSize: "12px" }}>
                {speed} M/hr
              </div>
            ) : null}
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                margin: "5px",
                marginLeft: "5px",
              }}
            >
              <Button
                color="primary"
                style={{
                  height: "25px",
                  width: 90,
                  padding: 3,
                  margin: "0 7px 0 0",
                  borderRadius: 5,
                  fontWeight: 430,
                  textDecoration: "none",
                  fontSize: "13px",
                  border: "1px solid #52514E",
                  color: "#52514E",
                }}
                onClick={() => {
                  const url = `${props.accountBaseUrl}/tracking?id=${item.driver?.id}`;
                  props.navigateToPage(url);
                }}
              >
                ROUTE
              </Button>

              {item?.eventCode ? (
                <DutyStatus
                  status={item}
                  eventType={item?.eventType}
                  eventCode={item?.eventCode}
                  annotation={item?.annotation}
                  eventSubType={item?.eventSubType}
                />
              ) : null}
            </div>
            <div
              style={{
                fontSize: 10,
                textAlign: "center",
                marginTop: 8,
                maxWidth: 200,
              }}
            />
            <div />
          </div>
        </InfoWindow>
      ) : null}
    </Marker>
  );
}

const ReactMap = withScriptjs(
  withGoogleMap((props) => {
    const [dcOpen, setDcOpen] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    let zoom = 12;
    const defaultBounds = [
      { lat: 30.172333, lng: -97.666898 },
      { lat: 46.319839, lng: -95.54907 },
    ];
    let defaultCenter = {
      lat: 41.5,
      lng: -100.0,
    };
    let drivers = props.drivers;
    let dId = props.dId;

    const _sel = drivers.find((item) => item.uqId === dId);

    function zoomToMarkers(map) {
      // setTimeout(() => {
      if (props.dId && _sel && _sel.lat) {
      } else {
        if (drivers && drivers.length) {
          const bounds = new window.google.maps.LatLngBounds();
          drivers.forEach((child) => {
            if (child.lat && child.lng)
              bounds.extend(
                new window.google.maps.LatLng(child.lat, child.lng)
              );
          });
          map && map.fitBounds(bounds);
        }
      }
      // }, 500);
    }

    if (props.dId && _sel && _sel.lat) {
      zoom = 20;
      defaultCenter = {
        lat: _sel.lat,
        lng: _sel.lng,
      };
    }

    function handleDcClick(_selected) {
      setDcOpen(true);
      setSelectedItem(_selected);
    }

    return (
      <>
        {dcOpen ? (
          <DashCamModal
            open={dcOpen}
            handleClose={() => {
              setDcOpen(false);
              setSelectedItem(null);
            }}
            data={selectedItem}
            getDcImages={props.getDcImages}
            getDcDetail={props.getDcDetail}
            formatDate={props.formatDate}
            formatTime={props.formatTime}
          />
        ) : null}
        <GoogleMap
          zoom={zoom}
          center={defaultCenter}
          ref={zoomToMarkers}
          options={{
            minZoom: 4,
            maxZoom: 22,
          }}
        >
          {drivers.map((_driver, idx) => {
            const lat = _driver.lat;
            const lng = _driver.lng;
            // return <Marker position={{ lat: lat, lng: lng }} />;
            return (
              <RenderMarker
                key={`${_driver.driverId}-${idx}`}
                lat={lat}
                lng={lng}
                driver={_driver}
                formatDate={props.formatDate}
                formatTime={props.formatTime}
                onDcClick={handleDcClick}
                dId={props.dId}
                onInfoWindowClose={props.onInfoWindowClose}
                navigateToPage={props.navigateToPage}
                accountBaseUrl={props.accountBaseUrl}
                onMarkerSelect={() => {}}
                onMarkerReset={() => {}}
              />
            );
          })}
          <TrafficLayer autoUpdate />
        </GoogleMap>
      </>
    );
  })
);

export default ReactMap;

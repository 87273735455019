import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        width: 500,
        "& > * + *": {
            marginTop: theme.spacing(3)
        }
    },
    center: {
        textAlign: "center"
    },
    formControl: {
        width: "100%"
    }
}));

export default function VehicleAssignment(props) {
    const driver = props.driver || {};
    const trailers = props.trailers || [];
    const [assigned, setAssigned] = useState(props.selected || []);
    const [vehicleId, setVehicleId] = useState(driver.vehicleId || "");
    const [trailerId, setTrailerId] = useState(driver.trailerId || "");

    const error = props.error || {};
    const errorVehicle = error.vehicle || error.vehicleId;
    const errorEquipments = error.equipments;

    const classes = useStyles();

    const CustomPaper = props => {
        return (
            <Paper
                {...props}
                style={{
                    zIndex: 1305,
                    position: "absolute",
                    bottom: 6,
                    width: 200
                }}
            />
        );
    };

    return (
        <Grid container spacing={1} style={{ marginTop: 10 }}>
            <Grid item xs={12} sm={12} md={6} style={{ position: "relative" }}>
                <Autocomplete
                    multiple
                    size="small"
                    id="assigned-vehicles"
                    options={props.equipments}
                    getOptionLabel={option => {
                        return option && option.name;
                    }}
                    PopperComponent={CustomPaper}
                    defaultValue={assigned}
                    renderInput={params => (
                        <TextField
                            {...params}
                            style={{ paddingTop: 3 }}
                            variant="standard"
                            label="Assigned Vehicle"
                            placeholder="Select"
                        />
                    )}
                    value={assigned}
                    onChange={(e, values) => {
                        setAssigned(values);
                        const ids = values
                            ? values.map(item => {
                                  return item && item.id;
                              })
                            : [];

                        if (!ids.includes(vehicleId)) {
                            setVehicleId("");
                            props.onChange({
                                equipments: ids,
                                vehicleId: "",
                                trailerId
                            });
                        } else {
                            props.onChange({
                                equipments: ids,
                                vehicleId,
                                trailerId
                            });
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
                <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">
                        Current Vehicle
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={vehicleId}
                        disabled={!assigned || assigned.length === 0}
                        onChange={e => {
                            const vId = e.target.value;
                            setVehicleId(vId);
                            const ids = assigned.map(item => item.id) || [];
                            props.onChange({
                                equipments: ids,
                                vehicleId: vId,
                                trailerId
                            });
                        }}
                    >
                        {assigned &&
                            assigned.map(item => {
                                if (!item) {
                                    return null;
                                }
                                return (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
                <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">
                        Current Trailer
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={trailerId}
                        disabled={trailers.length === 0}
                        onChange={e => {
                            const tId = e.target.value;
                            setTrailerId(tId);
                            const ids = assigned.map(item => item.id) || [];
                            props.onChange({
                                equipments: ids,
                                vehicleId,
                                trailerId: tId
                            });
                        }}
                    >
                        {trailers.map(item => (
                            <MenuItem key={item.id} value={item.id}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            {(errorVehicle || errorEquipments) && (
                <Grid item xs={12} sm={12} md={12}>
                    {errorEquipments && (
                        <Alert severity="error" style={{ width: "100%" }}>
                            No vehicle is assigned to driver{" "}
                        </Alert>
                    )}
                    {errorVehicle && (
                        <Alert severity="error" style={{ marginTop: 2 }}>
                            Please set current vehicle
                        </Alert>
                    )}
                </Grid>
            )}
        </Grid>
    );
}

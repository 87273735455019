import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import ListItemText from "@material-ui/core/ListItemText";
import SearchBar from "./SearchBar";

export class Fmcsa extends React.Component {
    componentDidMount() { }

    handleSearchChange = () => { };

    viewHistory = () => {
        const { accountBaseUrl } = this.props;
        this.props.history.push(`${accountBaseUrl}/fmcsa/history`);
    };

    render() {
        return (
            <Grid container spacing={0} style={{ padding: 10 }}>
                <Grid item xs={12} sm={12} style={{ padding: 10 }}>
                    <Alert
                        severity="warning"
                        style={{
                            width: "100%"
                        }}
                    >
                        <strong>
                            FMCSA Data Transfer (Steps to transfer data)
                        </strong>
                        <ol>
                            <li>
                                <ListItemText primary={"Select driver"} />
                            </li>
                            <li>
                                <ListItemText primary={"Select Date range"} />
                            </li>
                            <li>
                                <ListItemText primary={"Enter comment"} />
                            </li>
                            <li>
                                <ListItemText
                                    primary={`Click on "Send To FMCSA" to transfer data to FMCSA`}
                                />
                            </li>
                        </ol>
                    </Alert>
                </Grid>

                <Grid item xs={12} sm={12}>
                    <SearchBar
                        handleChange={this.handleSearchChange}
                        viewHistory={this.viewHistory}
                    />
                </Grid>
            </Grid>
        );
    }
}

reduxForm({
    form: "fmcsaForm"
})(Fmcsa);

const mapStateToProps = state => {
    return {
        accountBaseUrl: _get(state, "authData.accountBaseUrl")
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Fmcsa)
);

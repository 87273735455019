import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getUsers } from "../../actions/userActions";
import { sorting } from "../../utils/sortingHelpers";

const styles = (theme) => ({
  addButtonWrapper: {
    width: "99%",
    textAlign: "right",
    marginTop: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "200px",
    textAlign: "left",
  },
  grow: {
    flexGrow: 1,
    textAlign: "center",
  },
  center: {
    textTransform: "capitalize",
  },
});

export class SearchBar extends React.Component {
  state = {
    userId: "all",
  };

  componentDidMount() {
    // this.props.getUsers();
    this.props.getUsers({ skip: 0, limit: 250, status: "ACTIVE" });
  }

  handleChange = (event, name) => {
    this.setState(
      {
        [name]: event.target.value,
      },
      this.searchChange
    );
  };

  searchChange = () => {
    const { userId } = this.state;
    this.props.handleChange({
      userId: userId !== "all" ? userId : null,
    });
  };

  sendMessage = () => {
    this.props.showMessageDialog();
  };

  render() {
    const { classes, users, loading } = this.props;
    const { userId } = this.state;
    const usersArray = sorting(users, "firstName");

    return (
      <AppBar position="static" color="default">
        <Toolbar>
          <Grid container spacing={8} alignItems="center">
            <Grid sm={9} item style={{ textAlign: "left" }}>
              <Typography variant="h6" color="inherit">
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="userId">User</InputLabel>
                  <Select
                    className={classes.select}
                    value={userId}
                    onChange={(e) => this.handleChange(e, "userId")}
                    inputProps={{
                      name: "userId",
                      id: "userId",
                    }}
                  >
                    <MenuItem value={"all"}>All Users</MenuItem>
                    {Array.isArray(users) &&
                      usersArray.map((item, i) => (
                        <MenuItem
                          key={i}
                          value={item.id}
                          className={classes.center}
                        >
                          {item.firstName} {item.lastName} <br />
                          {item.email}{` (${item.status})`}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Typography>
            </Grid>
            <Grid sm={3} item style={{ textAlign: "right" }}>
              {/*AutoRefresh
                            <Switch
                                checked={autoRefresh}
                                onChange={this.handleAutoRefresh}
                                value={autoRefresh}
                                color="primary"
                                inputProps={{
                                    "aria-label": "primary checkbox"
                                }}
                            />*/}

              <Button
                disabled={loading}
                variant={"contained"}
                color="primary"
                onClick={this.sendMessage}
                style={{}}
              >
                {loading && (
                  <CircularProgress size={20} style={{ marginRight: 5 }} />
                )}
                {"Send Notification"}
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: _get(state, "userData.users", []),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getUsers }, dispatch);

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SearchBar)
);

import VIN from "vehicle-identification-number";

const validate = values => {
    const errors = {};
    let requiredFields = [
        "name",
        "vin",
        "licPlate",
        "dvir",
        "carrierId",
        "regulationMode"
    ];

    if (values.assetType === "TRAILER") {
        requiredFields = ["name", "licPlate", "dvir", "carrierId"];
    }

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = `Required`;
        } else if (
            field === "name" &&
            values[field] &&
            values[field].length > 10
        ) {
            errors.name = "Too long";
        }
    });
    if (values.vin && values.assetType !== "TRAILER") {
        try {
            VIN.parse(values.vin);
        } catch (e) {
            errors.vin = `VIN is not valid.`;
        }
    }
    return errors;
};

export default validate;

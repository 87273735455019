import React, { } from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import MapListItem from "./MapListItem";
import ArrowRight from "@material-ui/icons/ArrowRightOutlined";
import ArrowLeft from "@material-ui/icons/ArrowLeftOutlined";
import { getDcImages, getDcDetail } from "../../../actions/dashcam/dashcamActions";
import { formatDate, formatTime } from "../../../actions/momentActions";


function LLMap(props) {
    const { show, setShow } = props
    const drivers = props.drivers || [];
    
    return (
        <div style={{ position: "relative", height: "100%" }}>
            <div
                style={{
                    height: "100%",
                    maxHeight: "94vh",
                    overflowY: "scroll",
                    paddingTop: "8px"
                }}
            >
                {Array.isArray(drivers) &&
                    drivers.map((item, i) => {
                        const itemdriver = item.driver || {};
                        const status = item.eventCode || "";
                        const location = item.location || "";
                        const time = item.lastContact
                            ? item.lastContact
                            : item.eventTime
                                ? item.eventTime
                                : null;
                        const date = props.formatDate(time);
                        const currtime = props.formatTime(time);
                        const speed = (
                            _get(item, "telemetrics.speedKm", 0) * 0.621371
                        ).toFixed(2);
                        const rpm = _get(item, "telemetrics.rpm", 0);
                        const faultCodes = _get(item, "telemetrics.faultCodes", "") || "";

                        let fuel = _get(item, "telemetrics.fuel", "0") || "0";
                        fuel = parseInt((parseInt(fuel) / 247) * 100)
                        if (fuel > 100) {
                            fuel = 100
                        }

                        return (
                            <MapListItem
                                show={show}
                                key={i}
                                item={item}
                                status={status}
                                date={date}
                                time={currtime}
                                driver={itemdriver}
                                vehicle={item.vehicle}
                                address={item.address}
                                location={location}
                                speed={speed}
                                dId={props.dId}
                                popUpOpen={props.popupOpen}
                                rpm={rpm}
                                faultCodes={faultCodes}
                                fuel={fuel}
                                handleClick={props.handleClick}
                                accountBaseUrl={props.accountBaseUrl}
                                adminKey={props.adminKey}
                                getRealTime={props.getRealTime}
                                getDcImages={props.getDcImages}
                                getDcDetail={props.getDcDetail}
                                formatDate={props.formatDate}
                                formatTime={props.formatTime}
                            />
                        );
                    })}
            </div>

            <div style={{
                display: "none",
                backgroundColor: "aliceblue", position: "absolute", bottom: "0px", width: "100%",
                height: "42px", textAlign: "right"
            }}
                onClick={() => {
                    setShow(!show)
                }}
            >
                <div>
                    {!show ? <ArrowRight style={{ fontSize: 42, }} /> : <ArrowLeft style={{ fontSize: 42, }} />}
                </div>
            </div >

        </div>
    );
}

const mapStateToProps = state => ({
    accountBaseUrl: _get(state, "authData.accountBaseUrl"),
    adminKey: _get(state, "authData.profile.adminId")
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            formatDate,
            formatTime,
            getDcImages,
            getDcDetail
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(LLMap)
);

import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";

export default function SimpleMenu(props) {
  const isFmcsaSend = props.isFmcsaSend || false;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function onMenuSelect(event, item) {
    handleClose(event);
    props.onMenuSelect(item);
  }

  const menuItems = {
    REASS_MULTI: "Reassign Multiple Status",
    ADD_MULT: "Add Multiple Status",
    EDIT_MULT: "Edit Multiple Status",
    ADD_STATUS: "Add Status",
    SEND_MSG: "Send Message",
    SEND_NTG: "Send Notification",
    ADD_STATUS_ADMIN: "Add Status(Admin)",
  };

  return (
    <>
      <IconButton
        aria-label="Menu"
        color="primary"
        onClick={handleClick}
        aria-haspopup="true"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.adminKey && props.coDriver && (
          <MenuItem
            disabled={isFmcsaSend}
            onClick={(e) => onMenuSelect(e, menuItems.REASS_MULTI)}
          >{`${menuItems.REASS_MULTI}`}</MenuItem>
        )}
        {props.adminKey && props.coDriver && (
          <MenuItem
            onClick={(e) => onMenuSelect(e, menuItems.ADD_MULT)}
            disabled={isFmcsaSend}
          >{`${menuItems.ADD_MULT}`}</MenuItem>
        )}
        {(props.adminKey || props.profile === "MANAGER") && (
          <MenuItem
            disabled={isFmcsaSend}
            onClick={(e) => onMenuSelect(e, menuItems.EDIT_MULT)}
          >{`${menuItems.EDIT_MULT}`}</MenuItem>
        )}
        <MenuItem
          disabled={isFmcsaSend}
          onClick={(e) => onMenuSelect(e, menuItems.ADD_STATUS)}
        >{`${menuItems.ADD_STATUS}`}</MenuItem>
        {props.adminKey && (
          <MenuItem
            onClick={(e) => onMenuSelect(e, menuItems.SEND_MSG)}
          >{`${menuItems.SEND_MSG}`}</MenuItem>
        )}
        {props.adminKey && (
          <MenuItem
            onClick={(e) => onMenuSelect(e, menuItems.SEND_NTG)}
          >{`${menuItems.SEND_NTG}`}</MenuItem>
        )}
        {props.isAdminDs && (
          <MenuItem
            disabled={isFmcsaSend}
            onClick={(e) => onMenuSelect(e, menuItems.ADD_STATUS_ADMIN)}
          >{`${menuItems.ADD_STATUS_ADMIN}`}</MenuItem>
        )}
      </Menu>
    </>
  );
}

import _get from "lodash/get";
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import { DIAGNOSTIC_MALFUNCTION } from "../../constantsStatus";
import NotFoundTableRow from "../NotFoundTableRow";
import DutyStatus from "../../components/DutyStatus";

const styles = (theme) => ({
  root: {
    width: "98%",
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: theme.spacing(1),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  tableHead: {
    // padding: 5,
    // minWidth: 130
  },
  tableRowTitle: {
    display: "inline-block",
    // width: 95
  },
  capitalize: {
    textTransform: "capitalize",
  },
  saveIcon: {
    color: "#009be5",
    cursor: "pointer",
  },
});

class ReduxTableRow extends React.Component {
  handleAssign = (e, item) => {
    this.props.handleAssignDriver(e, item);
  };

  getCodeMessage = (code) => {
    const label = DIAGNOSTIC_MALFUNCTION[code];
    return label ? label : `${code}`;
  };

  render() {
    const { classes, item = {}, carriers = [] } = this.props;
    const firstName = _get(this, "props.item.driver.firstName", "");
    const lastName = _get(this, "props.item.driver.lastName", "");
    const carrierId = _get(this, "props.item.carrierId");
    // const eventTime = _get(this, "props.item.eventTime");
    const carrier = carriers.find((_c) => {
      return _c.id === carrierId;
    });
    const timezone = carrier && carrier.timezone;

    let disLabel = item.diagnostic
      ? this.getCodeMessage(item.diagnosticCode)
      : item.malfunction
      ? this.getCodeMessage(item.malfunctionCode)
      : "";

    if (!item.diagnostic && item.diagnosticCode) {
      disLabel = this.getCodeMessage(item.diagnosticCode);
    }
    if (!item.malfunction && item.malfunctionCode) {
      disLabel = this.getCodeMessage(item.malfunctionCode);
    }
    // console.log(`MALFUNCTION_DIAGNOSTIC = `, MALFUNCTION_CODES);
    return (
      <TableRow hover>
        <TableCell>
          <div className={classes.capitalize}>
            <div>{`${firstName} ${lastName}`}</div>
          </div>
        </TableCell>
        <TableCell>
          <div className={classes.capitalize}>
            {item.vehicle && <div>{item.vehicle.name}</div>}
          </div>
        </TableCell>
        <TableCell>
          <div className={classes.capitalize}>{disLabel}</div>
        </TableCell>
        <TableCell>
          <DutyStatus
            status={item}
            eventType={item.eventType}
            eventCode={item.eventCode}
            annotation={item.annotation}
            eventSubType={item.eventSubType}
          />
        </TableCell>
        <TableCell>
          <div>{this.props.formatDate(item.eventTime, timezone)}</div>
          <div>{this.props.formatTime(item.eventTime, timezone)}</div>
        </TableCell>
        <TableCell>{item.location}</TableCell>
        <TableCell className={`${classes.tableRowCell}`}>
          {this.props.adminKey &&
            this.props.isQa === "true" &&
            item.sequenceId && <div>{item.sequenceId}</div>}
        </TableCell>
      </TableRow>
    );
  }
}

export class ReduxTable extends React.Component {
  state = {
    order: "asc",
  };

  handleChangePage = (event, page) => {
    const { limit } = this.props;
    this.props.handleChange({ page, limit });
  };

  handleChangeRowsPerPage = (event) => {
    const limit = event.target.value;
    const { page } = this.props;
    this.props.handleChange({ page, limit });
  };

  render() {
    const { classes, items = [], count = 1000 } = this.props;
    const { page, limit } = this.props;

    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Driver</TableCell>
              <TableCell>Vehicle</TableCell>
              <TableCell>Diagnostic/Malfunction</TableCell>
              <TableCell>Event code</TableCell>
              <TableCell>Event time</TableCell>
              <TableCell>Location</TableCell>
              {this.props.adminKey && this.props.isQa === "true" && (
                <TableCell>Sq Id</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {items &&
              Array.isArray(items) &&
              items.map((item, i) => (
                <ReduxTableRow
                  item={item}
                  key={i}
                  classes={classes}
                  carriers={this.props.carriers}
                  formatDate={this.props.formatDate}
                  formatTime={this.props.formatTime}
                  handleAssignDriver={this.props.handleAssignDriver}
                  adminKey={this.props.adminKey}
                  isQa={this.props.isQa}
                />
              ))}
          </TableBody>
        </Table>
        <NotFoundTableRow items={items} />
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
          component="div"
          count={count}
          rowsPerPage={limit}
          page={page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

ReduxTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReduxTable);

// import _get from "lodash/get";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import MapIcon from "@material-ui/icons/Map";
import AppsIcon from "@material-ui/icons/Apps";
import PieChartIcon from "@material-ui/icons/PieChart";
import WarningIcon from "@material-ui/icons/Warning";
import MessageIcon from "@material-ui/icons/Message";
import Attachment from "@material-ui/icons/Attachment";
import Publish from "@material-ui/icons/Publish";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import SettingsIcon from "@material-ui/icons/Settings";
import NavigatorLinks from "./NavigatorLinks";
import HistoryIcon from "@material-ui/icons/History";
import EditIcon from "@material-ui/icons/Edit";
import UnknownIcon from "@material-ui/icons/Help";
import ShareIcon from "@material-ui/icons/Share";
import SupervisorAccount from "@material-ui/icons/SupervisorAccount";
import Warning from "@material-ui/icons/Warning";
import AssessmentIcon from '@material-ui/icons/Assessment';
import LocalGasStationIcon from "@material-ui/icons/LocalGasStationOutlined";
import { Hidden } from "@material-ui/core";
import { isUnity } from "../configureStore";

const appRoutes = [
  {
    id: "Dashboard",
    label: "Dashboard",
    url: "/dashboard",
    children: [
      {
        id: "Overview",
        label: "Overview",
        icon: <PieChartIcon />,
        url: "/overview",
        admin: false, // Admin only route
        adminSupport: true,
      },
      {
        id: "Dashboard",
        label: "Dashboard",
        icon: <PieChartIcon />,
        url: "/admin",
        admin: true, // Admin only route
        adminSupport: true,
      },
      {
        id: "Accounts",
        label: "Accounts",
        icon: <AppsIcon />,
        url: "/accounts",
        admin: true, // Admin only route
        adminSupport: true,
      },

      {
        id: "AdminFmcsa",
        label: "Fmcsa",
        icon: <Publish />,
        url: "/fmcsa",
        admin: true, // Admin only route
        adminSupport: true,
      },
      {
        id: "Audit",
        label: "Audit",
        icon: <Warning />,
        url: "/admin-audit",
        admin: true, // Admin only route
        adminSupport: true,
      },
      {
        id: "Users",
        label: "Users",
        icon: <SupervisorAccount />,
        url: "/admin-users",
        admin: true, // Admin only route
        adminSupport: true,
      },

      {
        id: "Admin Settings",
        label: "Admin_Settings",
        icon: <SettingsIcon />,
        url: "/admin-settings",
        admin: false, // Admin only route
        adminSupport: true,
      },
      {
        id: "Admin Reseller",
        label: "Admin_Reseller",
        icon: <SettingsIcon />,
        url: "/admin-reseller",
        admin: false, // Admin only route
        adminReseller: true,
      },
      {
        id: "Duty Status",
        label: "Duty Status",
        icon: <AppsIcon />,
        url: "/status/diary/:driverId",
        driver: true,
      },
      {
        id: "Daily Logs",
        label: "Daily Logs",
        icon: <HistoryIcon />,
        active: true,
        url: "/status/view",
        manager: true,
      },
      // {
      //     id: "Daily Logs Beta",
      //     label: "Daily Logs Beta",
      //     icon: <HistoryIcon />,
      //     active: true,
      //     url: "/status/view/beta",
      //     manager: true
      // },

      // {
      //     id: "Daily Logs GMap",
      //     label: "Daily Logs GMap",
      //     icon: <HistoryIcon />,
      //     active: true,
      //     url: "/status/view/Gmap",
      //     manager: true
      // },
      {
        id: "Tracking",
        label: "Tracking",
        icon: <MapIcon />,
        url: "/tracking",
        manager: true,
      },
      {
        id: "Unidentified",
        label: "Unidentified",
        icon: <UnknownIcon />,
        url: "/unidentified",
        manager: true,
      },
      {
        id: "Malfunctions",
        label: "Malfunctions",
        icon: <WarningIcon />,
        url: "/malfunctions",
        manager: true,
      },
      {
        id: "Violations",
        label: "Violations",
        icon: <WarningIcon />,
        url: "/violations",
        manager: true,
      },
      {
        id: "Notifications",
        label: "Notifications",
        icon: <MessageIcon />,
        url: "/notifications",
        manager: true,
      },
      {
        id: "Dvir",
        label: "Dvir",
        icon: <PictureAsPdf />,
        url: "/dvir",
        manager: true,
      },
      {
        id: "Document",
        label: "Document",
        icon: <Attachment />,
        url: "/document",
        manager: isUnity ? !isUnity : true,
      },
      {
        id: "Fmcsa",
        label: "Fmcsa",
        icon: <Publish />,
        url: "/fmcsa/create",
        manager: isUnity ? !isUnity : true,
      },
      {
        id: "EditLogs",
        label: "Log Edits",
        icon: <EditIcon />,
        url: "/log-edits",
        manager: true,
      },
      {
        id: "Liveshare",
        label: "Live share",
        icon: <ShareIcon />,
        url: "/live-share",
        manager: isUnity ? !isUnity : true,
      },
      // {
      //   id: "StateMileage",
      //   label: "State Mileage",
      //   icon: <LocalGasStationIcon />,
      //   url: "/state-mileage",
      //   manager: isUnity ? !isUnity : true,
      // },
      {
        id: "StateMileage",
        label: "State Mileage",
        icon: <LocalGasStationIcon />,
        url: "/state-mileage",
        manager: isUnity ? !isUnity : true,
      },
      // {
      //     id: "Ifta Mileage",
      //     label: "Ifta Mileage",
      //     icon: <LocalGasStationIcon />,
      //     url: "/ifta-mileage",
      //     manager: true
      // }
      // {
      //     id: "Fmcsa",
      //     label: "Fmcsa",
      //     icon: <Publish />,
      //     url: "/fmcsa/history",
      //     manager: true
      // }
      {
        id: "Reports",
        label: "Reports",
        icon: <AssessmentIcon />,
        url: "/reports/fmcsa/create",
        manager: isUnity,
      },
      {
        id:  isUnity ? "Fleet manager" : "Settings",
        label:  isUnity ? "Fleet manager" : "Settings",
        icon: <SettingsIcon />,
        url: "/settings/carrier",
        manager: true,
      },
    ],
  }
];

const styles = (theme) => ({
  categoryHeader: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  headerLogo: {
    width: 55,
    margin: "auto",
  },
  item: {
    paddingTop: 8,
    paddingBottom: 8,
    margin: "4px 0",
    fontSize: 14,
    fontWeight: 400,
    color: "#41536e",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: "#f4f5f7",
    },
  },
  itemCategory: {
    backgroundColor: "#171F2A",
    boxShadow: "0 -1px 0 #404854 inset",
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 10,
    paddingRight: 10,
  },
  firebase: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white,
  },
  itemActionable: {
    "&:hover": {
      backgroundColor: "#009ddd26",
    },
  },
  itemActiveItem: {
    color: "#009ddd",
    backgroundColor: "#009ddd26",
    position: "relative",
  },
  itemPrimary: {
    color: "inherit",
    fontSize: theme.typography.fontSize,
    "&$textDense": {
      fontSize: theme.typography.fontSize,
    },
  },
  textDense: {},
  divider: {
    backgroundColor: "#dfe1e6",
    height: 2,
    marginTop: 0,
  },
});

class MainNavigator extends React.Component {
  render() {
    const { classes, onClose, show, setShow, ...other } = this.props;

    return (
      <Drawer
        anchor="left"
        variant="permanent"
        {...other}
        onClose={() => {
          onClose();
        }}
      >
        <List
          disablePadding
          className="sidebar-list"
          style={{ padding: show ? 16 : 0, marginBottom: 52 }}
        >
          <NavigatorLinks
            index={1}
            show={show}
            appRoutes={appRoutes}
            classes={classes}
            onClose={onClose}
          />
        </List>
        <Hidden mdDown>
          <div className="slide-toggle">
            <div className="slide-togglebar "></div>
            <div className="toggle-line">
              <span className="css-xwsnrl"></span>
            </div>
            <div>
              <button
                type="button"
                className={`toggle-btn ${
                  show ? "toggle-btn-r1" : "toggle-btn-r2"
                }`}
                onClick={setShow}
              >
                <span className="css-pxzk9z">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    role="presentation"
                  >
                    <path
                      d="M10.294 9.698a.988.988 0 010-1.407 1.01 1.01 0 011.419 0l2.965 2.94a1.09 1.09 0 010 1.548l-2.955 2.93a1.01 1.01 0 01-1.42 0 .988.988 0 010-1.407l2.318-2.297-2.327-2.307z"
                      fill="currentColor"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                </span>
                <div className="css-z8pkji"></div>
              </button>
            </div>
          </div>
        </Hidden>
      </Drawer>
    );
  }
}

MainNavigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({});
export default withStyles(styles)(connect(mapStateToProps, {})(MainNavigator));

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _get from "lodash/get";
import Divider from "@material-ui/core/Divider";
import classNames from "classnames";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

class MainNavigatorLinks extends React.Component {
  onNavClick = (url) => {
    this.props.history.push(url);
    this.props.onClose();
  };

  render() {
    const { show, index } = this.props;
    const {
      appRoutes = [],
      classes,
      isManager,
      isManagerSor,
      isDriver,
      isAdmin,
      isAdminSupport,
      isAdminReseller,
      settings,
    } = this.props;
    const currentRoute = _get(this, "props.location.pathname");
    const driverId = _get(this, "props.driverId");
    const accountId = _get(this, "props.accountId");

    return (
      <div key={index}>
        {appRoutes.map(({ id, children, url }) => {
          if (accountId) {
            url = `${url}/${accountId}`;
          }

          const _children = children.filter((_child) => {
            if (isManagerSor) {
              if (_child.manager) {
                return true;
              }
            } else {
              if (_child.driver) {
                return isDriver;
              }
              if (_child.manager) {
                return isManager;
              }
              if (_child.admin) {
                return isAdmin;
              }
              if (_child.adminSupport) {
                return isAdminSupport;
              }
              if (_child.adminReseller) {
                return isAdminReseller;
              }
            }
            return false;
          });

          return (
            <React.Fragment key={id}>
              {/* <ListItem className={classes.categoryHeader}> */}
              {/*<ListItemText classes={{ primary: classes.categoryHeaderPrimary, }}>{id}</ListItemText>*/}
              {/* </ListItem> */}
              {_children.map(
                ({
                  label: childLabel,
                  id: childId,
                  url: childUrl,
                  icon,
                  active,
                  admin,
                  adminSupport,
                  manager,
                  driver,
                }) => {
                  let urlFinal =
                    childUrl === "/overview"
                      ? "/dashboard/overview"
                      : childUrl === "/fmcsa"
                      ? "/dashboard/fmcsa"
                      : childUrl === "/accounts"
                      ? "/dashboard/accounts"
                      : childUrl === "/payments"
                      ? "/dashboard/payments"
                      : childUrl === "/admin"
                      ? "/dashboard/admin"
                      : childUrl === "/admin-audit"
                      ? "/dashboard/admin-audit"
                      : childUrl === "/admin-users"
                      ? "/dashboard/admin-users"
                      : childUrl === "/admin-settings"
                      ? "/dashboard/admin-settings"
                      : childUrl === "/admin-reseller"
                      ? "/dashboard/admin-reseller"
                      : `${url}${childUrl}`;

                  // driverId
                  urlFinal = urlFinal.replace(":driverId", driverId);

                  // if (isAdminSupport) {
                  //     if (!adminSupport) {
                  //         return null;
                  //     }
                  // } else if (isAdmin) {
                  //     if (!admin) {
                  //         return null;
                  //     }
                  // }
                  //
                  // if (isManager) {
                  //     if (!manager) {
                  //         return null;
                  //     }
                  // }
                  //
                  // if (isDriver) {
                  //     if (!driver) {
                  //         return null;
                  //     }
                  // }

                  if (
                    childId === "EditLogs" &&
                    settings &&
                    !settings.editLogs
                  ) {
                    return null;
                  }
                  if (
                    childId === "Tracking" &&
                    settings &&
                    !settings.isTracking
                  ) {
                    return null;
                  }
                  if (
                    (childId === "StateMileage" ||
                      childId === "StateMileageBeta") &&
                    settings &&
                    !settings.isIfta
                  ) {
                    return null;
                  }
                  if (childId === "Document" && settings && !settings.isDoc) {
                    return null;
                  }
                  if (
                    childId === "Notifications" &&
                    settings &&
                    !settings.isNotif
                  ) {
                    return null;
                  }
                  if (
                    childId === "Liveshare" &&
                    settings &&
                    !settings.isShare
                  ) {
                    return null;
                  }

                  return (
                    <ListItem
                      button
                      dense
                      key={childId}
                      className={classNames(
                        classes.item,
                        classes.itemActionable,
                        currentRoute === urlFinal && classes.itemActiveItem,
                        currentRoute === urlFinal && show && "activeItem"
                      )}
                      url={urlFinal}
                      onClick={() => this.onNavClick(urlFinal)}
                      style={{ minHeight: 36 }}
                    >
                      <ListItemIcon
                        style={{
                          marginRight: 16,
                          fontSize: 24,
                          minWidth: "auto",
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText
                        classes={{
                          primary: classes.itemPrimary,
                        }}
                      >
                        {show ? childLabel : ""}
                      </ListItemText>
                    </ListItem>
                  );
                }
              )}
              <Divider className={classes.divider} />
            </React.Fragment>
          );
        })}
      </div>
    );
  }
}

MainNavigatorLinks.defaultProps = {
  onClose: () => {},
};

const mapStateToProps = (state) => ({
  isAdmin: _get(state, "authData.isAdmin"),
  isDriver: _get(state, "authData.isDriver"),
  isManager: _get(state, "authData.isManager"),
  isManagerSor: _get(state, "authData.isManagerSor"),
  isAdminSupport: _get(state, "authData.isAdminSupport"),
  isAdminReseller: _get(state, "authData.isAdminReseller"),
  driverId: _get(state, "authData.driverId"),
  accountId: _get(state, "authData.accountId"),
  settings: _get(state, "authData.profile.accountProperties.settings"),
});

export default withRouter(connect(mapStateToProps, {})(MainNavigatorLinks));

import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { Alert, AlertTitle } from "@material-ui/lab";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
// import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles(theme => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    buttomWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 10,
        marginBottom: "20px"
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    },
    statusButton: {
        margin: "0 2vh 0 2vh",
        float: "right",
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const UpdateButton = props => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isConfirmModal, setConfirmModal] = useState(false);
    const [response, setResponse] = useState("");
    const isSmallScreen = window.innerWidth < 800;


    function onClose() {
        setLoading(false);
        setErrorMessage("");
        setSuccessMessage("");
        setConfirmModal(false);
    }

    const changeStatus = async user => {
        const { updateUserAccount, getAccountDetails } = props;
        setLoading(true)
        if (user.status === "ACTIVE") {
            user.status = "IN_ACTIVE"
        }
        else {
            user.status = "ACTIVE"
        }
        const response = await updateUserAccount(user);
        let params = {
            accountId: user.account
        };
        await getAccountDetails(params);
        setResponse(response);

        if (response && user.status === "ACTIVE") {
            setSuccessMessage("Account activated successfully!")
            setErrorMessage("")
            setLoading(false)

        }
        else if (response && user.status === "IN_ACTIVE") {
            setSuccessMessage("Account deactivated successfully!")
            setErrorMessage("")
            setLoading(false)

        }
        else {
            setSuccessMessage("")
            setErrorMessage("Something went wrong!")
            setLoading(false)

        }
        setTimeout(() => {
            setSuccessMessage("");
            setErrorMessage("");
            setConfirmModal(false);
        }, 2000)
    }

    const { active, user, accountAuthBusy } = props;

    return (
        <React.Fragment>
            <Button
                variant="outlined"
                size="small"
                className={classes.statusButton}
                disabled={accountAuthBusy || loading}
                style={{
                    color: active ? "red" : "green",
                    borderColor: active ? "red" : "green"
                }}
                onClick={() => {
                    setConfirmModal(true)
                }}
            >
                {active ? "DEACTIVATE" : "ACTIVATE"}
            </Button>
            {isConfirmModal && (
                <Dialog
                    fullScreen={isSmallScreen}
                    fullWidth={true}
                    maxWidth={"md"}
                    open={isConfirmModal}
                    TransitionComponent={Transition}
                >
                    <DialogTitle id="alert-dialog-title" style={{ maxHeight: "9rem" }}>
                        <p style={{ marginTop: 0, textAlign: "center" }}>
                            {`Please confirm, if you want to ${active ? "deactivate" : "activate"} the account`}
                        </p>
                        <IconButton
                            style={{ marginTop: 5 }}
                            aria-label="Close"
                            className={classes.closeButton}
                            onClick={() => { setConfirmModal(false) }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        {successMessage && (
                            <Alert severity="success">
                                <AlertTitle>{successMessage}</AlertTitle>
                            </Alert>
                        )}
                        {errorMessage && (
                            <Alert severity="error">
                                <AlertTitle>{errorMessage}</AlertTitle>
                            </Alert>
                        )}
                        {loading &&
                            <div style={{ textAlign: "center" }}>
                                <CircularProgress />
                            </div>}
                        <div className={classes.buttomWrapper}>

                            <Button
                                variant="outlined"
                                color="primary"
                                disabled={loading || (response && response.email)}
                                onClick={() => { changeStatus(user) }}
                            >
                                {"CONFIRM"}
                            </Button>
                        </div>
                        <div className={classes.buttomWrapper}>
                            <Button color="secondary"
                                onClick={onClose}
                            >
                                {`CANCEL`}
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>
            )
            }
        </React.Fragment >
    );
};

export default UpdateButton;

import React from "react";
import Grid from "@material-ui/core/Grid";
import { Field } from "redux-form";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const renderCheckbox = ({ input, label, color, labelPlacement, classes }) => (
    <div>
        <FormControlLabel
            labelPlacement={labelPlacement}
            className={classes}
            control={
                <Checkbox
                    checked={input.value ? true : false}
                    onChange={input.onChange}
                    color={color}
                />
            }
            label={label}
        />
    </div>
);

export default function ReduxCheckBox({
    onClick,
    xs = 12,
    sm = 12,
    name,
    label,
    color = "primary",
    type = "text",
    placeholder,
    loading = false,
    labelPlacement = "end",
    classes
}) {
    const _label = label ? label : name;
    return (
        <Grid item xs={xs} sm={sm} style={{ paddingTop: 15 }}>
            <Field
                color={color}
                name={name}
                component={renderCheckbox}
                label={_label}
                labelPlacement={labelPlacement}
                classes={classes}
            />
        </Grid>
    );
}

import React, { useState, useEffect } from "react";
import _get from "lodash/get";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {
  DatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import CircularProgress from "@material-ui/core/CircularProgress";

const IFTA_YEARS = [];
const styles = (theme) => ({
  addButtonWrapper: {
    width: "99%",
    textAlign: "right",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
    textAlign: "left",
  },
  grow: {
    flexGrow: 1,
    textAlign: "center",
  },
  buttonGroup: {
    marginTop: 25,
    textAlign: "center",
  },
});

const SearchBar = (props) => {
  let { classes, constants, companies = [] } = props;

  const [companyId, setCompanyId] = useState("");
  const [dutyStatus, setDutyStatus] = useState("");
  const [connection, setConnection] = useState("");

  const onSearch = (e) => {
    e.preventDefault();

    setTimeout(() => {
      props.onSearch({ companyId, dutyStatus, connection });
    }, 1000);
  };

  const dutyStatusArray = [
    {
      id: "REMARK",
      name: "Remark",
    },
    {
      id: "ONDUTY_ND",
      name: "OnDutyND",
    },
    {
      id: "DRIVING",
      name: "Driving",
    },
    {
      id: "SLEEPER",
      name: "Sleeper",
    },
    {
      id: "OFFDUTY",
      name: "OffDuty",
    },
    {
      id: "BREAK",
      name: "Break",
    },
  ];

  const connectionArray = [
    {
      id: true,
      name: "Connected",
    },
    {
      id: false,
      name: "DisConnected",
    },
  ];

  return (
    <div style={{ paddingLeft: 10, paddingRight: 10 }}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container spacing={1}>
          <Grid item sm xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="companyId">Company</InputLabel>
              <Select
                className={classes.select}
                value={companyId}
                onChange={(e) => {
                  setCompanyId(e.target.value);
                  props.onSearch({
                    companyId: e.target.value,
                    dutyStatus,
                    connection,
                  });
                }}
                inputProps={{
                  name: "companyId",
                  id: "companyId",
                }}
              >
                <MenuItem value={""}>All Company</MenuItem>
                {companies.map((item, i) => (
                  <MenuItem
                    key={i}
                    value={item._id}
                    style={{ textTransform: "capitalize" }}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="dutyStatus">Duty Status</InputLabel>
              <Select
                className={classes.select}
                value={dutyStatus}
                onChange={(e) => {
                  setDutyStatus(e.target.value);
                  props.onSearch({
                    companyId,
                    dutyStatus: e.target.value,
                    connection,
                  });
                }}
                inputProps={{
                  name: "dutyStatus",
                  id: "dutyStatus",
                }}
              >
                <MenuItem value={""}>Duty Status</MenuItem>
                {dutyStatusArray.map((item, i) => (
                  <MenuItem
                    key={i}
                    value={item.id}
                    style={{ textTransform: "capitalize" }}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="connection">Connection</InputLabel>
              <Select
                className={classes.select}
                value={connection}
                onChange={(e) => {
                  setConnection(e.target.value);
                  props.onSearch({
                    companyId,
                    dutyStatus,
                    connection: e.target.value,
                  });
                }}
                inputProps={{
                  name: "connection",
                  id: "connection",
                }}
              >
                <MenuItem value={""}>Connection</MenuItem>
                {connectionArray.map((item, i) => (
                  <MenuItem
                    key={i}
                    value={item.id}
                    style={{ textTransform: "capitalize" }}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
  // }
};

const mapStateToProps = (state) => {
  return {
    drivers: _get(state, "driverData.drivers", []),
    equipments: _get(state, "equipmentData.equipments", []),
    accounts: _get(state, "accountData.accounts", []),
    constants: _get(state, "appData.constants", {}),
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SearchBar)
);

import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import queryString from "query-string";
import {
  formatDate,
  formatTime,
  formatDateTimeSec,
} from "../../actions/momentActions";
import { startDay, currTime } from "../../utils/momentHelpers";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getLocations, getLocationCount } from "../../actions/locationActions";
import LocationTable from "./LocationTable";
import SearchBar from "../../components/SearchBar";
import TrackingMap from "./TrackingMapNew";
import { gMapApiKey } from "../../utils/constants";

class Tracking extends React.Component {
  constructor(props) {
    let values = queryString.parse(window.location.search);
    super(props);
    this.buttonRef = React.createRef();
    this.state = {
      limit: 500,
      page: 0,
      timeOutId: null,
      autoRefresh: true,
      refreshDate: new Date(),
      AUTO_REFRESH_TIME: 15000,
      queryDriverId: values.id,
      loading: false,
      start: startDay,
      end: currTime,
      driverId: values.id,
      vehicleId: "",
      isMap: true,
    };
  }
  componentDidUpdate() {}

  componentDidMount() {
    if (this.state.queryDriverId) {
      this.loadLocations();
    } else {
      this.setState({ locations: [] });
    }
  }

  componentWillUnmount() {
    this.clearCurrentReq();
  }

  clearCurrentReq() {
    const { timeOutId } = this.state;
    if (timeOutId) {
      clearInterval(timeOutId);
    }
  }

  loadLocations = async () => {
    const { driverId, vehicleId } = this.state;
    const { limit, page, start, end, isMap } = this.state;
    if (!driverId && !vehicleId) {
      return;
    }
    // this.setState({ loading: true });
    const skip = parseInt(page) * parseInt(limit);

    let payload = {
      limit,
      skip,
      driverId,
      vehicleId,
    };

    if (isMap) {
      payload.limit = 5000;
      payload.lean = true;
    }
    if (start && end) {
      payload.to = end;
      payload.from = start;
    }

    const _locations = await this.props.getLocations(payload);
    const locations = _locations.map((item) => {
      item.lat = item.coordinates[0];
      item.lng = item.heading[1];
      item.bearing = parseFloat(item.heading);
      return item;
    });
    this.setState({ locations });
    if (!isMap) {
      await this.props.getLocationCount(payload);
    }
  };

  autoRefreshLocation = () => {
    const timeOutId = setTimeout(() => {
      this.loadLocations();
    }, this.state.AUTO_REFRESH_TIME);
    this.setState({ timeOutId, refreshDate: new Date() });
  };

  handleChange = ({ limit, page }) => {
    this.clearCurrentReq();
    this.setState({ limit, page }, this.loadLocations);
  };

  handleSearchChange = ({ driverId, vehicleId, start, end, loadMap }) => {
    this.setState(
      { driverId, vehicleId, start, end, loadMap },
      this.loadLocations
    );
  };

  handleTracking = () => {
    this.buttonRef.current.click();
  };

  handleView = (view) => {
    const setView = view === "Map" ? true : false;
    this.setState({ isMap: setView });
  };

  render() {
    const { loading, queryDriverId, isMap } = this.state;
    return (
      <div style={{ position: "relative" }}>
        <div>
          <SearchBar
            options={{
              isEqp: true,
              isDriver: true,
              isStartDate: true,
              isEndDate: true,
              isTime: true,
              isTimeperiod: true,
            }}
            loading={loading}
            onSearch={this.handleSearchChange}
            trackingId={queryDriverId}
            refrence={this.buttonRef}
            tracking={true}
            handleView={this.handleView}
            dateOptions={[
              { name: "Today", id: 1 },
              { name: "Yesterday", id: 2 },
            ]}
          />
        </div>
        {loading && (
          <div
            style={
              isMap
                ? {
                    position: "absolute",
                    zIndex: 1,
                    marginLeft: "40vw",
                    marginTop: "10px",
                  }
                : { textAlign: "center" }
            }
          >
            <CircularProgress />
          </div>
        )}

        {!isMap ? (
          <LocationTable
            handleChange={this.handleChange}
            items={this.state.locations}
            formatDate={this.props.formatDate}
            formatTime={this.props.formatTime}
            formatDateTimeSec={this.props.formatDateTimeSec}
            limit={this.state.limit}
            page={this.state.page}
            count={this.props.count}
            endDate={this.state.endDate}
            startDate={this.state.startDate}
            isMarkerShown={true}
          />
        ) : (
          <div>
            {this.state.locations && (
              <TrackingMap
                locations={this.state.locations}
                googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${gMapApiKey}`}
                loadingElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                containerElement={
                  <div
                    style={{
                      height: "calc(100vh - 175px)",
                      display: "block",
                      flexDirection: "column",
                      width: "100%",
                    }}
                    className="mapContainer"
                  />
                }
                formatDate={this.props.formatDate}
                formatTime={this.props.formatTime}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    count: _get(state, "locationData.count"),
    // locations: _get(state, "locationData.locations"),
    pagination: _get(state, "locationData.pagination"),
    adminKey: _get(state, "authData.profile.adminId"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      formatDate,
      formatTime,
      formatDateTimeSec,
      getLocations,
      getLocationCount,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Tracking);

import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import { reduxForm } from "redux-form";
import { getCarriers } from "../../../actions/carrierActions";
import { getDvirTemplates } from "../../../actions/dvirActions";
import {
  createEquipment,
  updateEquipment,
} from "../../../actions/equipmentActions";
import { getDashCameras } from "../../../actions/dashcam/dashcamActions";
import validate from "./validate";
import Grid from "@material-ui/core/Grid";
import ReduxButton from "../../../components/ReduxButton";
// import ReduxRadio from "../../../components/ReduxRadio";
import ReduxInput from "../../../components/ReduxInput";
import ReduxSelect from "../../../components/ReduxSelect";
import ReduxCheckBox from "../../../components/ReduxCheckBox";
import { getSelectYears } from "../../../utils/momentHelpers";
import Error from "../../Error";
import Success from "../../Success";

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const YEARS = getSelectYears(20);

export class AddEquipmentForm extends React.Component {
  state = {
    dcs: [],
  };

  componentDidMount() {
    this.props.getCarriers();
    this.props.getDvirTemplates();
    this.getDashCameras();
    this.setState({ errorMessage: "", successMessage: "" });
  }

  getDashCameras = async () => {
    try {
      const dcs = await this.props.getDashCameras();
      this.setState({ dcs });
    } catch (e) {}
  };

  save = async (values) => {
    this.setState({ errorMessage: "", successMessage: "" });
    let response;
    if (this.props.isEdit) {
      response = await this.props.updateEquipment(values);
    } else {
      if (values.assetType === "TRAILER" && !values.vin) {
        values.vin = values.name;
      }
      response = await this.props.createEquipment(values);
    }

    if (response.id) {
      this.setState({
        successMessage: "Equipment is saved successfully",
      });
      setTimeout(() => {
        this.props.handleClose();
      }, 1000);
    } else if (response.message) {
      this.setState({ errorMessage: response.message });
    } else {
      if (typeof response !== String) {
        response = "Something went wrong, please try after time.";
      }
      this.setState({ errorMessage: response });
    }
  };

  render() {
    const {
      classes,
      loading,
      carriers,
      handleSubmit,
      constants,
      equipmentValues,
      // accountProperties,
      isEdit,
      dvirTemplates,
      adminKey,
    } = this.props;

    const { errorMessage, successMessage } = this.state;

    const isAdmin = this.props.isAdmin;
    const isSmallScreen = window.innerWidth < 400;
    const isVehicle = equipmentValues.assetType === "VEHICLE";

    return (
      <Dialog
        fullScreen={isSmallScreen}
        fullWidth={true}
        maxWidth={"md"}
        className={classes.wrapper}
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography>{isEdit ? "EDIT EQUIPMENT" : "ADD EQUIPMENT"}</Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete="off">
            <Grid container spacing={0}>
              {isEdit ? (
                <Alert
                  severity="warning"
                  style={{
                    width: "100%",
                  }}
                >
                  <strong>
                    IFTA is only available for active trucks.
                    <br />
                    If you want to disable or delete truck please download state
                    mileage before disable action.
                  </strong>
                </Alert>
              ) : (
                <Grid item xs={12} sm={12} md={12}>
                  <Alert
                    severity="warning"
                    style={{
                      width: "100%",
                    }}
                  >
                    <strong>
                      NOTE: Once you add new vehicle, your card on file will be
                      automatically charged.
                    </strong>
                  </Alert>
                </Grid>
              )}

              {/* <Grid item xs={12} sm={12} md={12}>
                                <ReduxRadio
                                    name="assetType"
                                    items={constants.ASSET_TYPE}
                                />
                            </Grid> */}

              <Grid item xs={12} sm={12} md={3}>
                <ReduxInput
                  name="name"
                  label="Name"
                  disabled={isEdit ? !isAdmin : false}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={3}>
                <ReduxInput
                  uppercase={true}
                  name="vin"
                  label="Vin"
                  disabled={isEdit ? !isAdmin : false}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <ReduxInput name="vehicle.make" label="Make" />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <ReduxInput name="vehicle.model" label="Model" />
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <ReduxSelect name="vehicle.year" label="Year" items={YEARS} />
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <ReduxSelect
                  name="vehicle.fuel"
                  label="Fuel"
                  items={constants.EQUIPMENT_FUEL}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <ReduxSelect
                  name="vehicle.mode"
                  label="Type"
                  items={constants.EQUIPMENT_TYPE}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={3}>
                <ReduxSelect
                  name="license.country"
                  label="Country"
                  items={constants.COUNTRIES}
                />
              </Grid>
              {equipmentValues.country === "CA" ? (
                <Grid item xs={12} sm={12} md={3}>
                  <ReduxSelect
                    name="license.state"
                    label="State"
                    items={constants.CA_STATES}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={12} md={3}>
                  <ReduxSelect
                    name="license.state"
                    label="State"
                    items={constants.US_STATES}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={3}>
                <ReduxInput name="license.plate" label="Lic Plate" />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <ReduxSelect
                  name="dvir"
                  keyName={"name"}
                  valueName={"name"}
                  label="DVIR Form"
                  items={dvirTemplates}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <ReduxSelect
                  name="carrierId"
                  keyName={"id"}
                  valueName={"name"}
                  label="Carrier"
                  items={carriers}
                />
              </Grid>
              {isVehicle && (
                <React.Fragment>
                  <Grid item xs={12} sm={12} md={3}>
                    <ReduxSelect
                      name="gpsSource"
                      label="GPS Source"
                      items={constants.GPS_SOURCE}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <ReduxSelect
                      name="eldDevice.deviceType"
                      label="Device Type"
                      items={constants.DEVICE_TYPES}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <ReduxInput name="eldDevice.name" label="Device Name" />
                  </Grid>
                  {isAdmin && (
                    <Grid item xs={12} sm={12} md={3}>
                      <ReduxInput
                        name="eldDevice.macAddress"
                        label="MAC Address"
                      />
                    </Grid>
                  )}
                  {isAdmin && (
                    <Grid item xs={12} sm={12} md={3}>
                      <ReduxInput name="eldDevice.serial" label="ELD Serial" />
                    </Grid>
                  )}
                  {isAdmin && (
                    <Grid item xs={12} sm={12} md={3}>
                      <ReduxInput
                        name="eldDevice.firmware"
                        label="ELD Firmware"
                      />
                    </Grid>
                  )}
                  {isAdmin && (
                    <Grid item xs={12} sm={12} md={3}>
                      <ReduxInput
                        name="eldDevice.bleVersion"
                        label="ELD BleVersion"
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md={2}>
                    <ReduxSelect
                      name="eldDevice.connectionType"
                      label="Connection Type"
                      items={constants.CONNECTTION_TYPE}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={2}>
                    <ReduxSelect
                      name="regulationMode"
                      label="Regulation Mode"
                      items={constants.REGULATION}
                    />
                  </Grid>
                  {this.props.dcCustomerId && this.state.dcs && (
                    <Grid item xs={12} sm={12} md={3}>
                      <ReduxSelect
                        name="dcId"
                        label="Dashcam"
                        items={this.state.dcs}
                        keyName="RecorderID"
                        valueName="VehicleID"
                        valueTwoName="SerialNo"
                      />
                    </Grid>
                  )}
                  {isAdmin && (
                    <Grid item xs={12} sm={12} md={2}>
                      <ReduxInput
                        type="number"
                        name="offset"
                        label="Odometer offset"
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md={3}>
                    <ReduxCheckBox name="active" label={"Active"} />
                  </Grid>
                  {this.props.isAdmin && (
                    <Grid item xs={12} sm={12} md={2}>
                      <ReduxCheckBox name="settings.codes" label={"Codes"} />
                    </Grid>
                  )}
                  {this.props.isAdmin && (
                    <Grid item xs={12} sm={12} md={2}>
                      <ReduxCheckBox name="settings.ifta" label={"Ifta"} />
                    </Grid>
                  )}
                  {adminKey && (
                    <Grid item xs={12} sm={12} md={5}>
                      <ReduxCheckBox
                        name="vbusConnection"
                        label={"VBUS connection required for driving"}
                      />
                    </Grid>
                  )}
                </React.Fragment>
              )}

              <Error top={20} message={errorMessage} />
              <Success top={20} message={successMessage} />
            </Grid>
            <DialogActions className={classes.DialogActions}>
              <ReduxButton
                width
                loading={loading}
                onClick={handleSubmit(this.save)}
                marginTop={25}
                label={"SAVE"}
              />
              <Button onClick={this.props.handleClose} color="primary">
                CANCEL
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  const adminKey = _get(state, "authData.profile.adminId");
  return {
    constants: _get(state, "appData.constants", {}),
    accountProperties: _get(state, "authData.profile.accountProperties", {}),
    loading: _get(state, "equipmentData.equipmentBusy", false),
    equipmentValues: _get(state, "form.equipmentForm.values", {}),
    carriers: _get(state, "carrierData.carriers", []),
    dvirTemplates: _get(state, "dvirData.dvirTemplates", []),
    errorMessage: _get(state, "authData.errorMessage", ""),
    isAdmin: !!adminKey,
    dcCustomerId: _get(state, "authData.profile.accountProperties.dcId"),
  };
};

AddEquipmentForm = reduxForm({
  form: "equipmentForm",
  validate,
})(AddEquipmentForm);

AddEquipmentForm = connect((state) => {
  const values = _get(state, "equipmentData.selectedEquipment", {});
  return {
    initialValues: {
      vehicle: {
        year: 2023,
        fuel: "DIESEL",
        mode: "HEAVY_DUTY_VEHICLE",
      },
      ...values,
    },
  };
})(AddEquipmentForm);

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCarriers,
      createEquipment,
      updateEquipment,
      getDvirTemplates,
      getDashCameras,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AddEquipmentForm))
);

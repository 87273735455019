const DASHCAMERA = {
  header: "Integrated Video is here.",
  header2:
    "Osrit Dashcam helps you improve driver behavior and mitigate risks to your business by providing visual context of harsh driving events.",
  header3: "To know more, please visit at",
  header3Link: "www.truckdashcamera.com",
};

const OSRIT = {
  header: "Cloud Trucking Software | TMS.",
  header2:
    "Osrit Cloud Software helps you easily dispatch FTL or LTL loads, customers, brokers, drivers, payroll, invoicing, etc from one place !",
    header3: "To know more, please visit at",
    header3Link: "www.osrit.com",
};

export { DASHCAMERA, OSRIT };

import _get from "lodash/get";
import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// import ImageUpload from "../../../components/ImageUpload";

const useStyles = makeStyles({
    root: {
        maxWidth: "100%",
        marginTop: 25,
        padding: 25
    },
    media: {
        height: 140
    },
    title: {
        display: "inline-block",
        minWidth: 95
    }
});

export default function DetailCard(props) {
    const classes = useStyles();
    const profile =
        useSelector(state => _get(state, "authData.profile.user", {})) || {};

    const account =
        useSelector(state => _get(state, "authData.profile.account", {})) || {};
    const roles =
        useSelector(state => _get(state, "authData.profile.roles", [])) || [];

    return (
        <Paper className={classes.root}>
        <Typography
            gutterBottom
            variant="h5"
            component="h5"
            style={{ marginTop: 15 }}
        >
            Profile Info
        </Typography>
            <Typography
                gutterBottom
                variant="body2"
                component="p"
                style={{ marginTop: 25 }}
            >
                <span className={classes.title}>Name: </span>
                {`${profile.firstName} ${profile.lastName}`}
            </Typography>
            <Typography gutterBottom variant="body2" component="p">
                <span className={classes.title}>Email: </span>
                {`${profile.email}`}
            </Typography>
            <Typography gutterBottom variant="body2" component="p">
                <span className={classes.title}>Status: </span>
                {`${profile.status}`}
            </Typography>
            <Typography gutterBottom variant="body2" component="p">
                <span className={classes.title}>UUID: </span>
                {`${profile.uuid}`}
            </Typography>
            <Typography gutterBottom variant="body2" component="p">
                <span className={classes.title}>Roles: </span>
                {`${roles.toString()}`}
            </Typography>
            <Typography gutterBottom variant="body2" component="p">
                <span className={classes.title}>Account: </span>
                {`${account.name}`}
            </Typography>
            <Typography gutterBottom variant="body2" component="p">
                <span className={classes.title}>Account Id: </span>
                {`${profile.accountId}`}
            </Typography>
        </Paper>
    );
}

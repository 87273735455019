import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import Download from "../../components/Download";
import NotFoundTableRow from "../NotFoundTableRow";
import AndroidIcon from "@material-ui/icons/Android";
import AppleIcon from "@material-ui/icons/Apple";
import ConfirmActionModal from "../ConfirmActionModal";

const styles = (theme) => ({
  root: {
    width: "98%",
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: theme.spacing(1),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  capitalize: {
    textTransform: "capitalize",
  },
  icon: {
    color: "#009be5",
    fontSize: 16,
    marginBottom: -2,
    width: 25,
  },
  title: {
    fontWeight: 600,
    margin: "10px auto 5px",
  },
  EditIcon: {
    color: "#009be5",
    cursor: "pointer",
  },
  dataXLargeRow: {
    minWidth: 200,
  },
  dataLargeRow: {
    minWidth: 175,
  },
  dataMedRow: {
    minWidth: 125,
  },
  dataSmallRow: {
    minWidth: 100,
  },
  detailTitle: {
    display: "inline-block",
    minWidth: 70,
    lineHeight: 1.5,
  },
  logoDiv: {
    textAlign: "center",
  },
});

class ReduxTableRow extends React.Component {
  handleViewLogs = (e, item) => {
    this.props.handleViewLogs(item.logDate);
  };

  handleDownload = (e, item) => {
    console.log(item.media);
  };
  renderViolations = (item) => {
    const violations = item.violations || [];

    if (violations && Array.isArray(violations) && violations.length > 0) {
      return violations.map((violation, idx) => (
        <div key={`${violation.statusId}-${idx}`} style={{ marginBottom: 2 }}>
          <Chip
            size="small"
            label={violation.id}
            color="secondary"
            variant="outlined"
            style={{ minWidth: 30 }}
            onDelete={() => this.props.handleDelete(item)}
          />
        </div>
      ));
    } else {
      return null;
    }
  };

  render() {
    const { classes, timezone, item = {}, isUnity } = this.props;

    const isDownloadBtn = item.certified && item.media && item.media.name;
    return (
      <TableRow hover>
        <TableCell
          component="th"
          scope="row"
          style={{ textTransform: "capitalize" }}
        >
          {item.driver && (
            <div>
              {item.driver.firstName} {item.driver.lastName}
            </div>
          )}
        </TableCell>
        <TableCell component="th" scope="row">
          {this.props.formatDate(item.date, timezone)}
        </TableCell>
        <TableCell>
          {item.certified ? (
            <Typography variant="body1" component="body1" color="primary">
              Certified
            </Typography>
          ) : (
            <Typography variant="body1" component="body1" color="secondary">
              Uncertified
            </Typography>
          )}
        </TableCell>

        <TableCell>
          {item.offDutyHours && (
            <div className={classes.capitalize}>
              <span className={classes.detailTitle}>Driving:</span>
              {item.drivingHours}
            </div>
          )}
          {!isUnity && item.offDutyHours && (
            <div className={classes.capitalize}>
              <span className={classes.detailTitle}>OffDuty:</span>
              {item.offDutyHours}
            </div>
          )}
          {item.offDutyHours && (
            <div className={classes.capitalize}>
              <span className={classes.detailTitle}>OnDutyND:</span>
              {item.onDutyNDHours}
            </div>
          )}
          {!isUnity && item.offDutyHours && (
            <div className={classes.capitalize}>
              <span className={classes.detailTitle}>Sleeper:</span>
              {item.sleeperHours}
            </div>
          )}
        </TableCell>
        <TableCell>{this.renderViolations(item)}</TableCell>
        <TableCell component="th" scope="row">
          {item.iosSoftwareVersion ? (
            <div className={classes.logoDiv}>
              <AppleIcon fontSize="large" />
              <div style={{ marginTop: "-5px" }}>{item.iosSoftwareVersion}</div>
            </div>
          ) : null}

          {item.androidSoftwareVersion ? (
            <div className={classes.logoDiv}>
              <AndroidIcon fontSize="large" style={{ color: "green" }} />
              <div style={{ marginTop: "-8px" }}>
                {item.androidSoftwareVersion}
              </div>
            </div>
          ) : null}
        </TableCell>
        <TableCell>
          <Button
            variant="contained"
            color="default"
            style={{
              marginRight: 5,
              marginBottom: 5,
              minWidth: 100,
            }}
            onClick={(e) => this.handleViewLogs(e, item)}
          >
            Details
          </Button>
          {/*isDownloadBtn && <Download media={item.media} />*/}
        </TableCell>
      </TableRow>
    );
  }
}

export class DocumentTable extends React.Component {
  state = {};

  // handleChangePage = (event, page) => {
  //     this.setState({ page }, this.getDriverDaily);
  // };
  //
  // handleChangeRowsPerPage = event => {
  //     const limit = event.target.value;
  //     this.setState({ limit }, this.getDriverDaily);
  // };

  handleChangePage = (event, page) => {
    const { limit } = this.props;
    this.props.handleChange({ page, limit });
  };

  handleChangeRowsPerPage = (event) => {
    const limit = event.target.value;
    const { page } = this.props;
    this.props.handleChange({ page, limit });
  };

  // getDriverDaily = () => {
  //     const { limit, page } = this.state;
  //     const { driverId } = this.props;
  //
  //     // console.log("limit :: ", limit);
  //     // console.log("page :: ", page);
  //     // console.log("driverId :: ", driverId);
  //
  //     this.props.getDriverDaily({
  //         limit,
  //         page,
  //         driverId
  //     });
  // };

  handleDelete = (diary) => {
    this.setState({
      isConfirmOpen: true,
      deleting: false,
      selected: diary,
    });
  };

  onDelete = async () => {
    this.setState({ deleting: true });

    const selected = this.state.selected;
    const payload = {
      _id: selected._id,
      date: selected.date,
      driverId: selected.driverId,
      violations: [],
    };

    await this.props.updateDriverDaily(payload);

    this.setState({
      deleting: false,
      isConfirmOpen: false,
      selected: null,
    });

    const { page, limit } = this.props;
    await this.props.handleChange({ page, limit });
  };

  render() {
    const {
      eld,
      classes,
      items = [],
      getDriverDaily,
      count = 100,
      limit,
      page,
      isUnity
    } = this.props;
    const { isConfirmOpen, selected, deleting } = this.state;

    return (
      <Paper className={classes.root}>
        {isConfirmOpen && (
          <ConfirmActionModal
            open={isConfirmOpen}
            selected={selected}
            loading={deleting}
            handleClose={() =>
              this.setState({
                isConfirmOpen: false,
                deleting: false,
                selected: null,
              })
            }
            handleConfirm={this.onDelete}
            label={"Delete Violations"}
            message={"Are you sure, you want to delete all violations"}
          />
        )}
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Driver</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Certified</TableCell>
              <TableCell>Detail</TableCell>
              <TableCell>Violation</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(items) &&
              items.map((item, i) => (
                <ReduxTableRow
                  item={item}
                  key={i}
                  eld={eld}
                  classes={classes}
                  timezone={this.props.timezone}
                  getDriverDaily={getDriverDaily}
                  formatDate={this.props.formatDate}
                  handleViewLogs={this.props.handleViewLogs}
                  handleDelete={this.handleDelete}
                  isUnity={isUnity}
                />
              ))}
          </TableBody>
        </Table>
        <NotFoundTableRow items={items} />
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={count}
          rowsPerPage={limit}
          page={page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

DocumentTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DocumentTable);

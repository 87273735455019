import { React, useState } from "react";
import Grid from "@material-ui/core/Grid";
import MapList from "./MapList";
import ReactMap from "./ReactMap";
import RefreshIcon from "@material-ui/icons/Refresh";
import SearchIcon from "@material-ui/icons/Search";
import {
  Button,
  CircularProgress,
  IconButton,
  InputBase,
} from "@material-ui/core";
import { alpha, makeStyles } from "@material-ui/core/styles";
import { gMapApiKey } from "../../../utils/constants";

const useStyles = makeStyles((theme) => ({
  search: {
    display: "inline-flex",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 1, 0, 0),
    pointerEvents: "none",
    display: "inline-flex",
    height: 40,
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

function DisplayPosition({
  drivers,
  popupOpen,
  dId,
  loading,
  show,
  setShow,
  getRealTime,
}) {
  if (loading) {
    return <div style={{ textAlign: "center", paddingTop: 20 }}>Loading</div>;
  } else if (drivers.length === 0) {
    return (
      <div style={{ textAlign: "center", paddingTop: 20 }}>
        No driver found{" "}
      </div>
    );
  }
  return (
    <div style={{ textAlign: "center", width: "100%", height: "100%" }}>
      {dId && (
        <Button
          onClick={() => {
            popupOpen("");
          }}
        >
          RESET
        </Button>
      )}
      <MapList
        drivers={drivers}
        popupOpen={popupOpen}
        dId={dId}
        show={show}
        setShow={setShow}
        getRealTime={getRealTime}
      />
    </div>
  );
}

function RealTimeMap(props) {
  const [show, setShow] = useState(true);

  const classes = useStyles();
  const { onRefresh, showTable, search } = props;
  const [dId, setDId] = useState("");
  const eqps = props.equipments || [];
  const _drivers =
    props.drivers &&
    props.drivers.filter((item) => {
      return (
        item.coordinates !== null &&
        (item.coordinates[0] !== 0 || item.coordinates[1] !== 0)
      );
    });
  const drivers = _drivers.map((item, idx) => {
    item.uqId = `${item.driverId}-${idx}`;
    if (item && item.vehicle && item.vehicle.id) {
      const dc = eqps.find((_v) => _v.id === item.vehicle.id) || {};
      item.driver = item.driver || {};
      item.driver.dcId = dc.dcId;
    }
    return item;
  });

  const { accountBaseUrl, loading } = props;
  const popupOpen = (driverId) => {
    setDId(driverId);
  };

  let filterdDrivers = [];

  for (let idx = 0; idx < drivers.length; idx++) {
    const item = drivers[idx];
    if (item.coordinates) {
      const lat = item.coordinates[0] || 0;
      const long = item.coordinates[1] || 0;
      if (lat >= 7 && lat <= 76 && long >= -169 && long <= -51) {
        item.lat = parseFloat(lat);
        item.lng = parseFloat(long);

        filterdDrivers.push(item);
      }
    }
  }
  // drivers.forEach((item) => {
  //     if (item.coordinates) {
  //         const lat = item.coordinates[0] || 0
  //         const long = item.coordinates[1] || 0
  //         if (lat >= 7 && lat <= 76 && (long >= -169 && long <= -51)) {
  //             item.lat = parseFloat(lat)
  //             item.lng = parseFloat(long)
  //             filterdDrivers.push(item)
  //         }
  //     }
  // })

  return (
    <Grid container style={{ paddingRight: 0, height: "100%" }}>
      <Grid item xs={12} sm={12} md={show ? 3 : 2}>
        <DisplayPosition
          show={show}
          setShow={setShow}
          drivers={drivers}
          popupOpen={popupOpen}
          dId={dId}
          loading={loading}
          getRealTime={props.getRealTime}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={show ? 9 : 10}
        style={{ position: "relative", height: "100%" }}
      >
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            textAlign: "center",
            // left: 0,
            right: 0,
            top: 10,
            width: 300,
            marginRight: 35,
          }}
        >
          <div
            style={{
              display: "inline-flex",
              backgroundColor: "white",
              boxShadow: "rgb(0 0 0 / 30%) 0px 1px 4px -1px",
              borderRadius: 2,
              padding: "0px 8px 0px 8px",
              height: 40,
            }}
          >
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search..."
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
                onChange={search}
              />
            </div>

            <Button
              size="small"
              variant="text"
              color="primary"
              onClick={showTable}
            >
              TABLE
            </Button>

            <IconButton
              aria-label="refresh"
              color="primary"
              onClick={onRefresh}
              style={{ padding: 0 }}
            >
              <span
                style={{
                  minHeight: 30,
                  minWidth: 30,
                  marginTop: 7,
                }}
              >
                {loading ? <CircularProgress size={20} /> : <RefreshIcon />}
              </span>
            </IconButton>
          </div>
        </div>
        <ReactMap
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${gMapApiKey}`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={
            <div
              style={{
                height: "calc(100vh - 80px)",
                display: "block",
                flexDirection: "column",
                width: "100%",
              }}
              className="mapContainer"
            />
          }
          drivers={filterdDrivers}
          formatDate={props.formatDate}
          formatTime={props.formatTime}
          popupOpen={popupOpen}
          dId={dId}
          getDcImages={props.getDcImages}
          getDcDetail={props.getDcDetail}
          mapElement={<div style={{ height: `100%` }} />}
          onInfoWindowClose={(_dId) => setDId(_dId)}
          navigateToPage={props.navigateToPage}
          accountBaseUrl={accountBaseUrl}
        />
      </Grid>
    </Grid>
  );
}

export default RealTimeMap;

export function parseStateMileage(data) {
  let stateObj = {};

  for (let i = 0; i < data.length; i++) {
    const _data = data[i];

    for (let j = 0; j < _data.length; j++) {
      const __data = _data[j];
      stateObj[__data.country] = stateObj[__data.country] || {};

      const _baseTime = stateObj[__data.country].baseTime || 0;
      const _baseTime1 = __data.baseTime;
      const baseTime = _baseTime + _baseTime1;

      const _distance = stateObj[__data.country].distance || 0;
      const _distance1 = __data.distance;
      const distance = _distance + _distance1;

      const _trafficTime = stateObj[__data.country].trafficTime || 0;
      const _trafficTime1 = __data.trafficTime;
      const trafficTime = _trafficTime + _trafficTime1;

      const _tollRoadDistance = stateObj[__data.country].tollRoadDistance || 0;
      const _tollRoadDistance1 = __data.tollRoadDistance;
      const tollRoadDistance = _tollRoadDistance + _tollRoadDistance1;

      // const _travelTime = stateObj[__data.country].travelTime || 0;
      // const _travelTime1 = __data.travelTime;
      // const travelTime = _travelTime + _travelTime1;

      const _text = stateObj[__data.country].text || "";
      const _text1 = __data.text;
      const text = _text + " _ " + _text1;

      const _country = stateObj[__data.country].country || "";
      const _country1 = __data.country;
      const countryAll = _country + " _ " + _country1;

      stateObj[__data.country] = {
        baseTime,
        distance,
        tollRoadDistance,
        trafficTime,
        countryAll,
        country: __data.country,
        text,
      };
    }
  }

  const _objArray = Object.keys(stateObj);
  const objArray = _objArray.map((item) => stateObj[item]);

  return objArray;
}

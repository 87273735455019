export function validate(values = {}) {
    const errors = {};

    if (!values.location) {
        errors.location = "Required.";
    }

    if (!values.eventTime) {
        errors.eventTime = "Required.";
    }
    if (!values.odometer) {
        errors.odometer = "Required.";
    } else if (parseInt(values.odometer) > 9999999) {
        errors.odometer = "Invalid.";
    }

    if (!values.state) {
        errors.state = "Required.";
    }
    if (!values.eventCode) {
        errors.eventCode = "Required.";
    }
    // console.log('values.editType ==== ', values.editType);

    if (values.editType === "editAdmin" || values.editType === "addAdmin") {
    } else {
        if (!values.reason) {
            errors.reason = "Required.";
        }
    }

    return errors;
}

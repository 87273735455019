import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import * as cookie from "react-cookie";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import FolderIcon from "@material-ui/icons/Folder";
import { formatDateOnly } from "../../utils/momentHelpers";
import { formatCurrency } from "../../utils/numberHelpers";

const cookies = new cookie.Cookies();

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  pastDueText: {
    textAlign: "center",
    color: "red",
  },
});

export class CheckoutModal extends React.Component {
  state = {};

  handleSubmit = async (values) => {};

  async componentDidMount() {
    cookies.set("dueShown", "YES", {
      path: "/",
      maxAge: 43200,
    });
  }

  render() {
    const classes = this.props.classes;
    const invoices = this.props.invoices || [];
    const isSmallScreen = window.innerWidth < 400;
    const dueShown = cookies.get("dueShown", {
      path: "/",
    });

    let isPastDue = false;

    for (let i = 0; i < invoices.length; i++) {
      const dd = invoices[i].due_date * 1000;

      if (dd < Date.now() && invoices[i].status === "open") {
        isPastDue = true;
      }
    }

    if (dueShown === "YES" && !isPastDue) {
      return null;
    }

    return (
      <Dialog
        fullScreen={isSmallScreen}
        fullWidth={true}
        maxWidth={"md"}
        className={classes.wrapper}
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography>{"Pending invoices"}</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <List dense={true}>
                {invoices.map((item, idx) => {
                  let dueDate = "";
                  if (item.billing_reason === "subscription_cycle") {
                    const _periodStart = new Date(item.period_start * 1000);
                    const _periodEnd = new Date(item.period_end * 1000);
                    const periodStart = formatDateOnly(_periodStart);
                    const periodEnd = formatDateOnly(_periodEnd);

                    dueDate = ` (${periodStart} to ${periodEnd})`;
                  } else {
                    const _date = new Date(item.due_date * 1000);
                    const _dueDate = formatDateOnly(_date);
                    dueDate = ` (Due date: ${_dueDate})`;
                  }

                  const invNo = `Invoice# ${item.number}`;
                  const status = ` for `;
                  const amount = `${formatCurrency(item.amount_due / 100)}`;

                  const primaryTxt = `${invNo} ${status} ${amount} ${dueDate}`;

                  const secondaryTxt = item.lines.data.map(
                    (item) => item.description
                  );

                  return (
                    <ListItem key={`_${idx}`}>
                      <ListItemAvatar>
                        <Avatar>
                          <FolderIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={primaryTxt}
                        secondary={`${secondaryTxt}`}
                      />
                      <ListItemSecondaryAction>
                        <Button
                          variant="contained"
                          target="_blank"
                          color="primary"
                          href={`${item.hosted_invoice_url}`}
                        >
                          PAY NOW
                        </Button>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>

            <Grid item xs={12} md={12}>
              <DialogActions className={classes.DialogActions}>
                <Button color="primary" onClick={this.props.handleClose}>
                  CLOSE
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    constants: _get(state, "appData.constants", {}),
    errorMessage: _get(state, "equipmentData.error", ""),
    loading: _get(state, "accountData.accountCreateBusy", false),
    accountValues: _get(state, "form.accountForm.values", {}),
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckoutModal))
);

import React from "react";
// import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export default function StatusButtons(props) {
    const [allLogs, setAllLogs] = React.useState(false);

    return (
        <div>
            <FormControlLabel
                style={{ padding: 0 }}
                control={
                    <Checkbox
                        checked={allLogs}
                        onChange={e => {
                            const isChecked = e.target.checked;
                            setAllLogs(isChecked);
                            props.onChange(isChecked);
                        }}
                        style={{ padding: 0 }}
                        name="allLogs"
                        color="primary"
                    />
                }
                label={`ALL LOGS`}
            />
        </div>
    );
}

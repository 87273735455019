import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MultiStatusDetailTable from './MultiStatusDetailTable';
import {
    formatDateTimeToUtc,
    getDate
} from "../../../actions/momentActions";
import Error from "../../Error";
import Success from "../../Success";
import { createMultiEditRequest } from "../../../actions/logActions";
import { getPayload } from "../../DailyStatus/AddDailyStatusModal/helpers";

const styles = theme => ({
    DialogActions: {
        marginTop: 20
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    wrapper: {
        // padding: theme.spacing(1),
        minHeight: 200,
        overflow: "scroll",
    },

});


class MultipleStatusModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            logs: this.props.allLogs

        };
    }
    multi = (params = {}) => {
        params.isEdited = true;
        const { logs } = this.state;
        const index = logs.findIndex(e => e.cid === params.cid)
        logs[index] = params
        this.setState({ logs })

    }

    onSubmit = () => {
        const { logs = [] } = this.state;
        const { selectedDate, selectedDriver } = this.props;
        const driver = selectedDriver.driver || {};
        this.setState({ loading: true, successMessage: "", errorMessage: "" })

        let count = 0
        const list = logs.filter(e => e.isEdited)
        if (list.length > 0) {

            const error = list.filter(e => !e.eventTime || e.odometer === "" || parseInt(e.odometer) > 9999999 || e.location === "" || e.eventCode === ""
                || e.eventCode === "-" || e.state === "" || e.state === "-"
                || e.reason === "" || e.reason === "-");

            if (error && error.length > 0) {
                this.setState({ errorMessage: "Fix field errors to save", loading: false })
                return
            } else {
                list.forEach(async (item, index) => {
                    const payload = getPayload(driver, item, item, selectedDate);
                    await this.props.createMultiEditRequest(payload)
                    count++
                    if (count === list.length) {
                        this.setState({ successMessage: "Saved Successfully", errorMessage: "", loading: false })
                        this.props.onDone()
                    }
                })
            }
        } else {
            this.setState({ errorMessage: "Something went wrong, please try after time.", successMessage: "", loading: false })
            this.props.onDone();
        }
    }

    render() {
        const { classes } = this.props;
        const { loading, successMessage, errorMessage, logs } = this.state;
        const list = logs.filter(e => e.isEdited);
        const allLogs = logs.filter((e) => !e.locked);


        return (
            <div style={{ textAlign: "center" }}>
                <Dialog
                    fullScreen
                    className={classes.wrapper}
                    open={this.props.open}
                    onClose={this.props.close}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle
                        id="alert-dialog-title"
                        onClose={this.props.close}
                    >
                        <Typography style={{ minWidth: "900px" }}>
                            {"EDIT MULTIPLE STATUS"}
                        </Typography>
                        <IconButton
                            aria-label="Close"
                            className={classes.closeButton}
                            onClick={this.props.close}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>

                        <MultiStatusDetailTable
                            selectedDate={this.props.selectedDate}
                            searchLogs={this.props.searchLogs}
                            // isAllLog={this.props.isAllLog}
                            allLogs={allLogs}
                            // handleLogEdit={this.props.handleLogEdit}
                            dailyDiary={this.props.dailyDiary}
                            searchLogStatus={() =>
                                this.props.searchLogs(
                                    this.props.selectedDate
                                )
                            }
                            formatDateTimeToUtc={this.props.formatDateTimeToUtc}
                            getDate={this.props.getDate}
                            adminAccess={this.props.adminAccess}
                            adminKey={this.props.adminKey}
                            selectedLog={this.props.selectedLog}
                            formatTime={this.props.formatTime}
                            setSelectedLog={
                                this.props.setSelectedLog
                            }
                            formatRawDate={this.props.formatRawDate}
                            deleteAdminLogs={
                                this.props.deleteAdminLogs
                            }
                            updateAdminLogs={
                                this.props.updateAdminLogs
                            }
                            driverCalcus={this.props.driverCalcus}
                            // cycleStart={this.props.cycleStart}
                            isDebug={this.props.isDebug}
                            adminScope={this.props.adminScope}
                            formatDateTimeUtc={this.props.formatDateTimeUtc}
                            multi={this.multi}
                            createMultiEditRequest={this.props.createMultiEditRequest}
                            startOfDay={this.props.startOfDay}
                        />

                        {successMessage && (
                            <Success message={successMessage} />
                        )}
                        {errorMessage && (
                            <Error message={errorMessage} />
                        )}
                        <DialogActions
                            className={classes.DialogActions}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={this.onSubmit}
                                disabled={loading || list.length === 0}
                            >
                                {loading ? "Saving..." : "SAVE"}
                            </Button>
                            <Button
                                variant="contained"
                                onClick={this.props.close}
                            >
                                CLOSE
                            </Button>
                        </DialogActions>

                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        driverDiary: _get(state, "logData.driverDiary", []),
        driverId: _get(state, "driverData.selectedDriver.driver.id", {})
    };
};
const mapDispatchToProps = dispatch => bindActionCreators({
    createMultiEditRequest
    , formatDateTimeToUtc, getDate
}, dispatch);

export default withStyles(styles)(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(MultipleStatusModal)
    )
);

import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Formik, Form } from "formik";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { signIn } from "../../../actions/auth/authActions";
import { getTimeZone } from "../../../actions/momentActions";
import { updatePlan, } from "../../../actions/adminDashActions";
// import AddIcon from '@material-ui/icons/Add';
// import { validateEdit } from "./validate";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from '@material-ui/icons/Delete';
import InputBox from "../../../components/InputBox";
import SelectBox from "../../../components/SelectBox";
import TextField from "@material-ui/core/TextField";
import CheckBox from "../../../components/CheckBox";
import MomentUtils from "@date-io/moment";
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { PLAN_OPTIONS } from "./formhelpers";
import { formatDateTimeUtc, } from "../../../actions/momentActions";
import Error from "../../Error";
import Success from "../../Success";
import moment from "moment";

const styles = theme => ({
    wrapper: {},
    DialogActions: {
        marginTop: 25,
        textAlign: "right"
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

export class EditAccountForm extends React.Component {
    constructor(props) {
        super()
        this.state = {
            dues: props.userPlan?.dues || [],
            accountId: _get(props, "match.params.accountId")
        };

    }

    async componentDidMount() {

    }

    handleSubmit = async values => {
        this.setState({ errorMessage: "", successMessage: "", loading: true });
        const { dues, accountId } = this.state;
        const error = dues.filter(e => !e.date || e.truck === "");
        const vehicles = values.vehicles || {};
        if (error && error.length > 0) {
            this.setState({ errorMessage: "Enter required fields ", loading: false })
            return;
        }
        else if (values.plan === "annually") {
            values.plan = {
                "monthly": false,
                "annual": true
            }
        }
        else if (values.plan === "monthly") {
            values.plan = {
                "monthly": true,
                "annual": false
            }
        }

        vehicles.allowed = vehicles.allowed || "0"
        vehicles.current = vehicles.current || "0"

        if (parseInt(vehicles.allowed) < parseInt(vehicles.current)) {
            this.setState({ errorMessage: "Current vehicles must be less than allowed vehicles ", loading: false })
            return;
        }
        values.dues = dues;
        values.accountId = accountId;
        let response = await this.props.updatePlan(values);

        if (response && response._id) {
            this.setState({
                successMessage: "Plan is updated successfully",
                loading: false
            });
            // this.props.getAccounts();
            setTimeout(() => {
                this.props.handleSave();
            }, 1000);
        } else if (response && response.message) {
            this.setState({ errorMessage: response.message, loading: false });
        } else {
            if (typeof response !== String) {
                response = "Something went wrong, please try after time.";
            }
            this.setState({ errorMessage: response, loading: false });
        }
    };

    render() {
        const { classes, userPlan } = this.props;
        const { errorMessage, successMessage, loading, dues } = this.state;
        const isSmallScreen = window.innerWidth < 400;
        const getPlan = _get(userPlan, "plan", {});

        const plan = getPlan.monthly ? "monthly" : "annually";
        const initialValues = {
            ...userPlan, plan
        }

        return (
            <Dialog
                fullScreen={isSmallScreen}
                fullWidth={true}
                maxWidth={"md"}
                className={classes.wrapper}
                open={this.props.open}
                onClose={this.props.handleSave}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    onClose={this.props.handleSave}
                >
                    <Typography>{"EDIT PLAN"}</Typography>
                    <IconButton
                        aria-label="Close"
                        className={classes.closeButton}
                        onClick={this.props.handleSave}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <Formik
                        initialValues={initialValues}
                        // validate={validateEdit}
                        onSubmit={this.handleSubmit}
                        enableReinitialize={true}
                    >
                        {({ values, handleSubmit, setFieldValue, initialValues, errors }) => {

                            return (
                                <Form style={{ padding: "20px 10px" }}>
                                    <Grid container spacing={3}>

                                        <InputBox md={2} name="vehicles.allowed" label="Allowed Vehicle" type="number" />
                                        <InputBox md={2} name="vehicles.current" label="Current Vehicle" type="number" />

                                        <SelectBox
                                            md={3}
                                            name="plan"
                                            label="Plan"
                                            items={PLAN_OPTIONS}
                                        />

                                        <CheckBox
                                            md={2}
                                            name="ifta"
                                            label={"IFTA"}
                                        />
                                        <CheckBox
                                            md={2}
                                            name="api"
                                            label={"API"}
                                        />
                                        <Grid container spacing={4}
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-around", margin: "30px 0 5px 0"
                                            }}>
                                            {dues.length !== 0 ?
                                                <>
                                                    <span>Date</span>
                                                    <span>Truck</span>
                                                    <span>Note</span>
                                                    <Button color="primary"
                                                        variant="contained"

                                                        onClick={() => {
                                                            dues.push({
                                                                date: moment().add(1, 'Y')
                                                            })
                                                            this.setState({ dues })
                                                        }}>
                                                        ADD DUE
                                                    </Button>
                                                </> :
                                                <Grid style={{
                                                    display: "flex",
                                                    justifyContent: "end"
                                                }}>
                                                    <Button color="primary"
                                                        variant="contained"

                                                        onClick={() => {
                                                            dues.push({
                                                                date: moment().add(1, 'Y')
                                                            })
                                                            this.setState({ dues })
                                                        }}>
                                                        ADD DUE
                                                    </Button>
                                                </Grid>}
                                        </Grid>

                                        {dues.map((item, index) => {
                                            const time = item.date ? this.props.formatDateTimeUtc(item.date) : moment();
                                            return (
                                                <Grid container spacing={4} key={index}
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-around", marginBottom: "20px"
                                                    }}>
                                                    <div style={{ marginTop: "33px" }}>
                                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                                            <>
                                                                <KeyboardDateTimePicker
                                                                    className={classes.font}
                                                                    value={time}
                                                                    inputProps={{ readOnly: true }}
                                                                    format="MM/DD/yyyy hh:mm A"
                                                                    onChange={(date) => {
                                                                        item.date = date
                                                                        dues[index] = item
                                                                        this.setState({ dues })
                                                                    }}
                                                                />
                                                            </>
                                                        </MuiPickersUtilsProvider>
                                                    </div>
                                                    <div>
                                                        <TextField
                                                            fullWidth
                                                            className={classes.font}
                                                            style={{ borderBottom: "1px solid #7F7F7F", marginTop: "32px" }}
                                                            placeholder="Truck"
                                                            type="text"
                                                            onChange={(e) => {
                                                                item.truck = e.target.value
                                                                dues[index] = item
                                                                this.setState({ dues })
                                                            }}
                                                            // onBlur={() => {
                                                            //     this.props.multi(item)
                                                            // }}
                                                            value={this.state.truck || item.truck}
                                                            InputProps={{
                                                                disableUnderline: true,
                                                                className: classes.searchInput,
                                                            }}
                                                        />
                                                    </div>
                                                    <div>
                                                        <TextField
                                                            fullWidth
                                                            className={classes.font}
                                                            style={{
                                                                borderBottom: "1px solid #7F7F7F",
                                                                marginTop: "32px"
                                                            }}
                                                            placeholder="Note"
                                                            type="text"
                                                            onChange={(e) => {
                                                                item.note = e.target.value
                                                                dues[index] = item
                                                                this.setState({ dues })
                                                            }}
                                                            // onBlur={() => {
                                                            //     this.props.multi(item)
                                                            // }}
                                                            value={this.state.note || item.note}
                                                            InputProps={{
                                                                disableUnderline: true,
                                                                className: classes.searchInput,
                                                            }}
                                                        />
                                                    </div>
                                                    <IconButton style={{ marginTop: "30px" }}
                                                        aria-label="Close"
                                                        // className={classes.closeButton}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            const due = dues.filter(
                                                                ee => item !== ee
                                                            );
                                                            this.setState({ dues: due });
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                    {successMessage && (
                                        <Success message={successMessage} />
                                    )}
                                    {errorMessage && <Error message={errorMessage} />}
                                    <div className={classes.DialogActions}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={loading}
                                        >
                                            {loading ? "Saving..." : "SAVE"}
                                        </Button>{" "}
                                        <Button
                                            variant="contained"
                                            onClick={this.props.handleSave}
                                        >
                                            CLOSE
                                        </Button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </DialogContent>
            </Dialog >
        );
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            signIn,
            updatePlan,
            getTimeZone,
            formatDateTimeUtc
        },
        dispatch
    );

export default withStyles(styles)(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(EditAccountForm)
    )
);

import React from "react";
import { alpha, withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";

const styles = theme => ({
    addButtonWrapper: {
        width: "99%",
        textAlign: "right",
        marginTop: 10
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: "100px",
        textAlign: "left"
    },
    secondaryBar: {
        zIndex: 0
    },
    button: {
        margin: theme.spacing(1)
        // color: "white"
    },
    grow: {
        flexGrow: 1
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: alpha(theme.palette.common.white, 0.25)
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto"
        }
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    inputRoot: {
        color: "inherit"
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "20ch"
        }
    }
});

function debounce(func, wait, immediate) {
    let timeout;
    return function () {
        let context = this,
            args = arguments;
        let later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function SearchInput(props) {
    const { classes, delay = 300, minimum = 2 } = props;
    const onChange = debounce(seachQuery => props.onChange(seachQuery), delay);

    return (
        <div className={classes.search}>
            <div className={classes.searchIcon}>
                <SearchIcon />
            </div>
            <InputBase
                placeholder="Search..."
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                }}
                inputProps={{ "aria-label": "search" }}
                onChange={event => {
                    event.persist();
                    const query = event.target.value;
                    if (query.length >= minimum) {
                        onChange(query);
                    } else if (query.length === 0) {
                        onChange("");
                    }
                }}
            />
        </div>
    );
}

export default withStyles(styles)(SearchInput);

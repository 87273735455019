import React from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import NotFoundTableRow from "../NotFoundTableRow";
import PersonIcon from "@material-ui/icons/Person";
import EmailIcon from "@material-ui/icons/Email";
import CleanModal from "./CleanModal";
import EditModal from "./EditModal";
import { Button } from "@material-ui/core";

const styles = theme => ({
    root: {
        width: "98%",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: theme.spacing(1.5),
        overflowX: "auto"
    },
    table: {
        minWidth: 700
    },
    capitalize: {
        textTransform: "capitalize"
    },
    icon: {
        color: "#009be5",
        fontSize: 16,
        marginBottom: -2,
        width: 25
    },
    dataSmallRow: {
        minWidth: 90
    }
});

class ReduxTableRow extends React.Component {

    render() {
        const { userProfile, classes, item = {} } = this.props;
        const carrier = _get(item, "carrier", {});
        const driver = _get(item, "driver", {});
        const terminal = _get(item, "terminal", {});
        const isSuperAdmin = userProfile.user.adminAccess
        return (
            <TableRow hover>
                <TableCell component="th" scope="row">
                    <div className={classes.dataSmallRow}>
                        <PersonIcon className={classes.icon} />
                        <strong>
                            {item.firstName || ""} {item.lastName || ""}
                        </strong>
                        <div>
                            <EmailIcon className={classes.icon} />
                            {item.email}
                        </div>
                    </div>
                </TableCell>
                <TableCell>{item.phone}</TableCell>
                <TableCell>{driver.status}</TableCell>
                <TableCell>{carrier.name}</TableCell>
                <TableCell>{item.adminAccess ? 'SUPER ADMIN' : item.role}</TableCell>
                <TableCell>
                  {isSuperAdmin ? <Button onClick={() => this.props.onEditSelect(item)}>
                      {"EDIT"}
                  </Button> : terminal.name
                }
                </TableCell>
                <TableCell>
                    <Button onClick={() => this.props.onCleanSelect(driver)}>
                        {"CLEAN"}
                    </Button>
                </TableCell>
            </TableRow>
        );
    }
}

export class userTable extends React.Component {
    state = {
        openClean: false,
        selDriver: {}
    };

    onCleanSelect = selDriver => {
        this.setState({ openClean: true, selDriver });
    };

    onEditSelect = selDriver => {
        this.setState({ openEdit: true, selDriver });
    };


    render() {
        const { eld, classes, items = [], userProfile } = this.props;
        const { openClean, openEdit, selDriver } = this.state;

        return (
            <React.Fragment>
                {openClean ? (
                    <CleanModal
                        open={openClean}
                        handleClose={() => {
                            this.setState({
                                openClean: false,
                                selDriver: null
                            });
                        }}
                        driver={selDriver}
                    />
                ) : null}
                {openEdit ? (
                    <EditModal
                        open={openEdit}
                        handleClose={() => {
                            this.setState({
                                openEdit: false,
                                selDriver: null
                            });
                        }}
                        selected={selDriver}
                    />
                ) : null}

                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Phone</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Carrier</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell>Terminal</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array.isArray(items) &&
                                items.map((item, i) => (
                                    <ReduxTableRow
                                        item={item}
                                        key={i}
                                        eld={eld}
                                        classes={classes}
                                        onCleanSelect={this.onCleanSelect}
                                        onEditSelect={this.onEditSelect}
                                        userProfile={userProfile}
                                    />
                                ))}
                        </TableBody>
                    </Table>
                    <NotFoundTableRow items={items} />
                    {/* <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
                        component="div"
                        count={count}
                        rowsPerPage={limit}
                        page={page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    /> */}
                </Paper>
            </React.Fragment>
        );
    }
}

userTable.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(userTable);

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import NavigateNext from "@material-ui/icons/NavigateNext";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import IconButton from "@material-ui/core/IconButton";
import {
    addDay,
    subtractDay,
    formatRawDate,
    isToday
} from "../../actions/momentActions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../custom-styles.css"

const styles = theme => ({
    wrapper: { width: "fit-content" },
    formControl: {
        margin: theme.spacing(1),
        minWidth: "200px",
        textAlign: "left"
    },
    addButtonWrapper: {
        width: "99%",
        textAlign: "right",
        marginTop: 10
    },
    dateWrapper: {
        backgroundColor: "#009be5",
        paddingTop: 1,
        display: "inline-block",
        borderRadius: 5
    },
    dateWrapperDisabled: {
        backgroundColor: "#c5ccd0",
        paddingTop: 1,
        display: "inline-block",
        borderRadius: 5
    },
    dateButton: {
        padding: "5px 0px"
    },
    colorWhite: {
        color: "white"
    },
    dateInput: {
        backgroundColor: "transparent",
        border: 0,
        fontSize: 16,
        width: 100,
        textAlign: "center",
        color: "white",
        fontWeight: 600
    },
    dateIconBtn: {
        padding: 0
    },
    colorDisabled: {
        color: "#c5ccd0"
        // color: "#009be5"
    },
    grow: {
        flexGrow: 1,
        textAlign: "center"
    }
});

export class DateWithButton extends React.Component {
    state = {
        selectedDate: new Date()
    };
    constructor(props) {
        super(props);

        const selectedDate = props.selectedDate
            ? new Date(props.selectedDate)
            : new Date();
        this.state = {
            selectedDate
        };
    }

    componentDidMount() { }

    handleChange = selectedDate => {
        this.setState({ selectedDate });
        this.props.onChange(selectedDate);
    };

    handlePrevDate = () => {
        const date = new Date(this.state.selectedDate);
        const _selectedDate = this.props.subtractDay(
            this.props.formatRawDate(date)
        );
        const selectedDate = new Date(_selectedDate);
        this.setState({ selectedDate });
        this.props.onChange(selectedDate);
    };

    handleNextDate = () => {
        const date = new Date(this.state.selectedDate);
        const _selectedDate = this.props.addDay(this.props.formatRawDate(date));
        const selectedDate = new Date(_selectedDate);
        this.setState({ selectedDate });
        this.props.onChange(selectedDate);
    };

    render() {
        const { classes, disabled, prevDisabled = false, prevDays } = this.props;
        const { selectedDate } = this.state;
        const isNextDisabled = disabled || this.props.isToday(selectedDate);
        const isPrevDisabled = disabled || prevDisabled;
        return (
            <div
                className={
                    disabled ? classes.dateWrapperDisabled : classes.dateWrapper
                }
            >
                <IconButton
                    aria-label="Previous"
                    className={classes.dateIconBtn}
                    onClick={this.handlePrevDate}
                    disabled={isPrevDisabled}
                >
                    <NavigateBefore
                        fontSize="large"
                        className={
                            isPrevDisabled
                                ? classes.colorDisabled
                                : classes.colorWhite
                        }
                    />
                </IconButton>
                <DatePicker
                    wrapperClassName={classes.wrapper}
                    calendarClassName={classes.calendar}
                    className={classes.dateInput}
                    selected={selectedDate}
                    onChange={this.handleChange}
                    maxDate={new Date()}
                    minDate={prevDays}
                    disabled={disabled}
                />
                <IconButton
                    aria-label="Next"
                    className={classes.dateIconBtn}
                    onClick={this.handleNextDate}
                    disabled={isNextDisabled}
                >
                    <NavigateNext
                        fontSize="large"
                        className={
                            isNextDisabled
                                ? classes.colorDisabled
                                : classes.colorWhite
                        }
                    />
                </IconButton>
            </div>
        );
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { formatRawDate, addDay, subtractDay, isToday },
        dispatch
    );

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(DateWithButton)
);

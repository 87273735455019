import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Alert from "@material-ui/lab/Alert";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MenuItem from "@material-ui/core/MenuItem";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Error from "../../Error";
import Success from "../../Success";
import { getDrivers } from "../../../actions/driverActions";
import { updateDriverDaily } from "../../../actions/logActions";

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  wrapperButton: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
    textAlign: "left",
  },
});

export class AddDailyStatusModal extends React.Component {
  constructor(props) {
    super(props);
    const coDrOneId = _get(props, "dailyDiary[0].coDriver[0].id", "");
    const coDrTwoId = _get(props, "dailyDiary[0].coDriver[1].id", "");
    this.state = {
      coDrOneId,
      coDrTwoId,
      loading: false,
      errorMessage: "",
      successMessage: "",
      driver: [],
    };
  }

  componentDidMount() {
    this.getDrivers();
  }

  getDrivers = async () => {
    const drivers = await this.props.getDrivers({
      skip: 0,
      limit: 250,
      status: "ACTIVE",
    });
    if (drivers && Array.isArray(drivers)) {
      this.setState({ drivers });
    }
  };

  onSave = async () => {
    const drivers = this.state.drivers || [];
    const { coDrOneId, coDrTwoId } = this.state;
    const driverId = _get(this, "props.dailyDiary[0].driverId");
    const _id = _get(this, "props.dailyDiary[0]._id");
    const date = _get(this, "props.dailyDiary[0].date");

    const payload = { _id, driverId, date, coDriver: [], patch: true };

    for (let i = 0; i < drivers.length; i++) {
      const sel = drivers[i];
      if (sel.id === coDrOneId) {
        payload.coDriver.push({
          id: sel.id,
          email: sel.email,
          firstName: sel.firstName,
          lastName: sel.lastName,
        });
      } else if (sel.id === coDrTwoId) {
        payload.coDriver.push({
          id: sel.id,
          firstName: sel.firstName,
          lastName: sel.lastName,
        });
      }
    }

    this.setState({ errorMessage: "", successMessage: "", loading: true });

    const response = await this.props.updateDriverDaily(payload);

    if (response && response._id) {
      this.setState({
        successMessage: "Saved successfully.",
        loading: false,
      });
      setTimeout(() => this.props.onDone(), 1000);
    } else {
      this.setState({
        errorMessage: "Something went wrong, please try after sometime.",
        loading: false,
      });
    }
  };

  render() {
    const { classes } = this.props;
    const drivers =
      Array.isArray(this.state.drivers) &&
      this.state.drivers.sort((a, b) =>
        a.firstName.localeCompare(b.firstName, "es", {
          sensitivity: "base",
        })
      );

    const { selectedDriver = {} } = this.props;
    const { errorMessage, successMessage, loading } = this.state;
    const { coDrOneId, coDrTwoId } = this.state;
    const curDrId = _get(this, "props.selectedDriver.driver.id");
    const isDailyDiary = !!_get(this, "props.dailyDiary[0]._id");
    const isSmallScreen = window.innerWidth < 400;
    const coDrGrid = coDrOneId ? 4 : 8;

    return (
      <Dialog
        fullScreen={isSmallScreen}
        fullWidth={true}
        maxWidth={"md"}
        className={classes.wrapper}
        open={!!this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" onClose={this.props.handleClose}>
          <Typography>{"Manage Drivers"}</Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {isDailyDiary ? (
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Typography variant="h5" component="h2">
                  {"Driver"}
                </Typography>
                <Typography variant="body2" component="p">
                  {`${selectedDriver.firstName}  ${selectedDriver.lastName} (${selectedDriver.status})`}
                  <br />
                  {selectedDriver.email}
                </Typography>
              </Grid>
              <Grid item xs={12} md={coDrGrid}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="coDrOneId">Co Driver</InputLabel>
                  <Select
                    className={classes.select}
                    value={coDrOneId}
                    onChange={(e) => {
                      this.setState({
                        coDrOneId: e.target.value,
                      });
                    }}
                    inputProps={{
                      name: "coDrOneId",
                      id: "coDrOneId",
                    }}
                  >
                    <MenuItem value={""}>All Drivers</MenuItem>
                    {Array.isArray(drivers) &&
                      drivers.map((item, i) => {
                        if (item.id === curDrId) {
                          return null;
                        }
                        return (
                          <MenuItem key={i} value={item.id}>
                            {item.firstName} {item.lastName} ({item.userStatus}){" "}
                            <br />
                            {item.email}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              {coDrOneId && (
                <Grid item xs={12} md={coDrGrid}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="coDrTwoId">Co Driver</InputLabel>
                    <Select
                      className={classes.select}
                      value={coDrTwoId}
                      onChange={(e) => {
                        this.setState({
                          coDrTwoId: e.target.value,
                        });
                      }}
                      inputProps={{
                        name: "coDrTwoId",
                        id: "coDrTwoId",
                      }}
                    >
                      <MenuItem value={""}>All Drivers</MenuItem>
                      {Array.isArray(drivers) &&
                        drivers.map((item, i) => {
                          if (item.id === curDrId || item.id === coDrOneId) {
                            return null;
                          }
                          return (
                            <MenuItem key={i} value={item.id}>
                              {item.firstName} {item.lastName} <br />
                              {item.email}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
            {successMessage && <Success message={successMessage} />}
            {errorMessage && <Error message={errorMessage} />}
            <DialogActions className={classes.DialogActions}>
              <Button
                variant="contained"
                color="primary"
                onClick={this.onSave}
                disabled={loading}
              >
                {loading ? "Saving..." : "SAVE"}
              </Button>
              <Button variant="contained" onClick={this.props.handleClose}>
                CLOSE
              </Button>
            </DialogActions>
          </DialogContent>
        ) : (
          <DialogContent>
            <Alert
              severity="error"
              style={{
                width: "100%",
                marginTop: 25,
                marginBottom: 50,
              }}
            >
              {`No login found for ${selectedDriver.firstName} ${selectedDriver.lastName} for selected date.`}
            </Alert>
          </DialogContent>
        )}
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return { drivers: _get(state, "driverData.drivers", []) };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateDriverDaily,
      getDrivers,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AddDailyStatusModal)
);

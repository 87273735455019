const validate = values => {
    const errors = {};
    const requiredFields = [
        "name",
        "timezone",
        "line1",
        "city",
        "state",
        "zip",
        "country"
    ];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = `Required`;
        }
    });
    return errors;
};

export default validate;

import React from "react";
import _get from "lodash/get";
import html2pdf from "html2pdf.js";
import { Alert } from "@material-ui/lab";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { pdfOptions } from "../../helpers/helpers";
import { formatDate, formatTime } from "../../actions/momentActions";
import {
  getEqpLogs,
  getIftaLogs,
  updateStateMileage,
} from "../../actions/stateMileageActions";
import { getProfile } from "../../actions/auth/authActions";
// import { getEquipments } from "../../actions/equipmentActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchBar from "../../components/SearchBar";
import MileTable from "./mileTable";
import { parseStateMileage } from "./helpers";

class StateMileage extends React.Component {
  state = {
    limit: 10,
    page: 0,
    loading: false,
    search: false,
    allRoutes: [],
  };

  getEqpLogs = async () => {
    const { vehicleId, start, end } = this.state;
    if (!vehicleId) {
      this.setState({
        errorMessage: "Select vehicle",
        loading: false,
      });
    } else {
      try {
        this.setState({ loading: true, errorMessage: "", allRoutes: [] });

        const vehicle = this.props.equipments.filter(
          (item) => item.id === vehicleId
        );
        this.setState({ vehicle });
        const iftaLogs = await this.props.getIftaLogs({
          vehicleId: vehicleId,
          from: start,
          to: end,
        });

        const _statemiles = iftaLogs.statemiles || [];

        const allStatus = _statemiles.filter(
          (e) =>
            Array.isArray(e.coordinates) &&
            e.coordinates.length === 2 &&
            e.coordinates[0] !== 0 &&
            e.coordinates[1] !== 0
        );

        if (this.state.reportOn) {
          this.setState({
            allStatus,
            loading: false,
            errorMessage: "",
          });
          return;
        }

        if (allStatus && Array.isArray(allStatus) && allStatus.length > 1) {
          this.getStateMileage(allStatus);
        } else {
          this.setState({
            errorMessage: "No records are found",
            loading: false,
          });
        }
      } catch (e) {
        this.setState({ loading: false });
      }
    }
  };

  getWayPoints = (locations = []) => {
    try {
      let result = {};
      let idx = 0;
      locations.forEach((item) => {
        const _item = item.coordinates;
        if (_item && _item[0]) {
          const keyName = `waypoint${idx}`;

          result[keyName] = `geo!${_item[0]},${_item[1]}`;

          idx++;
        }
      });
      return result;
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  getStateMileage = async (dutyLogs) => {
    try {
      const arrayOfPromises = [];
      const chunkSize = 125;

      for (let i = 0; i < dutyLogs.length; i += chunkSize) {
        const _chunk = dutyLogs.slice(i, i + chunkSize);
        arrayOfPromises.push(this._getStateMileage(_chunk));
      }

      const response = await Promise.all(arrayOfPromises);
      const allRoutes = parseStateMileage(response);

      this.setState({ allRoutes, loading: false, errorMessage: "" });
    } catch (e) {
      console.log(" ************* END ERROR ************* ", e);
      this.setState({
        allRoutes: [],
        loading: false,
        errorMessage: e.errorMessage || "Not able to calculate state mileage",
      });
    }
  };

  _getStateMileage = (dutyLogs, cb) => {
    const { iftaApiKey } = this.props;
    return new Promise((resolve, reject) => {
      try {
        const platform = new window.H.service.Platform({
          apikey: iftaApiKey,
        });
        const allWayPoints = this.getWayPoints(dutyLogs);
        let routingParameters = {
          mode: "fastest;truck",
          representation: "display",
          routeattributes: "waypoints,summary,summaryByCountry,shape,legs",
          maneuverattributes: "direction,action",
          alternatives: 2,
          // excludeCountries:['TX'],
          ...allWayPoints,
        };
        const router = platform.getRoutingService();

        router.calculateRoute(
          routingParameters,
          (result) => {
            const response = (result.response && result.response.route) || [];
            const allRoutes = _get(response, "[0].summaryByCountry", []);
            resolve(allRoutes);
          },
          (error) => {
            console.log(error);
            const _error = { errorMessage: "Calculate Ifta for few days" };
            resolve(_error);
          }
        );
      } catch (e) {
        console.log(e);
        resolve(e);
      }
    });
  };

  onSearch = ({ vehicleId, vehicle, start, end, reportOn }) => {
    this.setState(
      { vehicleId, vehicle, start, end, reportOn },
      this.getEqpLogs
    );
  };

  downloadReport = async () => {
    this.setState({ download: true });
    const fileName = `stateMileage.${"pdf"}`.replace(/ /g, "");
    window.scrollTo(0, 0);
    const element = document.getElementById("print_log");

    pdfOptions.filename = fileName;
    pdfOptions.margin = [0.3, 0.3, 0.3, 0.3];

    await html2pdf().set(pdfOptions).from(element).toPdf().save();
    this.setState({ download: false, saving: true });
  };

  render() {
    const { loading, errorMessage } = this.state;

    return (
      <>
        <SearchBar
          options={{
            isEqp: true,
            isState: false,
            isStartDate: true,
            isEndDate: true,
            isTime: false,
            isTimeperiod: true,
            isReport: true,
          }}
          loading={loading}
          onSearch={this.onSearch}
          stateMil={true}
        />

        {errorMessage && (
          <div style={{ margin: "20px" }}>
            <Alert severity="warning">{errorMessage}</Alert>
          </div>
        )}
        <>
          {loading ? (
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            <MileTable
              downloadReport={this.downloadReport}
              allRoutes={this.state.allRoutes}
              vehicle={this.state.vehicle}
              carrier={this.props.carrier}
              start={this.state.start}
              end={this.state.end}
              download={this.state.download}
              vehicleId={this.state.vehicleId}
              viewDetails={this.viewDetails}
            />
          )}
        </>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    equipments: _get(state, "equipmentData.equipments", []),
    adminKey: _get(state, "authData.profile.adminId"),
    carrier: _get(state, "authData.profile.account.carrier.name", ""),
    iftaApiKey: _get(state, "appData.IFTA_KEY", ""),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getIftaLogs,
      formatDate,
      formatTime,
      getEqpLogs,
      updateStateMileage,
      getProfile,
      // getEquipments
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(StateMileage);

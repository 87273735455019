import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import _get from "lodash/get";
import queryString from "query-string";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import DateWithButton from "../../components/DateWithButton";
import { getDriverById } from "../../actions/driverActions";
import {
    getDriverLogs,
    getDriverDaily,
    deleteDriverLog,
    getAllCalcus,
    updateDriverDaily
} from "../../actions/logActions";
// import {
//     getDailyDiary, getLogs, getCalcs
// } from "../../actions/webviewActions";
import {
    getDailyLogsDays,
    formatTime,
    formatRawDate,
    getEndOfDay,
    getStartOfDay,
} from "../../actions/momentActions";
import {
    dateToday, lastEightDays, daydiff
} from "../../utils/momentHelpers";
import { setTimeZone } from "../../actions/app/appActions";
import { getAllDayEvents, filterDriverLogs } from "../DailyStatus/helpers";
import LogCertify from "../DailyStatus/LogCertify";


const styles = theme => ({
    root: {
        width: "98%",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: theme.spacing(1),
        overflowX: "auto"
    },
    calendar: {
        margin: "15px"
    }
    , capitalize: {
        textTransform: "capitalize"
    },
    table: {
        minWidth: 700
    },
    small: {
        minWidth: 150
    },
    medium: {
        minWidth: 200
    },
    large: {
        minWidth: 300
    },
    speed: {
        display: "inline-block",
        marginLeft: 10,
        fontWeight: 600
    },
    buttonProgress: {
        color: "green[500]",
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});


class Webview extends Component {
    constructor(props) {
        super(props);
        const values = queryString.parse(window.location.search);

        this.state = {
            open: false,
            loading: false,
            showAll: false,
            openRecap: false,
            isAllLog: values.isAllLog,
            selectedDate: values.selectedDate || new Date(),
            openCertify: false,
            webview: true
        };
    }
    async componentDidMount() {
        await this.searchLogs(this.state.selectedDate);
    }

    componentWillUnmount() {
        const timezone = _get(this, "props.defaultTimezone");
        this.props.setTimeZone(timezone);
    }

    getDriverDaily = async () => {
        const selectedDate = this.state.selectedDate;
        const driverId = _get(this, "props.match.params.driverId");
        const dailyDiary = await this.props.getDriverDaily({
            driverId,
            date: this.props.formatRawDate(selectedDate)
        });
        // const params = { date: this.props.formatRawDate(selectedDate) }
        // const dailyDiary = await this.props.getDailyDiary(params);
        this.setState({ dailyDiary });
    };

    getAllCalcus = async () => {
        const driverId = _get(this, "props.match.params.driverId");
        const driverCalcus = await this.props.getAllCalcus({ driverId });

        // const params = {
        //     from: this.props.getStartOfDay(selectedDate),
        //     to: this.props.getEndOfDay(selectedDate)
        // }
        // const webCalcus = await this.props.getCalcs(params);
        this.setState({ driverCalcus });
        return driverCalcus;
    };


    searchLogs = async (selectedDate, showAll) => {
        this.setDateinQuery(selectedDate);
        this.setState({ selectedDate, loading: true }, this.getDriverDaily);

        const driverId = _get(this, "props.match.params.driverId");
        const days = this.props.getDailyLogsDays(selectedDate, 1);
        const selDriver = await this.props.getDriverById({ driverId });
        const timezone = _get(selDriver, "terminal.timezone");

        // const params = {
        //     from: this.props.getStartOfDay(selectedDate),
        //     to: this.props.getEndOfDay(selectedDate),
        // }
        // const webvLogs = (await this.props.getLogs(
        //     params
        // )) || {};
        this.props.setTimeZone(timezone);
        const allLogs =
            (await this.props.getDriverLogs({
                showAll,
                driverId,
                ...days
            })) || {};

        const allCalcus = await this.getAllCalcus();
        const driverLogs = allLogs.driverLogs || [];
        const driverLastLogs = allLogs.driverLastLogs || {};
        const driverNextLogs = allLogs.driverNextLogs || {};


        const allEvents = getAllDayEvents(
            timezone,
            selectedDate,
            driverLogs,
            driverLastLogs,
            driverNextLogs
        );

        this.setState({
            loading: false,
            driverLogs,
            allDayEvents: filterDriverLogs(allEvents, allCalcus),
            allEvents
        });
    };


    setDateinQuery = selectedDate => {
        const date = this.props.formatRawDate(selectedDate);
        const currenUrl = _get(this, "props.history.location.pathname");

        this.props.history.push({
            pathname: currenUrl,
            search: `?selectedDate=${date}`
        });
    };

    handleShowAll = () => {
        const showAll = !this.state.showAll;
        this.setState({ showAll });
        this.searchLogs(this.state.selectedDate, showAll);
    };

    render() {
        const { timezone, selectedDriver = {}, classes } = this.props;
        const { dailyDiary, allEvents } = this.state;
        const { loading, selectedDate, allDayEvents, webview } = this.state;
        const logDate = _get(this.state, "dailyDiary[0].date", "");
        let date = new Date();
        date.setDate(date.getDate() - 7);
        const activeLogs =
            Array.isArray(allEvents) &&
            allEvents.filter(item => {
                return item.recordStatus === "ACTIVE"
                    && item.eventType === "CHANGE_DUTY_STATUS" && !item.isLast;
            });
        const days = daydiff(selectedDate, lastEightDays);

        return (
            <div>
                <Grid item xs={12} md={3} className={classes.calendar}>
                    <DateWithButton
                        disabled={this.state.loading}
                        onChange={this.searchLogs}
                        selectedDate={selectedDate}
                        prevDisabled={days <= 1}
                        prevDays={date}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <LogCertify
                        dailyDiary={dailyDiary}
                        accountId={this.props.accountId}
                        selectedDriver={selectedDriver}
                        timezone={timezone}
                        dataLoading={loading}
                        driverLogs={activeLogs}
                        formatTime={this.props.formatTime}
                        certifyDiary={this.certifyDiary}
                        allDayEvents={allDayEvents}
                        logDate={logDate}
                        dateToday={dateToday}
                        webview={webview}
                        selectedDate={selectedDate}
                        days={days}
                    />
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        logs: _get(state, "logData.logs", []),
        driverRealTime: _get(state, "driverData.driverRealTime", []),
        accountBaseUrl: _get(state, "authData.accountBaseUrl"),
        timezone: _get(state, "appData.timezone"),
        adminAccess: _get(state, "authData.adminAccess", false),
        driverCalcus: _get(state, "logData.driverCalcus", []),
        selectedLog: _get(state, "logData.selectedLog", {}),
        driverBusy: _get(state, "driverData.driverBusy", false),
        selectedDriver: _get(state, "driverData.selectedDriver", {}),
        accountId: _get(state, "authData.accountId"),
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getDriverById,
            getDriverLogs,
            getDriverDaily,
            deleteDriverLog,
            getAllCalcus,
            updateDriverDaily,
            getDailyLogsDays,
            formatTime,
            formatRawDate,
            setTimeZone,
            getEndOfDay,
            getStartOfDay

        },
        dispatch
    );

export default withStyles(styles)(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(Webview)
    )
);

import axiosCo from "../axios";
import _get from "lodash/get";
import * as TYPES from "./coActionTypes";

export function setInvoices(value) {
    return { type: TYPES.INVOICES, value };
}

export function getDueInvoices(user) {
    return async (dispatch, getState) => {
        const _cus = _get(getState(), "authData.profile.account.stripeBuss");
        const providerId = _get(getState(), "authData.accountId");
        const customer = _cus && _cus.split(":") && _cus.split(":")[1];
        const BASE_URL = _get(getState(), "appData.CO_URL");

        try {
            const params = {
                provider: "SE",
                search: "INVOICE",
                providerId,
                customer
            };

            const response = await axiosCo({
                url: `${BASE_URL}/billing-dues-sm`,
                method: "get",
                params: params
            });
            let _response = _get(response, "data.data", {});
            dispatch(setInvoices(_response));
            return _response;
        } catch (error) {
            return error;
        }
    };
}

import React from "react";
import _get from "lodash/get";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { cleanRedis } from "../../actions/adminDashActions";
import Alert from "@material-ui/lab/Alert";

const styles = theme => ({
    wrapper: {},
    DialogActions: {
        marginTop: 20
    },
    AlignCenter: {
        textAlign: "center",
        display: "flex",
        justifyContent: "space-evenly"
    },
    AlignBtn: {
        textAlign: "center"
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    agriExm: {
        border: "1px solid grey",
        textTransform: "uppercase",
        flexBasis: "25%",
        flexShrink: 0,
        padding: 4,
        backgroundColor: "white",
        textAlign: "center",
        color: "black",
        display: "inline-block",
        width: 110,
        borderRadius: 5,
        fontWeight: 500
    }
});

export class ConfirmActionModal extends React.Component {
    state = {
        loading: false,
        message: ""
    };

    handleClean = async () => {
        const { accountId, driverId, vehicle } = this.props.driver;
        try {
            this.setState({ loading: true, message: "" });
            await this.props.cleanRedis({
                accountId,
                driverId,
                vehicleId: vehicle && vehicle.id
            });
            this.setState({
                loading: false,
                message: "Driver is cleaned sucessfully"
            });
            setTimeout(() => this.props.handleClose(), 1000);
        } catch (e) {
            console.log(e);
        }
    };

    render() {
        const { classes } = this.props;
        const loading = this.state.loading;
        const isSmallScreen = window.innerWidth < 500;

        return (
            <Dialog
                fullScreen={isSmallScreen}
                fullWidth={true}
                maxWidth={"xs"}
                className={classes.wrapper}
                open={!!this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    onClose={this.props.handleClose}
                >
                    <Typography>
                        {"Do you want to clean driver from list"}
                    </Typography>
                    <IconButton
                        aria-label="Close"
                        className={classes.closeButton}
                        onClick={this.props.handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <Grid container>
                        {this.state.message && (
                            <Grid item xs={12} sm={12} md={12}>
                                <Alert severity="success">
                                    {this.state.message}
                                </Alert>
                            </Grid>
                        )}
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            className={classes.AlignCenter}
                        >
                            <Button
                                size="small"
                                onClick={this.handleClean}
                                variant="contained"
                                color="primary"
                            >
                                {loading ? "Cleaning..." : "CLEAN NOW"}
                            </Button>
                            <br />
                            <Button
                                size="small"
                                onClick={this.props.handleClose}
                                color="primary"
                            >
                                CLOSE
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        constants: _get(state, "appData.constants", {}),
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ cleanRedis }, dispatch);

export default withStyles(styles)(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(ConfirmActionModal)
    )
);

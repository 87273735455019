import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import { Alert, AlertTitle } from "@material-ui/lab";
import Fade from "@material-ui/core/Fade";
import LogCertify from "./LogCertify";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles(theme => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    buttomWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 10
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const LogCertifyButton = props => {
    const classes = useStyles();
    let diary = (props.dailyDiary && props.dailyDiary[0]) || {};
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isCertifyModal, setCertifyModal] = useState(false);
    const [isUnCertifyModal, setUnCertifyModal] = useState(false);
    const { dateToday, dataLoading } = props;
    const diaryDate = dateToday <= diary.date;

    function onClose() {
        setLoading(false);
        setErrorMessage("");
        setSuccessMessage("");
        setCertifyModal(false);
        setUnCertifyModal(false);
    }

    async function unCertifyDiary() {
        diary.certified = false;
        diary.media = {};

        setLoading(true);
        const response = await props.updateDriverDaily(diary);
        setLoading(false);
        if (response._id) {
            setSuccessMessage("Log is uncertified successfully.");

            setTimeout(() => onClose(), 1000);
        } else {
            setErrorMessage("Not able uncertify.");
        }
    }

    async function onCertifyDone(response) {
        if (response._id) {
            setSuccessMessage("Log is certified successfully.");

            setTimeout(() => onClose(), 1000);
        } else {
            setErrorMessage("Not able certify log.");
        }
    }

    if (!diary._id) {
        return null;
    }

    return (
        <React.Fragment>
            <Button
                variant="outlined"
                color="primary"
                disabled={diaryDate || dataLoading}
                onClick={() => {
                    if (diary.certified) {
                        setUnCertifyModal(true);
                    } else {
                        setCertifyModal(true);
                    }
                }}
            >
                {diary.certified ? "UNCERTIFY" : "CERTIFY"}
            </Button>
            {isUnCertifyModal && (
                <Dialog
                    open={isUnCertifyModal}
                    TransitionComponent={Transition}
                >
                    <Fade in={isUnCertifyModal}>
                        <div className={classes.paper}>
                            <h2 id="transition-modal-title">
                                {`Please confirm, if you want to uncertify your logs`}
                            </h2>
                            {successMessage && (
                                <Alert severity="success">
                                    <AlertTitle>{successMessage}</AlertTitle>
                                </Alert>
                            )}
                            {errorMessage && (
                                <Alert severity="error">
                                    <AlertTitle>{errorMessage}</AlertTitle>
                                </Alert>
                            )}
                            <div className={classes.buttomWrapper}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    disabled={loading}
                                    onClick={unCertifyDiary}
                                >
                                    {loading ? "Loading..." : `UNCERTIFY`}
                                </Button>
                            </div>
                            <div className={classes.buttomWrapper}>
                                <Button color="secondary" onClick={onClose}>
                                    {`CANCEL`}
                                </Button>
                            </div>
                        </div>
                    </Fade>
                </Dialog>
            )}
            {isCertifyModal && (
                <Dialog
                    fullScreen
                    open={isCertifyModal}
                    TransitionComponent={Transition}
                >
                    <Fade in={isCertifyModal}>
                        <Grid item xs={12}>
                            <LogCertify
                                {...props}
                                diary={diary}
                                isCertify={isCertifyModal}
                                onCertifyDone={onCertifyDone}
                            />
                            <Grid
                                item
                                xs={12}
                                style={{
                                    textAlign: "center",
                                    margin: "10px 40px"
                                }}
                            >
                                <Button
                                    color="secondary"
                                    disabled={loading}
                                    onClick={onClose}
                                >
                                    CLOSE
                                </Button>
                            </Grid>
                        </Grid>
                    </Fade>
                </Dialog>
            )}
        </React.Fragment>
    );
};

export default LogCertifyButton;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import EditAccountForm from "./EditAccountForm";
import EditPropertiesForm from "./EditPropertiesForm";

function a11yProps(index) {
  return {
    id: `accunts-tab-${index}`,
    "aria-controls": `accunts-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  wrapper: {},
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function EditAccount(props) {
  const isSmallScreen = window.innerWidth < 400;
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Dialog
      fullScreen={isSmallScreen}
      fullWidth={true}
      maxWidth={"lg"}
      className={classes.wrapper}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="secondary"
          aria-label="accunts tabs"
        >
          <Tab label="Details" {...a11yProps(0)} />
          <Tab label="Settings" {...a11yProps(1)} />
        </Tabs>
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={props.handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {selectedTab === 0 && <EditAccountForm {...props} />}
        {selectedTab === 1 && <EditPropertiesForm {...props} />}
      </DialogContent>
    </Dialog>
  );
}

import { React, useState, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Toolbar from "@material-ui/core/Toolbar";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import { getAllUsers } from "../../actions/adminDashActions";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import UserTable from "./UsersTable";
import AddUserForm from "./AddUserForm";
import AddIcon from "@material-ui/icons/Add";

const styles = (theme) => ({
  paper: {
    // maxWidth: 936,
    margin: 15,
    overflow: "hidden",
  },
  searchBar: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: "block",
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: "40px 16px",
  },
});

function Content(props) {
  const { classes } = props;
  const [users, setUsers] = useState([]);
  const [isAdd, setIsAdd] = useState(false);
  const [query, setQuery] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  async function searchSupport() {
    if (props.adminAccess) {
      setQuery("");
      getAllUsers({ searchSupport: true });
    }
  }

  async function getAllUsers(params) {
    let payload = {
      query,
    };

    if (params && params.searchSupport) {
      payload.searchSupport = "ON";
    }

    setLoading(true);
    let response = await props.getAllUsers(payload);
    setUsers(response);
    setLoading(false);
  }

  // const validateEmail = (data) => {
  //   const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   return re.test(String(data).toLowerCase());
  // };

  const onSubmit = () => {
    setMessage("");
    getAllUsers();
  };

  return (
    <Paper className={classes.paper}>
      {isAdd && (
        <AddUserForm open={isAdd} handleClose={() => setIsAdd(false)} />
      )}
      <AppBar
        className={classes.searchBar}
        position="static"
        color="default"
        elevation={0}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <TextField
                fullWidth
                placeholder="Search by email or name"
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
                InputProps={{
                  disableUnderline: true,
                  className: classes.searchInput,
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onSubmit();
                  }
                }}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                aria-label="Search"
                onClick={onSubmit}
              >
                <SearchIcon className={classes.block} color="inherit" />
                {"SEARCH"}
              </Button>{" "}
              {props.adminAccess && (
                <Button
                  variant="contained"
                  color="primary"
                  aria-label="Search"
                  onClick={() => setIsAdd(true)}
                >
                  <AddIcon className={classes.block} color="inherit" />
                  {"ADD"}
                </Button>
              )}{" "}
              {props.adminAccess && (
                <Button
                  variant="contained"
                  color="primary"
                  aria-label="Search"
                  onClick={() => searchSupport()}
                >
                  {"Support Users"}
                </Button>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.contentWrapper}>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {message && (
              <Alert
                severity="error"
                style={{ color: "red", margin: "10px 33px" }}
              >
                {message}
              </Alert>
            )}
            <UserTable userProfile={props.userProfile} items={users} />
          </>
        )}
      </div>
    </Paper>
  );
}

const mapStateToProps = (state) => {
  return {
    isAdmin: state.authData.isAdmin,
    userProfile: state.authData.profile,
    adminAccess: state.authData.adminAccess,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllUsers,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Content)
);

import _get from "lodash/get";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import GroupIcon from "@material-ui/icons/Group";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import SignoutIcon from "@material-ui/icons/ExitToAppOutlined";
import { withStyles } from "@material-ui/core/styles";
import { SM_LOGO_LINE, LOGO_UNITY } from "../../utils/logos";

const lightColor = "rgba(255, 255, 255, 0.7)";

const styles = (theme) => ({
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: "none",
    color: lightColor,
    "&:hover": {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
  account: {
    textTransform: "uppercase",
    fontWeight: 400,
  },
});

export class Header extends React.Component {
  logOut = () => {
    this.props.handleUserIconClick();
  };

  render() {
    const isUnity = this.props.isUnity;
    const { classes, onDrawerToggle, adminKey, account } = this.props;
    let LOGO_URL = SM_LOGO_LINE;
    let LOGO_STYLES = { maxWidth: 150, maxHeight: 28, margin: "auto" };

    if (isUnity) {
      LOGO_URL = LOGO_UNITY;
      LOGO_STYLES = {
        maxWidth: 150,
        margin: "auto",
        maxHeight: 95,
        marginTop: -25,
        marginBottom: -25,
      };
    }

    return (
      <React.Fragment>
        <AppBar color="primary" position="sticky" elevation={0}>
          <Toolbar>
            <Grid container spacing={0} alignItems="center">
              <Grid item style={{ display: "flex" }}>
                <Hidden lgUp>
                  <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={onDrawerToggle}
                    className={classes.menuButton}
                  >
                    <MenuIcon />
                  </IconButton>
                </Hidden>
                <Hidden mdDown>
                  <img src={LOGO_URL} alt={"Elds"} style={LOGO_STYLES} />
                </Hidden>
              </Grid>
              <Grid item xs />
              {adminKey && (
                <Grid item className={classes.account}>
                  {account}
                </Grid>
              )}
              {adminKey && (
                <Grid item>
                  <Tooltip title="All Accounts">
                    <IconButton
                      color="inherit"
                      onClick={this.props.handleAppAccount}
                    >
                      <GroupIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
              {/*<Grid item>
                                <Tooltip title="Alerts • No alters">
                                    <IconButton color="inherit">
                                        <NotificationsIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>*/}

              <Grid item>
                <Tooltip title="Sign Out">
                  <IconButton
                    onClick={this.logOut}
                    color="inherit"
                    className={classes.iconButtonAvatar}
                  >
                    <SignoutIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </React.Fragment>
    );
  }
}
Header.propTypes = {
  classes: PropTypes.object.isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    account: _get(state, "authData.profile.account.name", ""),
    isUnity: _get(state, "appData.isUnity", false),
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

// export default withStyles(styles)(Header);
export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Header)
);

import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ExploreIcon from "@material-ui/icons/Explore";

const styles = (theme) => ({});

function IftaDetailTable(props) {
  const classes = props.classes;
  const allStatus = props.allStatus;

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell>Location</TableCell>
            <TableCell align="left">Coordinates</TableCell>
            <TableCell align="left">Event</TableCell>
            <TableCell align="left">Date</TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allStatus.map((item, index) => {
            const city = item.city ? `${item.city}, ` : "";
            const state = item.state || "";
            const coords = item.coordinates || [];
            const date = item.date ? props.formatDateTime(item.date) : "";
            return (
              <TableRow key={item._id}>
                <TableCell component="th" scope="row">
                  {`${city} ${state}`}
                </TableCell>
                <TableCell align="left">{`${coords[0]}, ${coords[1]}`}</TableCell>
                <TableCell align="left">{item.eventCode}</TableCell>
                <TableCell align="left">{date}</TableCell>
                <TableCell align="right">
                  <IconButton
                    aria-label="Map"
                    onClick={() => props.viewOnMap(index)}
                  >
                    <ExploreIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default withStyles(styles)(IftaDetailTable);

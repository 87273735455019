import React, { useState, useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {
  updateAdminLogs,
  deleteAdminLogs,
} from "../../actions/account/accountAdminActions";
import { getDrivers } from "../../actions/driverActions";
import ConfirmDialog from "../../components/ConfirmDialog";
import EditShTrDrDialog from "./EditShTrDrDialog";
import Error from "../Error";
import Success from "../Success";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingLeft: 10,
  },
  container: { marginTop: 25, marginBottom: 15 },
  input: { width: "95%" },
  buttonSub: {
    width: "20%",
    marginTop: 10,
    backgroundColor: "#ff00001c",
  },
  buttonAdd: {
    width: "20%",
    marginTop: 10,
    backgroundColor: "#009ddd20",
  },
  buttonEdit: {
    width: "20%",
    marginTop: 10,
    backgroundColor: "",
    marginRight: 2,
  },
  buttonDel: {
    width: "20%",
    marginTop: 10,
    backgroundColor: "",
  },
  formControl: {
    width: "95%",
    marginTop: -3,
  },
}));

const items = Array.from(Array(60), (_, i) => i);

function SelectField(props) {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <FormControl className={props.classes.formControl}>
      <InputLabel id="hours">{props.label}</InputLabel>
      <Select
        labelId={props.label}
        id={props.label}
        value={props.value}
        onChange={props.onChange}
        input={<Input />}
        MenuProps={MenuProps}
      >
        {items.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default function AllLogEdit(props) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isEdit, setEditOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");

  const [days, setDays] = React.useState(0);
  const [hours, setHours] = React.useState(0);
  const [minutes, setMinutes] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);
  const [odometer, setOdometer] = React.useState(0);
  const [engineHours, setEngineHours] = React.useState(0);

  const [drivers, setDrivers] = React.useState([]);

  const _selectedLogs = props.selectedLogs || [];
  const selectedLogs = _selectedLogs.filter((item) => !item.isLast);

  const dispatch = useDispatch();

  useEffect(() => {
    _getDrivers();
  }, []);

  async function onSave(action, data) {
    setLoading(true);
    setSuccess("");
    setError("");
    let _logs = [];
    let logs = selectedLogs.filter((item) => item.isSelected);

    const _odometer = parseFloat(odometer);
    const _engineHours = parseFloat(engineHours);
    const _days = parseFloat(days) || 0;
    const _hours = parseFloat(hours) || 0;
    const _minutes = parseFloat(minutes) || 0;
    const _seconds = parseFloat(seconds) || 0;
    const _dSec = _days * 24 * 60 * 60;
    const _hSec = _hours * 60 * 60;
    const _mSec = _minutes * 60;
    const totalSec = _dSec + _hSec + _mSec + _seconds;

    if (action === "DELETE") {
      _logs = logs.map((item) => item);
    } else if (action === "ADD") {
      _logs = logs.map((item) => {
        const _et = item.eventTime;
        const _odo = item.odometer ? parseFloat(item.odometer) : 0;
        const _eh = item.engineHours ? parseFloat(item.engineHours) : 0;

        if (_odometer && _odo > 0 && _odo > _odometer) {
          item.odometer = _odo + _odometer;
        }
        if (_engineHours && _eh > 0 && _eh > _engineHours) {
          item.engineHours = _eh + _engineHours;
        }

        if (totalSec && totalSec > 0) {
          item.eventTime = moment(_et).add(totalSec, "seconds");
        }

        return item;
      });
    } else if (action === "EDIT") {
      _logs = logs.map((item) => {
        const _et = item.eventTime;
        const _odo = item.odometer ? parseFloat(item.odometer) : 0;
        const _eh = item.engineHours ? parseFloat(item.engineHours) : 0;

        if (data.trailer) {
          item.trailer = data.trailer;
        }
        if (data.shippingDoc) {
          item.shippingDoc = data.shippingDoc;
        }
        if (data.driver) {
          item.driverId = data.driver.id;
          item.driver = {
            id: data.driver.id,
            email: data.driver.email,
            firstName: data.driver.firstName,
            lastName: data.driver.lastName,
          };
        }
        return item;
      });
    } else {
      _logs = logs.map((item) => {
        const _et = item.eventTime;
        const _odo = item.odometer ? parseFloat(item.odometer) : 0;
        const _eh = item.engineHours ? parseFloat(item.engineHours) : 0;

        if (_odometer && _odo > 0 && _odo > _odometer) {
          item.odometer = _odo - _odometer;
        }

        if (_engineHours && _eh > 0 && _eh > _engineHours) {
          item.engineHours = _eh - _engineHours;
        }

        if (totalSec && totalSec > 0) {
          item.eventTime = moment(_et).subtract(totalSec, "seconds");
        }

        return item;
      });
    }
    await saveLogs(_logs, action);
  }

  async function saveLogs(logs, action) {
    try {
      if (logs.length === 0) {
        setError("No log are selected.");
        return;
      }
      // console.log("saveLogs logs :::: ", logs);
      const arrayOfPromises =
        action === "DELETE"
          ? logs.map((item, idx) => _deleteLogs(item, idx))
          : logs.map((item, idx) => _saveLogs(item, idx));
      await Promise.all(arrayOfPromises);

      setLoading(false);
      setSuccess("Logs are saved sucessfully.");
      props.reloadAllLogs();
      reset();
    } catch (e) {
      console.log(e);
      setLoading(false);
      reset();
      setError("Not able to logs.");
    }
  }

  async function _getDrivers() {
    try {
      const response = await dispatch(getDrivers({ skip: 0, limit: 500 }));
      setDrivers(response);
    } catch (e) {
      console.log("e :: ", e);
    }
  }

  async function _saveLogs(log, idx) {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        try {
          const response = await dispatch(updateAdminLogs(log));
          resolve(response);
        } catch (e) {
          resolve(null);
        }
      }, idx * 100);
    });
  }

  async function _deleteLogs(log, idx) {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        try {
          const response = await dispatch(deleteAdminLogs(log));
          resolve(response);
        } catch (e) {
          resolve(null);
        }
      }, idx * 100);
    });
  }

  function reset() {
    setTimeout(() => {
      setDays(0);
      setHours(0);
      setMinutes(0);
      setSeconds(0);
      setOdometer(0);
      setEngineHours(0);
      setSuccess("");
      setError("");
    }, 1000);
  }

  let buttonDisabled = false;
  let _logs = selectedLogs.filter((item) => item.isSelected);

  if (
    _logs.length === 0 ||
    (days === 0 &&
      hours === 0 &&
      minutes === 0 &&
      seconds === 0 &&
      odometer === 0 &&
      engineHours === 0)
  ) {
    buttonDisabled = true;
  } else {
    buttonDisabled = false;
  }

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <ConfirmDialog
        open={isOpen}
        text={"Please confirm, you want to delete selected logs."}
        onClose={(isTrue) => {
          if (isTrue) {
            onSave("DELETE");
          }
          setIsOpen(false);
        }}
      />
      {isEdit && (
        <EditShTrDrDialog
          open={isEdit}
          drivers={drivers}
          onSave={(_data) => {
            onSave("EDIT", _data);
            setEditOpen(false);
          }}
          onClose={() => {
            setEditOpen(false);
          }}
        />
      )}
      <Grid container spacing={0} className={classes.container}>
        <Grid item xs={12} md={12} lg={12}>
          {success && <Alert severity="success">{success}</Alert>}
          {error && <Alert severity="error">{error}</Alert>}
        </Grid>
        <Grid item xs={12} md={1} lg={1}>
          <SelectField
            label={"Days"}
            value={days}
            classes={classes}
            onChange={(e) => setDays(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={1} lg={1}>
          <SelectField
            label={"Hours"}
            value={hours}
            classes={classes}
            onChange={(e) => setHours(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={1} lg={1}>
          <SelectField
            label={"Minutes"}
            value={minutes}
            classes={classes}
            onChange={(e) => setMinutes(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={1} lg={1}>
          <SelectField
            label={"Seconds"}
            value={seconds}
            classes={classes}
            onChange={(e) => setSeconds(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={2} lg={2}>
          <TextField
            size="small"
            type="number"
            className={classes.input}
            id="odometer"
            label="Odometer"
            value={odometer}
            onChange={(e) => setOdometer(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={2} lg={2}>
          <TextField
            size="small"
            type="number"
            className={classes.input}
            id="engineHours"
            label="Engine Hours"
            value={engineHours}
            onChange={(e) => setEngineHours(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={2} lg={4}>
          <Button
            size="small"
            color="secondary"
            disabled={buttonDisabled || loading}
            onClick={() => onSave()}
            className={classes.buttonSub}
          >
            Subtract
          </Button>{" "}
          <Button
            size="small"
            color="primary"
            disabled={buttonDisabled || loading}
            onClick={() => onSave("ADD")}
            className={classes.buttonAdd}
          >
            Add
          </Button>{" "}
          <Button
            size="small"
            color="primary"
            disabled={!_logs.length || loading}
            onClick={() => setEditOpen(true)}
            className={classes.buttonEdit}
            variant="contained"
          >
            Edit
          </Button>
          <Button
            size="small"
            color="secondary"
            disabled={!_logs.length || loading}
            onClick={() => setIsOpen(true)}
            className={classes.buttonDel}
            variant="contained"
          >
            Delete
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

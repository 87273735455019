import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Error from "../Error";
import Success from "../Success";

const styles = theme => ({
    root: {
        padding: theme.spacing(1),
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(1),
        minHeight: 25,
        overflow: "scroll"
    },
    EditIcon: {
        color: "#009be5",
        cursor: "pointer",
        float: "right"
    },
    driverHeader: {
        textAlign: "center",
        padding: 10
    },
    accountRow: {
        padding: 10,
        backgroundColor: "#f0f0f0",
        borderRadius: 10
    },
    accountTitle: {
        display: "inline-block",
        minWidth: 150
    }
});

export class SettingExceptionDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            exceptions: props.exceptions,
            errorMessage: "",
            successMessage: ""
        };
    }

    componentDidMount() { }

    handleToggle = (e, item) => {
        const allItem = this.state.exceptions;
        const exceptions = allItem.map(_item => {
            if (_item.en === item.en) {
                _item.active = e.target.checked;
            }
            return _item;
        });
        this.setState({ exceptions });
    };

    save = async (e, items) => {
        const response = await this.props.updateAccountProperties({
            accountSettingsId: this.props.accountSettingsId,
            exceptions: items
        });
        if (response._id) {
            this.setState({
                successMessage: "Driver exceptions are updated successfully."
            });
        } else {
            this.setState({
                errorMessage: "Something went wrong, please try after sometime."
            });
        }
    };

    render() {
        const { classes, accountUpdateBusy } = this.props;
        const { exceptions, errorMessage, successMessage } = this.state;

        return (
            <Paper className={classes.paper}>
                <div className={classes.driverHeader}>EDIT EXCEPTIONS</div>
                <List className={classes.root}>
                    <Grid container spacing={8}>
                        {Array.isArray(exceptions) &&
                            exceptions.map((item, i) => (
                                <ListItem key={i} role="listitem" button>
                                    <ListItemIcon>
                                        <Checkbox
                                            onClick={e =>
                                                this.handleToggle(e, item)
                                            }
                                            checked={item.active}
                                            tabIndex={-1}
                                            color="primary"
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={item.en} />
                                </ListItem>
                            ))}
                    </Grid>
                </List>
                <Error top={20} message={errorMessage} />
                <Success top={20} message={successMessage} />
                <div className={classes.driverHeader}>
                    <Button
                        color="primary"
                        disabled={accountUpdateBusy}
                        variant="contained"
                        className={classes.button}
                        onClick={e => this.save(e, exceptions)}
                    >
                        {accountUpdateBusy ? "Saving..." : "SAVE EXCEPTIONS"}
                    </Button>
                </div>
            </Paper>
        );
    }
}

const mapStateToProps = state => {
    return {
        store: state
    };
};

export default withStyles(styles)(
    connect(
        mapStateToProps,
        {}
    )(SettingExceptionDetail)
);

import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { reduxForm } from "redux-form";
import { getTimeZone } from "../../../actions/momentActions";
import {
    createTerminal,
    updateTerminal
} from "../../../actions/terminalActions";
import validate from "./validate";
import Grid from "@material-ui/core/Grid";
import ReduxButton from "../../../components/ReduxButton";
import ReduxInput from "../../../components/ReduxInput";
import ReduxSelect from "../../../components/ReduxSelect";
import Error from "../../Error";
import Success from "../../Success";

const styles = theme => ({
    wrapper: {},
    DialogActions: {
        marginTop: 20
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

export class AddTerminalForm extends React.Component {
    state = {};

    componentDidMount() {
        const isEdit = _get(this, "props.isEdit");

        if (!isEdit) {
            const timezone = this.props.getTimeZone();
            this.props.change("timezone", timezone);
        }
    }

    save = async values => {
        this.setState({ errorMessage: "", successMessage: "" });
        const isEdit = _get(this, "props.isEdit");

        let response;

        if (isEdit) {
            response = await this.props.updateTerminal(values);
        } else {
            response = await this.props.createTerminal(values);
        }

        if (response && response.id) {
            this.setState({ successMessage: "Saved successfully" });
            setTimeout(() => {
                this.props.handleClose();
            }, 1000);
        } else if (response && response.message) {
            this.setState({ errorMessage: response.message });
        } else {
            if (typeof response !== String) {
                response = "Something went wrong, please try after time.";
            }
            this.setState({ errorMessage: response });
        }
    };

    render() {
        const {
            isEdit,
            classes,
            loading,
            handleSubmit,
            constants,
            termialValues
        } = this.props;
        const { errorMessage, successMessage } = this.state;
        const isSmallScreen = window.innerWidth < 400;
        return (
            <Dialog
                fullScreen={isSmallScreen}
                fullWidth={true}
                maxWidth={"md"}
                className={classes.wrapper}
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography>
                        {isEdit ? "EDIT TERMINAL" : "ADD TERMINAL"}
                    </Typography>
                    <IconButton
                        aria-label="Close"
                        className={classes.closeButton}
                        onClick={this.props.handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <form noValidate autoComplete="off">
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={12} md={6}>
                                <ReduxInput name="name" label="Name" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <ReduxSelect
                                    name="timezone"
                                    label="Timezone"
                                    items={constants.TIME_ZONES}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={2}>
                                <ReduxSelect
                                    name="country"
                                    label="Country"
                                    keyName={"id"}
                                    valueName={"id"}
                                    items={constants.COUNTRIES}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={3}>
                                <ReduxInput
                                    name="line1"
                                    label="Address line1"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <ReduxInput name="line2" label="Suit., bldg." />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <ReduxInput name="city" label="City" />
                            </Grid>
                            {termialValues.country === "CA" ? (
                                <Grid item xs={12} sm={12} md={1}>
                                    <ReduxSelect
                                        name="state"
                                        label="State"
                                        keyName={"id"}
                                        valueName={"id"}
                                        items={constants.CA_STATES}
                                    />
                                </Grid>
                            ) : (
                                    <Grid item xs={12} sm={12} md={1}>
                                        <ReduxSelect
                                            name="state"
                                            label="State"
                                            keyName={"id"}
                                            valueName={"id"}
                                            items={constants.US_STATES}
                                        />
                                    </Grid>
                                )}
                            <Grid item xs={12} sm={12} md={2}>
                                <ReduxInput name="zip" label="Zip" />
                            </Grid>

                            <Error top={20} message={errorMessage} />
                            <Success top={20} message={successMessage} />
                        </Grid>
                        <DialogActions className={classes.DialogActions}>
                            <ReduxButton
                                width
                                loading={loading}
                                onClick={handleSubmit(this.save)}
                                marginTop={25}
                                label={"SAVE"}
                            />
                            <Button
                                onClick={this.props.handleClose}
                                color="primary"
                                autoFocus
                            >
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        constants: _get(state, "appData.constants", {}),
        errorMessage: _get(state, "equipmentData.error", ""),
        loading: _get(state, "appData.appBusy", false),
        termialValues: _get(state, "form.termialForm.values", {}),
        carriers: _get(state, "carrierData.carriers", [])
    };
};

AddTerminalForm = reduxForm({
    form: "termialForm",
    validate
})(AddTerminalForm);

AddTerminalForm = connect(state => {
    return {
        initialValues: _get(state, "terminalData.selectedTerminal", {})
    };
})(AddTerminalForm);

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            createTerminal,
            updateTerminal,
            getTimeZone
        },
        dispatch
    );

export default withStyles(styles)(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(AddTerminalForm)
    )
);

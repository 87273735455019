import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm } from "redux-form";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ReduxCheckBox from "../../components/ReduxCheckBox";
import ReduxSelect from "../../components/ReduxSelect";
import Error from "../Error";
import Success from "../Success";
import validate from "./validate";
import { THEME } from "../../constants";

const styles = theme => ({
    root: {
        padding: theme.spacing(1),
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(1),
        minHeight: 25,
        overflow: "scroll"
    },
    EditIcon: {
        color: "#009be5",
        cursor: "pointer",
        float: "right"
    },
    driverHeader: {
        textAlign: "center",
        padding: 10,
        marginTop: 20
    },
    accountRow: {
        padding: 10,
        backgroundColor: "#f0f0f0",
        borderRadius: 10
    },
    accountTitle: {
        display: "inline-block",
        minWidth: 150
    },
    checkBoxLabel: {
        minWidth: "100%"
    }
});

export class SettingEditReasonDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accountSettings: props.accountSettings,
            errorMessage: "",
            successMessage: ""
        };
    }

    componentDidMount() { }

    save = async payload => {
        // console.log("payload ", payload);
        // payload.settings.debug = true;
        const response = await this.props.updateAccountProperties(payload);
        // console.log("response ", response);
        if (response._id) {
            this.setState({
                successMessage: "Account properties are updated successfully."
            });
        } else {
            this.setState({
                errorMessage: "Something went wrong, please try after sometime."
            });
        }
    };

    render() {
        const {
            classes,
            constants,
            accountBusy,
            handleSubmit,
            accountUpdateBusy
        } = this.props;
        const { errorMessage, successMessage } = this.state;

        return (
            <Paper className={classes.paper}>
                <div className={classes.driverHeader}>ACCOUNT SETTINGS</div>
                {!accountBusy && (
                    <form
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit(this.save)}
                    >
                        <Grid container spacing={8}>
                            {/*<Grid item xs={12} md={3}>
                                <ReduxCheckBox
                                    name="autoOnDuty"
                                    label={"Auto On Duty Driver"}
                                />
                            </Grid>*/}
                            <Grid item xs={12} md={2}>
                                <ReduxCheckBox
                                    name="offRoad"
                                    label={"Off Road"}
                                />
                            </Grid>
                            {/*<Grid item xs={12} md={3}>
                                <ReduxCheckBox
                                    name="showFullAddress"
                                    label={"Show FullAddress"}
                                />
                            </Grid>*/}
                            <Grid item xs={12} md={2}>
                                <ReduxCheckBox
                                    name="updateLocation"
                                    label={"GPS Tracking"}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <ReduxCheckBox
                                    name="settings.showAllLogs"
                                    label={"All Logs"}
                                />
                            </Grid>
                            {/*<Grid item xs={12} md={4}>
                                <ReduxSelect
                                    disabled={true}
                                    name="speedThreshold"
                                    label="Threshold speed"
                                    items={constants.THRESHOLD_SPEED}
                                />
                            </Grid>*/}
                            <Grid item xs={12} sm={12} md={3}>
                                <ReduxSelect
                                    name="updateFrequency"
                                    label="Update frequency"
                                    items={constants.UPDATE_FREQUENCY}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <ReduxSelect
                                    name="theme"
                                    label="Theme"
                                    items={THEME}
                                />
                            </Grid>
                        </Grid>
                        <Error top={20} message={errorMessage} />
                        <Success top={20} message={successMessage} />
                        <div className={classes.driverHeader}>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                disabled={accountUpdateBusy}
                            >
                                {accountUpdateBusy ? "Saving..." : "SAVE"}
                            </Button>
                        </div>
                    </form>
                )}
            </Paper>
        );
    }
}

const mapStateToProps = state => {
    return {
        constants: _get(state, "appData.constants", {}),
        loading: _get(state, "appData.appBusy", false),
        accountBusy: _get(state, "appData.accountBusy", false),
        accountUpdateBusy: _get(state, "appData.accountUpdateBusy", false)
    };
};

SettingEditReasonDetail = reduxForm({
    form: "settingForm",
    enableReinitialize: true,
    validate
})(SettingEditReasonDetail);

SettingEditReasonDetail = connect(state => {
    const accountSettings = _get(state, "appData.accountSettings", {});

    return {
        initialValues: {
            offRoad: accountSettings.offRoad,
            settings: accountSettings.settings,
            updateLocation: accountSettings.updateLocation,
            updateFrequency: accountSettings.updateFrequency,
            theme: accountSettings.theme,
            accountSettingsId: accountSettings._id
        }
    };
})(SettingEditReasonDetail);

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SettingEditReasonDetail)
);

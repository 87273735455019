const INPUTS = {
  signInLabel: "Email",
  signInPlaceholder: "Email",
  signInError: "Required",

  passwordLabel: "Password",
  passwordPlaceholder: "Password",
  passwordError: "Required",

  errorInvalidEmailPwd: "Invalid email or password",
};

const BUTTONS = {
  logInLabel: "Log In",
  forgetPwdLabel: "Forget Password",
};

export { INPUTS, BUTTONS };

import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import UserNavigator from "../../Navigator/UserNavigator";

const styles = theme => ({});

function Setting(props) {
    return <UserNavigator accountId={props.accountId} />;
}

const mapStateToProps = state => {
    return {
        store: state,
        accountId: _get(state, "authData.accountId")
    };
};

export default withStyles(styles)(
    connect(
        mapStateToProps,
        {}
    )(Setting)
);

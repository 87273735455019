import _get from "lodash/get";

function pF(value) {
  return parseFloat(value) ? parseFloat(value) : 0;
}

export function getCoDriver(props) {
  const selectedDate = props.selectedDate || "";
  const baseUrl = props.accountBaseUrl || "";
  const coDrivers = _get(props, "dailyDiary[0].coDriver", []);

  let all = {};

  if (coDrivers && Array.isArray(coDrivers)) {
    coDrivers.forEach((item, i) => {
      let fullName = item.firstName ? `${item.firstName} ${item.lastName}` : "";
      if (fullName && fullName !== "null") {
        all[fullName] = {
          id: item.id || item._id,
          name: fullName,
        };
      }
    });
  }

  const url = `${baseUrl}/status/detail`;
  return Object.values(all).map((item) => (
    <div key={item.name}>
      {props.role === "DRIVER" ? (
        item.name
      ) : (
        <a
          style={{ padding: 0, color: "#009be5", textDecoration: "none" }}
          href={`${url}/${item.id}?selectedDate=${selectedDate}`}
        >
          {item.name}
        </a>
      )}
    </div>
  ));
}

export function getVehicleDriven(props) {
  const { driverLogs = [], dailyDiary = [] } = props;
  const diaryVehicles = _get(dailyDiary[0], "vehicle", []);
  let vehicle = "";
  diaryVehicles.forEach((item, index) => {
    if (index === 0) {
      vehicle = vehicle + "" + item.name;
    } else {
      vehicle = vehicle + "," + item.name;
    }
  });

  let all = {};
  driverLogs.forEach((item) => {
    const name = item.vehicle && item.vehicle.name;
    // const vin = item.vehicle && item.vehicle.vin;
    if (name && name !== "null") {
      all[name] = `${name} `;
    }
  });

  return vehicle ? (
    <span>{vehicle}</span>
  ) : (
    Object.values(all).map((item) => <span key={item}>{item}</span>)
  );
}

export function getTrailerDriven(props) {
  const { driverLogs = [] } = props;

  let all = [];
  driverLogs.forEach((item) => {
    const name = item.trailer;
    if (name && name !== "null" && item.recordStatus === "ACTIVE") {
      if (!all.includes(name)) {
        all.push(name);
      }
    }
  });
  return all.join(", ");
}

export function getShippingDocs(props) {
  const { driverLogs = [] } = props;
  let all = [];
  driverLogs.forEach((item) => {
    const name = item.shippingDoc;
    if (name && name !== "null" && item.recordStatus === "ACTIVE") {
      if (!all.includes(name)) {
        all.push(name);
      }
    }
  });
  return all.join(", ");
}

export function getEngineHours(props) {
  const allLogs = props.driverLogs || [];
  let start = 0;
  let end = 0;
  for (let i = 0; i < allLogs.length; i++) {
    const current = allLogs[i] || {};

    let valid = current.engineHours ? parseFloat(current.engineHours) : 0;
    if (!start && valid) {
      start = valid;
    }
    if (valid) {
      end = valid;
    }
  }

  const mi = parseFloat(end) - parseFloat(start);
  return `${mi.toFixed(2)} Hours`;
}

export function getOdometer(props) {
  const allLogs =
    Array.isArray(props.driverLogs) &&
    props.driverLogs.filter((item) => {
      return (
        item.recordStatus === "ACTIVE" &&
        (item.eventType === "CHANGE_DUTY_STATUS" ||
          item.eventType === "YARD_MOVES" ||
          item.eventType === "PERSONAL_USE") &&
        item.odometer !== 0
      );
    });

  let start = 0;
  let end = 0;
  for (let i = 0; i < allLogs.length; i++) {
    const current = allLogs[i] || {};
    let valid = current.odometer ? parseFloat(current.odometer) : 0;
    if (!start && valid) {
      start = valid;
    }
    if (valid) {
      end = valid;
    }
  }
  const mi = pF(end) - pF(start);
  return `${Math.round(mi)} Miles`;
}

export function getUnidentifiedDriving() {
  return "Unidentified Driving Time = 00:00:00";
}

export function getTerminal(props) {
  const selectedDriver = props.selectedDriver || {};
  const terminal = selectedDriver.terminal || {};
  // const line1 = terminal.line1 ? `${terminal.line1}, ` : "";
  // const city = terminal.city ? `${terminal.city}, ` : "";
  // const state = terminal.state ? `${terminal.state}-` : "";
  // const zip = terminal.zip ? terminal.zip : "";

  // return `${line1} ${city} ${state}${zip}`;
  return terminal.name ? terminal.name : "";
}

export function getTerminalAddress(props) {
  const selectedDriver = props.selectedDriver || {};
  const terminal = selectedDriver.terminal || {};
  const line1 = terminal.line1 ? `${terminal.line1}, ` : "";
  const city = terminal.city ? `${terminal.city}, ` : "";
  const state = terminal.state ? `${terminal.state}` : "";
  // const zip = terminal.zip ? terminal.zip : "";

  return `${line1} ${city} ${state}`;
}

export function getInfoArray(props) {
  const selectedDate = props.selectedDate;
  const formatRawDate = props.formatRawDate;
  const selectedDriver = props.selectedDriver || {};
  const driver = selectedDriver.driver || {};
  const carrier = selectedDriver.carrier || {};
  const timezone = _get(selectedDriver, "terminal.timezone");
  const cycle = _get(props, "cycleData.cycleUsa");
  const android = _get(props, "dailyDiary[0].androidSoftwareVersion", "");
  const androidOS = _get(props, "dailyDiary[0].androidOsVersion", "");
  const ios = _get(props, "dailyDiary[0].iosSoftwareVersion", "");
  const iosOS = _get(props, "dailyDiary[0].iosOsVersion", "");

  const seperator = android && ios ? ", " : "";

  const appConfigAndroid = props.appConfig?.android || {};
  const appConfigIos = props.appConfig?.ios || {};

  let color = "black";

  if (!android) {
    if (parseFloat(ios) <= parseFloat(appConfigIos.outdated)) {
      color = "red";
    } else if (parseFloat(ios) < parseFloat(appConfigIos.current)) {
      color = "orange";
    }
  } else {
    if (parseFloat(android) <= parseFloat(appConfigAndroid.outdated)) {
      color = "red";
    } else if (parseFloat(android) < parseFloat(appConfigAndroid.current)) {
      color = "orange";
    }
  }

  const allItems = [
    {
      label: "DATE",
      value: formatRawDate(selectedDate),
      labelMiddle: "START TIME",
      valueMiddle: `${driver.startTime || ""} ${timezone || ""}`,
      labelRight: "CYCLE",
      valueRight: `${(cycle ? cycle : driver.cycleUsa) || ""}`,
    },
    {
      label: "DRIVER",
      value: `${selectedDriver.firstName || ""} ${
        selectedDriver.lastName || ""
      }`,
      labelMiddle: "CARRIER",
      valueMiddle: `${carrier.name || ""}`,
      labelRight: "CO DRIVERS",
      valueRight: getCoDriver(props),
    },
    {
      label: "VEHICLES",
      value: getVehicleDriven(props),
      labelMiddle: "HOME TERMINAL",
      valueMiddle: getTerminal(props),
      valueMiddleTwo: getTerminalAddress(props),
      labelRight: "DISTANCE",
      valueRight: getOdometer(props),
    },

    {
      label: "TRAILERS",
      value: getTrailerDriven(props),
      labelMiddle: "SHIPPING DOCS",
      labelMiddleLink: true,
      valueMiddle: getShippingDocs(props),
      labelRight: "ENGINE HOURS",
      valueRight: getEngineHours(props),
    },
    {
      label: "EMAIL",
      value: `${selectedDriver.email || ""}`,
      labelMiddle: "App",
      valueMiddleStyle: { color: color },
      valueMiddle: `${ios}${seperator} ${android}`,
      labelRight: "Device",
      valueRight: `${iosOS}${seperator} ${androidOS}`,
    },
  ];

  return allItems;
}

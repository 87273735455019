import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import offDutyTruck from "../../utils/icons/offDutyTruck.png";
import Button from "@material-ui/core/Button";
import NotFoundTableRow from "../NotFoundTableRow";
import DvirDialog from "./DvirDialog";

const styles = (theme) => ({
  root: {
    width: "98%",
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: theme.spacing(1),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  capitalize: {
    textTransform: "capitalize",
  },
  icon: {
    color: "#009be5",
    fontSize: 16,
    marginBottom: -2,
    width: 25,
  },
  title: {
    fontWeight: 600,
    margin: "10px auto 5px",
  },
  EditIcon: {
    color: "#009be5",
    cursor: "pointer",
  },
  dataXLargeRow: {
    minWidth: 200,
  },
  dataLargeRow: {
    minWidth: 175,
  },
  dataMedRow: {
    minWidth: 125,
  },
  dataSmallRow: {
    minWidth: 100,
  },
});

class ReduxTableRow extends React.Component {
  handleDownload = (e, item) => {};

  render() {
    const { adminKey, classes, item = {} } = this.props;

    return (
      <TableRow hover>
        <TableCell component="th" scope="row">
          <div className={classes.capitalize}>
            {item.inspectionType}
            <div>{item.templateId}</div>
          </div>
        </TableCell>
        <TableCell component="th" scope="row">
          {item.status}
        </TableCell>
        <TableCell>
          <div className={classes.dataSmallRow}>
            <div>
              <img
                style={{
                  width: 15,
                  top: 3,
                  position: "relative",
                  marginRight: "5px",
                }}
                alt={"Truck"}
                src={offDutyTruck}
              />
              <span className={classes.capitalize}>{item.vehicleName}</span>
            </div>
            {item.driverName ? (
              <div className={classes.capitalize}>{item.driverName}</div>
            ) : (
              <div className={classes.capitalize}>{item.inspectorType}</div>
            )}
          </div>
        </TableCell>
        <TableCell component="th" scope="row">
          <div className={classes.dataMedRow}>
            <div>
              Status:{" "}
              <span className={classes.capitalize}>
                {item.equipmentCondition}
              </span>
            </div>
            <div>
              Odometer: <span>{item.odometer} Miles</span>
            </div>
          </div>
        </TableCell>
        <TableCell>
          <div className={classes.dataMedRow}>
            {item.created && (
              <div>Start: {this.props.formatDateTime(item.startTime)}</div>
            )}
            {item.endTime && (
              <div>End: {this.props.formatDateTime(item.endTime)}</div>
            )}
          </div>
        </TableCell>
        <TableCell>
          <div className={classes.dataSmallRow}>{item.location}</div>
        </TableCell>
        <TableCell>
          <Button
            size="small"
            color="primary"
            variant="contained"
            style={{ marginTop: 5 }}
            onClick={() => this.props.onSelect(item)}
          >
            {"View"}
          </Button>
        </TableCell>
      </TableRow>
    );
  }
}

export class DvirTable extends React.Component {
  state = {
    order: "asc",
    isView: false,
    selected: null,
  };

  handleChangePage = (event, page) => {
    const { limit } = this.props;
    this.props.handleChange({ page, limit });
    // this.setState({ page }, this.getAllDvir);
  };

  handleChangeRowsPerPage = (event) => {
    const limit = event.target.value;
    const { page } = this.props;
    this.props.handleChange({ page, limit });
    // this.setState({ limit }, this.getAllDvir);
  };

  getAllDvir = () => {
    const { limit, page } = this.state;
    const skip = parseInt(page) * parseInt(limit);
    this.props.getAllDvir({ limit, skip });
  };
  render() {
    const { eld, classes, items = [], handleEdit, count = 100 } = this.props;

    const { limit, page } = this.props;
    const isView = this.state.isView;
    const selected = this.state.selected;

    return (
      <Paper className={classes.root}>
        {isView && selected && (
          <DvirDialog
            open={isView}
            selected={selected}
            onClose={() => {
              this.setState({ selected: null, isView: false });
            }}
          />
        )}
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Inspector</TableCell>
              <TableCell>Condition</TableCell>
              <TableCell>Start/End</TableCell>
              <TableCell>Location</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(items) &&
              items.map((item, i) => (
                <ReduxTableRow
                  item={item}
                  key={i}
                  eld={eld}
                  classes={classes}
                  handleEdit={handleEdit}
                  formatDateTime={this.props.formatDateTime}
                  adminKey={this.props.adminKey}
                  onSelect={(selected) => {
                    this.setState({ selected, isView: true });
                  }}
                />
              ))}
          </TableBody>
        </Table>
        <NotFoundTableRow items={items} />
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
          component="div"
          count={count}
          rowsPerPage={limit}
          page={page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

DvirTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DvirTable);

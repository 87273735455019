import _get from "lodash/get";
import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
  Polyline,
  InfoWindow,
} from "react-google-maps";
import Card from "@material-ui/core/Card";
import { formatPaths, formatStops, startLatLng, carImage } from "./helpers";

function RenderMarker(props) {
  const item = props.item || {};
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (props.dId) {
      if (props.dId === item.uqId) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }
  });

  const address = _get(props, "item.data.address.line1");
  const _time = _get(props, "item.data.time");
  const time = `${props.formatDate(_time)} ${props.formatTime(_time)}`

  return (
    <Marker
      position={{ lat: props.lat, lng: props.lng }}
      prerender={false}
      labelAnchor={new window.google.maps.Point(0, 0)}
      labelStyle={{
        backgroundColor: "grey",
        color: "white",
        fontSize: "10px",
        padding: "1px",
      }}
      onClick={() => {
        if (props.dId) {
          props.onInfoWindowClose(item.driverId);
        }
        setIsOpen(true);
        props.onMarkerSelect(item);
      }}
      label={`${props.index + 1}`}
    >
      {isOpen ? (
        <InfoWindow
          onCloseClick={() => {
            setIsOpen(false);
            props.onInfoWindowClose();
            props.onMarkerReset();
          }}
        >
          <div style={{ textAlign: "center" }}>
            <div
              style={{
                textAlign: "center",
                padding: "5px 2px 0px 2px",
                marginTop: "0px",
                fontSize: "12px",
                color: "#333333",
                textTransform: "uppercase",
                cursor: "default",
                width: 200,
              }}
            >
              <p style={{ margin: "0", marginBottom: "1px", fontWeight: 600 }}>
                {`${item?.id}`}
              </p>
              <p style={{ margin: "0", marginBottom: "1px", fontWeight: 400 }}>
                {`${address}`}
              </p>
              <p style={{ margin: "0", marginBottom: "1px", fontWeight: 200 }}>
                {`${time}`}
              </p>
            </div>
          </div>
        </InfoWindow>
      ) : null}
    </Marker>
  );
}

const Map = (props) => {
  const locations = props.locations;
  let paths = formatPaths(locations);
  let stops = formatStops(locations);

  const [progress] = useState(null);
  // const velocity = 270; // 100km per hour
  // let initialDate;
  let interval = null;
  const icon1 = {
    url: carImage,
    scaledSize: new window.google.maps.Size(40, 40),
    anchor: new window.google.maps.Point(20, 20),
    scale: 0.7,
  };

  let zoom = 10;
  let center = startLatLng(locations);

  useEffect(() => {
    calculatePath();

    return () => {
      console.log("CLEAR........");
      interval && window.clearInterval(interval);
    };
  }, [paths]);

  // const getDistance = () => {
  //   // seconds between when the component loaded and now
  //   const differentInTime = (new Date() - initialDate) / 1000; // pass to seconds
  //   return differentInTime * velocity; // d = v*t -- thanks Newton!
  // };

  // const moveObject = () => {
  //   const distance = getDistance();
  //   if (!distance) {
  //     return;
  //   }
  //
  //   let progress = paths.filter(
  //     (coordinates) => coordinates.distance < distance
  //   );
  //
  //   const nextLine = paths.find(
  //     (coordinates) => coordinates.distance > distance
  //   );
  //
  //   if (!nextLine) {
  //     setProgress(progress);
  //     window.clearInterval(interval);
  //     console.log("Trip Completed!! Thank You !!");
  //     return; // it's the end!
  //   }
  //   const lastLine = progress[progress.length - 1];
  //
  //   const lastLineLatLng = new window.google.maps.LatLng(
  //     lastLine.lat,
  //     lastLine.lng
  //   );
  //
  //   const nextLineLatLng = new window.google.maps.LatLng(
  //     nextLine.lat,
  //     nextLine.lng
  //   );
  //
  //   // distance of this line
  //   const totalDistance = nextLine.distance - lastLine.distance;
  //   const percentage = (distance - lastLine.distance) / totalDistance;
  //
  //   const position = window.google.maps.geometry.spherical.interpolate(
  //     lastLineLatLng,
  //     nextLineLatLng,
  //     percentage
  //   );
  //
  //   mapUpdate();
  //   setProgress(progress.concat(position));
  // };

  const calculatePath = () => {
    paths = paths.map((coordinates, i, array) => {
      if (i === 0) {
        return { ...coordinates, distance: 0 }; // it begins here!
      }
      const { lat: lat1, lng: lng1 } = coordinates;
      const latLong1 = new window.google.maps.LatLng(lat1, lng1);

      const { lat: lat2, lng: lng2 } = array[0];
      const latLong2 = new window.google.maps.LatLng(lat2, lng2);

      // in meters:
      const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
        latLong1,
        latLong2
      );

      return { ...coordinates, distance };
    });
  };

  // const startSimulation = useCallback(() => {
  //   if (interval) {
  //     window.clearInterval(interval);
  //   }
  //   setProgress(null);
  //   initialDate = new Date();
  //   interval = window.setInterval(moveObject, 1000);
  // }, [interval, initialDate]);

  // const stopSimulation = useCallback(() => {
  //   if (interval) {
  //     window.clearInterval(interval);
  //   }
  //   setProgress(null);
  // }, [interval, initialDate]);

  // const pauseSimulation = useCallback(() => {
  //   if (interval) {
  //     window.clearInterval(interval);
  //   }
  // }, [interval, initialDate]);

  // const mapUpdate = () => {
  //   const distance = getDistance();
  //   if (!distance) {
  //     return;
  //   }
  //
  //   let progress = paths.filter(
  //     (coordinates) => coordinates.distance < distance
  //   );
  //
  //   const nextLine = paths.find(
  //     (coordinates) => coordinates.distance > distance
  //   );
  //
  //   let point1, point2;
  //
  //   if (nextLine) {
  //     point1 = progress[progress.length - 1];
  //     point2 = nextLine;
  //   } else {
  //     // it's the end, so use the latest 2
  //     point1 = progress[progress.length - 2];
  //     point2 = progress[progress.length - 1];
  //   }
  //
  //   const point1LatLng = new window.google.maps.LatLng(point1.lat, point1.lng);
  //   const point2LatLng = new window.google.maps.LatLng(point2.lat, point2.lng);
  //
  //   const angle = window.google.maps.geometry.spherical.computeHeading(
  //     point1LatLng,
  //     point2LatLng
  //   );
  //   const actualAngle = angle - 90;
  //
  //   const marker = document.querySelector(`[src="${icon1.url}"]`);
  //
  //   if (marker) {
  //     // when it hasn't loaded, it's null
  //     marker.style.transform = `rotate(${actualAngle}deg)`;
  //   }
  // };

  function zoomToMarkers(map) {
    if (locations && locations.length && !progress) {
      const bounds = new window.google.maps.LatLngBounds();
      locations.forEach((child) => {
        if (child.coordinates && child.coordinates[0] && child.coordinates[1])
          bounds.extend(
            new window.google.maps.LatLng(
              child.coordinates[0],
              child.coordinates[1]
            )
          );
      });
      map && map.fitBounds(bounds);
    }
  }

  if (progress) {
    center = progress[progress.length - 1];
    zoom = 14;
  }

  return (
    <Card variant="outlined">
      {/*<div
        className="btnCont"
        style={{ marginTop: -30, marginLeft: 70, position: "absolute" }}
      >
        <IconButton aria-label="Play" size="small" onClick={startSimulation}>
          <PlayCircleOutlineIcon />
        </IconButton>
        <IconButton aria-label="Pause" size="small" onClick={pauseSimulation}>
          <PauseCircleOutlineIcon />
        </IconButton>
        <IconButton aria-label="Stop" size="small" onClick={stopSimulation}>
          <StopIcon />
        </IconButton>
      </div>*/}

      <div className="gMapCont">
        <GoogleMap zoom={zoom} center={center} ref={zoomToMarkers}>
          <Polyline
            path={paths}
            options={{
              strokeColor: "#0088FF",
              strokeWeight: 6,
              strokeOpacity: 0.6,
              defaultVisible: true,
            }}
          />

          {stops.data &&
            stops.data.map((stop, idx) => (
              <RenderMarker
                key={`id-${idx}`}
                lat={stop.lat}
                lng={stop.lng}
                driver={{}}
                index={idx}
                item={stop}
                formatDate={props.formatDate}
                formatTime={props.formatTime}
              />
            ))}

          {progress && (
            <>
              <Polyline path={progress} options={{ strokeColor: "orange" }} />

              <Marker icon={icon1} position={progress[progress.length - 1]} />
            </>
          )}
        </GoogleMap>
      </div>
    </Card>
  );
};

export default withScriptjs(withGoogleMap(Map));

const DASHCAMERA = {
  header: "El video integrado está aquí.",
  header2:
    "Osrit Dashcam lo ayuda a mejorar el comportamiento de los conductores y mitigar los riesgos para su negocio al proporcionar un contexto visual de los eventos de conducción difíciles.",
  header3: "Para saber más, por favor visite en",
  header3Link: "www.truckdashcamera.com",
};

const OSRIT = {
  header: "Software de transporte en la nube | TMS.",
  header2:
    "¡El software Osrit Cloud lo ayuda a despachar fácilmente cargas FTL o LTL, clientes, corredores, conductores, nómina, facturación, etc. desde un solo lugar!",
  header3: "Para saber más, por favor visite en",
  header3Link: "www.osrit.com",
};

export { DASHCAMERA, OSRIT };

import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Formik, Form } from "formik";
import { signIn } from "../../../actions/auth/authActions";
import { getTimeZone } from "../../../actions/momentActions";
import { createCarrier, updateCarrier } from "../../../actions/carrierActions";
import { getResellers } from "../../../actions/reseller/resellerActions";
import validate from "./validate";
import Grid from "@material-ui/core/Grid";
import InputBox from "../../../components/InputBox";
import SelectBox from "../../../components/SelectBox";
import Error from "../../Error";
import Success from "../../Success";

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export class AddAccountForm extends React.Component {
  state = {
    resellers: [],
  };

  componentDidMount() {
    // const timezone = this.props.getTimeZone();
    // this.props.change("timezone", timezone);
    this.getResellers();
  }

  getResellers = async () => {
    if (!this.props.adminAccess) {
      return null;
    }
    try {
      const resellers = await this.props.getResellers();
      if (resellers && Array.isArray(resellers)) {
        this.setState({ resellers });
      }
    } catch (e) {
      console.log(e);
    }
  };

  handleSubmit = async (values) => {
    this.setState({ errorMessage: "", successMessage: "", loading: true });

    let response = await this.props.createAccount(values);

    if (response && response.account && response.account._id) {
      this.setState({
        successMessage: "Account is created successfully",
        loading: false,
      });
      this.props.getAccounts();
      setTimeout(() => {
        this.props.handleClose();
      }, 1000);
    } else if (response && response.message) {
      this.setState({ errorMessage: response.message, loading: false });
    } else {
      if (typeof response !== String) {
        response = "Something went wrong, please try after some time.";
      }
      this.setState({ errorMessage: response, loading: false });
    }
  };

  render() {
    const { classes, constants } = this.props;
    const { adminAccess, resellers } = this.props;
    const isSmallScreen = window.innerWidth < 400;
    const { errorMessage, successMessage, loading } = this.state;

    const initialValues = {
      status: "ACTIVE",
      country: "US",
      city: "",
      dot: "",
      email: "",
      firstName: "",
      lastName: "",
      line1: "",
      line2: "",
      name: "",
      notes: "",
      password: "",
      phone: "",
      state: "",
      timezone: "America/Los_Angeles",
      zip: "",
    };

    return (
      <Dialog
        fullScreen={isSmallScreen}
        fullWidth={true}
        maxWidth={"lg"}
        className={classes.wrapper}
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography>{"ADD ACCOUNT"}</Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={this.handleSubmit}
            enableReinitialize={true}
          >
            {({ values, handleSubmit, setFieldValue }) => {
              const ALL_STATES =
                values.country === "CA"
                  ? constants.CA_STATES
                  : constants.US_STATES;

              return (
                <Form style={{ padding: "20px 10px" }}>
                  <Grid container spacing={3}>
                    <SelectBox
                      md={adminAccess ? 1 : 2}
                      name="status"
                      label="Status"
                      disabled={true}
                      items={constants.ALL_STATUS_OPTIONS}
                    />
                    <SelectBox
                      md={adminAccess ? 1 : 2}
                      name="country"
                      label="Country"
                      selLabel={"id"}
                      items={constants.COUNTRIES}
                    />
                    {adminAccess && (
                      <SelectBox
                        md={3}
                        selValue={"_id"}
                        name="resellerId"
                        label="Reseller"
                        items={resellers}
                      />
                    )}
                    <InputBox md={3} name="name" label="Carrier name" />
                    <InputBox md={2} name="dot" label="DOT" />
                    <SelectBox
                      md={adminAccess ? 2 : 3}
                      name="timezone"
                      label="Timezone"
                      items={constants.TIME_ZONES}
                    />

                    <InputBox name="line1" label="Address line1" />
                    <InputBox md={2} name="line2" label="Suit., bldg." />
                    <InputBox md={2} name="city" label="City" />
                    <SelectBox
                      md={2}
                      name="state"
                      label="State"
                      selLabel={"id"}
                      selLabelThree={"name"}
                      items={ALL_STATES}
                    />

                    <InputBox md={2} name="zip" label="Zip" />
                    <InputBox name="firstName" label="First name" />
                    <InputBox name="lastName" label="Last name" />
                    <InputBox name="phone" label="Phone" />
                    <InputBox name="email" label="Email" />
                    <InputBox name="password" label="Password" />

                    <InputBox name="notes" label="Note" />
                  </Grid>
                  {successMessage && <Success message={successMessage} />}
                  {errorMessage && <Error message={errorMessage} />}
                  <DialogActions className={classes.DialogActions}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={loading}
                    >
                      {loading ? "Saving..." : "SAVE"}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={this.props.handleClose}
                    >
                      CLOSE
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    constants: _get(state, "appData.constants", {}),
    errorMessage: _get(state, "equipmentData.error", ""),
    loading: _get(state, "accountData.accountCreateBusy", false),
    accountValues: _get(state, "form.accountForm.values", {}),
    adminAccess: _get(state, "authData.profile.adminAccess", false),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signIn,
      createCarrier,
      updateCarrier,
      getTimeZone,
      getResellers,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AddAccountForm))
);

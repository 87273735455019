import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import NotFoundTableRow from "../../NotFoundTableRow";

const styles = theme => ({
    root: {
        width: "98%",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: theme.spacing(1),
        overflowX: "auto"
    },
    table: {
        minWidth: 700
    },
    tableRowTitle: {
        display: "inline-block",
        width: 50
    },
    capitalize: {
        textTransform: "capitalize"
    },
    EditIcon: {
        color: "#009be5",
        cursor: "pointer"
    }
});

class ReduxTableRow extends React.Component {
    render() {
        const { eld, classes, item = {}, adminKey } = this.props;
        const isEdit = item.active ? item.active : adminKey ? true : false;

        return (
            <TableRow hover>
                <TableCell component="th" scope="row">
                    {item.name}
                </TableCell>
                <TableCell>{item.active ? "ACTIVE" : "IN ACTIVE"}</TableCell>
                <TableCell>{item.regulationMode}</TableCell>
                {eld && (
                    <TableCell>
                        {item.assetType === "VEHICLE" && item.eldDevice && (
                            <div className={classes.capitalize}>
                                {item.eldDevice.name && (
                                    <div>
                                        <span className={classes.tableRowTitle}>
                                            Name:
                                        </span>
                                        {item.eldDevice.name}
                                    </div>
                                )}
                                {item.eldDevice.connectionType && (
                                    <div>
                                        <span className={classes.tableRowTitle}>
                                            Type:{" "}
                                        </span>
                                        {item.eldDevice.connectionType}
                                    </div>
                                )}
                                {item.eldDevice.deviceType && (
                                    <div>
                                        <span className={classes.tableRowTitle}>
                                            Device:
                                        </span>{" "}
                                        {item.eldDevice.deviceType}
                                    </div>
                                )}
                                {item.eldDevice.serial && (
                                    <div>
                                        <span className={classes.tableRowTitle}>
                                            Serial:
                                        </span>{" "}
                                        {item.eldDevice.serial}
                                    </div>
                                )}
                            </div>
                        )}
                    </TableCell>
                )}
                <TableCell>
                    {item.license && (
                        <div>
                            {item.license.state}-{item.license.plate}
                        </div>
                    )}
                </TableCell>
                <TableCell>
                    <div>
                        {item.created && (
                            <div>
                                Created: {this.props.formatDate(item.created)}
                            </div>
                        )}
                        {item.edited && (
                            <div>
                                Edited: {this.props.formatDate(item.edited)}
                            </div>
                        )}
                    </div>
                </TableCell>
                <TableCell>
                    {isEdit && (
                        <EditIcon
                            className={classes.EditIcon}
                            onClick={() => this.props.handleEdit(item)}
                        />
                    )}
                </TableCell>
            </TableRow>
        );
    }
}

export class ReduxTable extends React.Component {
    state = {
        order: "asc"
    };

    handleChangePage = (event, page) => {
        const { limit } = this.props;
        this.props.handleChange({ limit, page });
    };

    handleChangeRowsPerPage = event => {
        const limit = event.target.value;
        const { page } = this.props;
        this.props.handleChange({ limit, page });
    };

    handleSort = field => {
        const { page, limit } = this.props;
        const order = this.state.order === "desc" ? "asc" : "desc";

        this.setState({ order });
        this.props.handleChange({ page, limit, order: `${field} ${order}` });
    };

    render() {
        const { eld, classes, items = [], equipmentsCount } = this.props;
        const { page, limit, adminKey } = this.props;

        return (
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Regulation Mode</TableCell>
                            {eld && <TableCell>ELD</TableCell>}
                            <TableCell>Plate</TableCell>
                            <TableCell>
                                Created/Edited
                                {/*<TableSortLabel
                                    active={true}
                                    direction={order}
                                    onClick={() => this.handleSort("created")}
                                >
                                    {"Created/Edited"}
                                </TableSortLabel>*/}
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(items) &&
                            items.map((item, i) => (
                                <ReduxTableRow
                                    item={item}
                                    key={i}
                                    eld={eld}
                                    adminKey={adminKey}
                                    classes={classes}
                                    handleEdit={this.props.handleEdit}
                                    formatDate={this.props.formatDate}
                                />
                            ))}
                    </TableBody>
                </Table>
                <NotFoundTableRow items={items} />
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={equipmentsCount}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
            </Paper>
        );
    }
}

ReduxTable.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ReduxTable);

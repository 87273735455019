import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { reduxForm } from "redux-form";
// import { Formik, Form } from "formik";
// import InputBox from "../../../components/InputBox";
// import SelectBox from "../../../components/SelectBox";
import { signIn } from "../../../actions/auth/authActions";
import { getTimeZone } from "../../../actions/momentActions";
import { getImagePostUrl, getImageGetUrl } from "../../../actions/imageActions";
import { createCarrier, updateCarrier } from "../../../actions/carrierActions";
import validate from "./validate";
import Grid from "@material-ui/core/Grid";
import ReduxButton from "../../../components/ReduxButton";
import ReduxInput from "../../../components/ReduxInput";
import ReduxSelect from "../../../components/ReduxSelect";
import ImageUpload from "../../../components/ImageUpload";
import Error from "../../Error";
import Success from "../../Success";

const styles = theme => ({
    wrapper: {},
    DialogActions: {
        marginTop: 20
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

export class AddCarrierForm extends React.Component {
    state = {
        folder: "logocarrier"
    };

    componentDidMount() {
        const isEdit = _get(this, "props.isEdit");

        if (!isEdit) {
            const timezone = this.props.getTimeZone();
            this.props.change("timezone", timezone);
        }
    }

    uploadImage = async () => {
        const carrierId = _get(this, "props.initialValues.id", Date.now());
        const { folder } = this.state;
        const { bucket } = this.props;
        const { imageInfo, imageData } = this.state;
        const _fileName = _get(imageInfo, "name", "");
        const fileExt = _fileName.split(".").pop();
        const fileName = `logo_${carrierId}.${fileExt}`;

        this.setState({ fileName });

        console.log('bucket ', this.props.bucket);
        const response = await this.props.getImagePostUrl({
            // getImageGetUrl,
            accountId: this.props.accountId,
            bucket,
            folder,
            name: fileName
        });

        if (response.url) {
            try {
                await fetch(response.url, {
                    method: "PUT",
                    body: imageData
                });
                return true;
            } catch (e) {
                return false;
            }
        } else {
            return false;
        }
    };

    save = async values => {
        this.setState({ errorMessage: "", successMessage: "" });
        const isEdit = _get(this, "props.isEdit");

        const { imageInfo, imageData } = this.state;
        if (imageInfo && imageData) {
            const uploadResponse = await this.uploadImage();
            if (uploadResponse) {
                const { folder, fileName } = this.state;
                const { bucket } = this.props;
                values.media = {
                    bucket,
                    folder,
                    name: fileName
                };
            }
        }

        let response;

        if (isEdit) {
            response = await this.props.updateCarrier(values);
        } else {
            response = await this.props.createCarrier(values);
        }

        if (response && response.id) {
            this.setState({ successMessage: "Carrier is saved successfully" });
            setTimeout(() => {
                this.props.handleClose();
            }, 1000);
        } else if (response && response.message) {
            this.setState({ errorMessage: response.message });
        } else {
            if (typeof response !== String) {
                response = "Something went wrong, please try after time.";
            }
            this.setState({ errorMessage: response });
        }
    };

    onImageChange = (imageInfo, imageData) => {
        this.setState({ imageInfo, imageData });
    };

    getImageUrl = () => {
        const media = _get(this, "props.initialValues.media");
        if (!media || !media.name) {
            return null;
        }
        const { name, folder, bucket } = media;
        return `https://${bucket}.s3.amazonaws.com/${folder}/${name}`;
    };

    render() {
        const {
            isEdit,
            classes,
            loading,
            handleSubmit,
            constants,
            carrierValues
        } = this.props;
        const { errorMessage, successMessage } = this.state;
        const isSmallScreen = window.innerWidth < 400;
        return (
            <Dialog
                fullScreen={isSmallScreen}
                fullWidth={true}
                maxWidth={"md"}
                className={classes.wrapper}
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography>
                        {isEdit ? "EDIT CARRIER" : "ADD CARRIER"}
                    </Typography>
                    <IconButton
                        aria-label="Close"
                        className={classes.closeButton}
                        onClick={this.props.handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <form noValidate autoComplete="off">
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={12} md={12}>
                                <ImageUpload
                                    onChange={this.onImageChange}
                                    imageUrl={this.getImageUrl()}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <ReduxInput name="name" label="Name" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <ReduxInput name="dot" label="DOT" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <ReduxSelect
                                    name="timezone"
                                    label="Timezone"
                                    items={constants.TIME_ZONES}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={2}>
                                <ReduxSelect
                                    name="country"
                                    label="Country"
                                    keyName={"id"}
                                    valueName={"id"}
                                    items={constants.COUNTRIES}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <ReduxInput
                                    name="line1"
                                    label="Address line1"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <ReduxInput name="line2" label="Suit., bldg." />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <ReduxInput name="city" label="City" />
                            </Grid>
                            {carrierValues.country === "CA" ? (
                                <Grid item xs={12} sm={12} md={1}>
                                    <ReduxSelect
                                        name="state"
                                        label="State"
                                        keyName={"id"}
                                        valueName={"id"}
                                        items={constants.CA_STATES}
                                    />
                                </Grid>
                            ) : (
                                    <Grid item xs={12} sm={12} md={1}>
                                        <ReduxSelect
                                            name="state"
                                            label="State"
                                            keyName={"id"}
                                            valueName={"id"}
                                            items={constants.US_STATES}
                                        />
                                    </Grid>
                                )}
                            <Grid item xs={12} sm={12} md={2}>
                                <ReduxInput name="zip" label="Zip" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <ReduxInput
                                    name="contact.name"
                                    label="Contact name"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <ReduxInput
                                    name="contact.email"
                                    label="Email"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <ReduxInput
                                    name="contact.phone"
                                    label="Phone"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <ReduxInput name="contact.role" label="Role" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <ReduxInput
                                    name="dispatch.name"
                                    label="Dispatch name"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <ReduxInput
                                    name="dispatch.email"
                                    label="Email"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <ReduxInput
                                    name="dispatch.phone"
                                    label="Phone"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <ReduxInput name="dispatch.role" label="Role" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <small>
                                    <strong>NOTE: </strong>
                                    Dispatch info is only required, if you want
                                    send dispatch to ELD device as push
                                    notifications.
                                </small>
                            </Grid>
                            <Error top={20} message={errorMessage} />
                            <Success top={20} message={successMessage} />
                        </Grid>
                        <DialogActions className={classes.DialogActions}>
                            <ReduxButton
                                width
                                loading={loading}
                                onClick={handleSubmit(this.save)}
                                marginTop={25}
                                label={"SAVE"}
                            />
                            <Button
                                onClick={this.props.handleClose}
                                color="primary"
                                autoFocus
                            >
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        accountId: _get(state, "authData.accountId"),
        constants: _get(state, "appData.constants", {}),
        errorMessage: _get(state, "equipmentData.error", ""),
        loading: _get(state, "appData.appBusy", false),
        carrierValues: _get(state, "form.equipmentForm.values", {}),
        carriers: _get(state, "carrierData.carriers", []),
        bucket: _get(state, "appData.S3_BUCKET")
    };
};

AddCarrierForm = reduxForm({
    form: "carrierForm",
    validate
})(AddCarrierForm);

AddCarrierForm = connect(state => {
    return {
        initialValues: _get(state, "carrierData.selectedCarrier", {}),

    };
})(AddCarrierForm);

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            signIn,
            createCarrier,
            updateCarrier,
            getImagePostUrl,
            getImageGetUrl,
            getTimeZone
        },
        dispatch
    );

export default withStyles(styles)(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(AddCarrierForm)
    )
);

import React from "react";
import _get from "lodash/get";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import { bindActionCreators } from "redux";
import { formatDate, formatDateTime } from "../../actions/momentActions";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import GetAppIcon from "@material-ui/icons/GetApp";
import NotFoundTableRow from "../NotFoundTableRow";
import { usStates } from "../../utils/constants";

const STYLES = {
  mainDiv: {
    margin: "0 50px 0",
  },
  table: {
    border: "0.1px solid #ccc",
    width: "98%",
    borderCollapse: "collapse",
    margin: "auto",
    fontSize: "13px",
  },
  td: {
    border: "0.2px solid #ccc",
    // width: "35%",
    textAlign: "center",
    padding: "20px 0 20px 0",
  },
  tStyle: {
    padding: "20px 0 20px 0",
  },
  message: {
    width: "90%",
    margin: "auto",
    marginTop: 20,
  },
  reportHead: {
    textAlign: "center",
    fontSize: "20px",
    marginBottom: "40px",
  },
};

function ReduxTableRow({ item = {} }) {
  const distance = item.distance
    ? (parseInt(item.distance) / 1609).toFixed(2)
    : 0;
  const stateObj = usStates.find((it) => item.country === it.name) || {};

  return (
    <tr style={STYLES.td}>
      <td style={STYLES.tStyle}>{stateObj.abbreviation || ""}</td>
      <td className="text-capitalize">{`${distance} Mi`}</td>
    </tr>
  );
}

class stateTable extends React.Component {
  state = {
    limit: 10,
    page: 0,
    loading: false,
    isDetailed: false,
    isEdit: false,
  };

  handleOpen = (data) => {
    this.setState({ isEdit: true, selItem: data });
  };

  renderCsvData = () => {
    const { start, end } = this.props;
    const stateMiles = _get(this, "props.allRoutes", []);

    if (!stateMiles || !Array.isArray(stateMiles) || stateMiles.length === 0) {
      return null;
    }
    const csvData = stateMiles.map((item) => {
      const distance = item.distance
        ? (parseInt(item.distance) / 1609).toFixed(2)
        : 0;
      const stateObj = usStates.find((it) => item.country === it.name) || {};
      const data = {
        REPORT: "STATE MILEAGE",
        START_DATE: `${this.props.formatDate(start)}`,
        END_DATE: `${this.props.formatDate(end)}`,
        STATE: `${stateObj.abbreviation || ""}`,
        MILES: `${distance}`,
      };
      return data;
    });

    return (
      <CSVLink
        data={csvData}
        filename={`StateMileage_Report.csv`}
        style={{
          textDecoration: "none",
          color: !stateMiles ? "#F3F3F3" : "#369BE5",
        }}
      >
        CSV
      </CSVLink>
    );
  };

  render() {
    const { start, end, carrier, download, accountBaseUrl } = this.props;
    const vehicle = _get(this.props, "vehicle[0].name", "");
    const stateMiles = _get(this, "props.allRoutes", []);
    const totalKm = stateMiles.reduce((prev, cur) => prev + cur.distance, 0);
    const totalMi = totalKm ? (parseInt(totalKm) / 1609).toFixed(2) : 0;

    return (
      <>
        <Grid container style={{ marginBottom: "15px" }}>
          <Grid item xs={6} md={6} style={{ marginLeft: "5vh" }} />
          <Grid item xs={6} md={5} style={{ textAlign: "right" }}>
            <Button
              color="primary"
              variant="outlined"
              onClick={this.props.downloadReport}
            >
              <GetAppIcon />
              PDF
            </Button>

            <Button
              variant="outlined"
              color="primary"
              style={{ marginLeft: "4px" }}
            >
              <GetAppIcon />
              {this.renderCsvData()}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              style={{ marginLeft: "4px" }}
            >
              <Link
                style={{ textDecoration: "none", color: "#009be5" }}
                to={`${accountBaseUrl}/state-mileage/detail`}
              >
                DETAILS
              </Link>
            </Button>
          </Grid>
        </Grid>
        <Grid id="print_log" style={STYLES.mainDiv}>
          <Paper elevation={3} style={{ padding: "10px", minHeight: "550px" }}>
            <Grid item xs={12}>
              <p style={STYLES.reportHead}>State Mileage Report</p>

              <Grid container>
                <Grid item xs={12} sm={6}>
                  <p
                    style={{
                      marginLeft: "30px",
                      marginBottom: "0",
                    }}
                  >
                    {`Carrier Name : ${carrier}`}
                  </p>
                  <p style={{ marginLeft: "30px" }}>
                    {`Report : ${
                      this.props.formatDate(start) || this.props.formatDate()
                    }`}
                    {` TO ${
                      this.props.formatDate(end) || this.props.formatDate()
                    }`}{" "}
                  </p>
                </Grid>

                <Grid item xs={12} sm={6} style={{ marginBottom: "20px" }}>
                  <p
                    style={{
                      marginRight: "30px",
                      marginBottom: "0",
                      textAlign: "right",
                    }}
                  >
                    {`Vehicle Name : ${vehicle ? vehicle : "All Vehicles"}`}
                  </p>
                  <p
                    style={{
                      marginRight: "30px",
                      textAlign: "right",
                    }}
                  >
                    {`Miles : ${totalMi} Mile(s)`}
                  </p>
                </Grid>
              </Grid>
            </Grid>

            <table style={STYLES.table}>
              <thead>
                <tr>
                  <th style={STYLES.tStyle} scope="col">
                    State
                  </th>
                  <th scope="col">Mileage(Miles)</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(stateMiles) &&
                  stateMiles.map((item, index) => (
                    <ReduxTableRow
                      key={index}
                      item={item}
                      formatDateTime={this.props.formatDateTime}
                      download={download}
                      handleOpen={this.handleOpen}
                    />
                  ))}
              </tbody>
            </table>
            <NotFoundTableRow items={stateMiles} />
          </Paper>
        </Grid>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    constants: _get(state, "appData.constants", {}),
    accountBaseUrl: _get(state, "authData.accountBaseUrl"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      formatDate,
      formatDateTime,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(stateTable);

import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const styles = theme => ({
    DialogActions: {
        marginTop: 20
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    wrapper: {
        padding: theme.spacing(1),
        minHeight: 200,
        overflow: "scroll"
    },
    driverTitle: {
        fontWeight: 500,
        fontSize: 14,
        marginTop: 10,
        display: "inline-block",
        minWidth: 175
    },
    driverContent: {
        fontSize: 14,
        marginTop: 10,
        display: "inline-block"
    }
});

export class RecapDialog extends React.Component {
    state = {
        successMessage: "",
        errorMessage: ""
    };

    componentDidMount() {
        this.getDriverRecap();
    }

    getDriverRecap = async () => {
        const payload = {
            days: 14,
            driverId: this.props.driverId,
            selectedDate: this.props.selectedDate
        };
        await this.props.getDriverDaily(payload);
    };

    render() {
        const { classes, recapBusy = false, driverDiary = {} } = this.props;

        return (
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                className={classes.wrapper}
                open={this.props.open}
                onClose={this.props.handleRecapClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    onClose={this.props.handleRecapClose}
                >
                    <Typography>
                        {recapBusy ? "LOADING..." : "RECAP"}
                    </Typography>
                    <IconButton
                        aria-label="Close"
                        className={classes.closeButton}
                        onClick={this.props.handleRecapClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>DATE</TableCell>
                                <TableCell>Driving</TableCell>
                                <TableCell>OffDuty</TableCell>
                                <TableCell>OnDutyND</TableCell>
                                <TableCell>Sleeper</TableCell>
                                {/*<TableCell>WaitingAtWellSite</TableCell>*/}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array.isArray(driverDiary) &&
                                driverDiary.map((item, i) => (
                                    <TableRow key={i}>
                                        <TableCell>
                                            {this.props.formatRawDate(
                                                item.logDate
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {item.drivingHours}
                                        </TableCell>
                                        <TableCell>
                                            {item.offDutyHours}
                                        </TableCell>
                                        <TableCell>
                                            {item.onDutyNDHours}
                                        </TableCell>
                                        <TableCell>
                                            {item.sleeperHours}
                                        </TableCell>
                                        {/*<TableCell>
                                            {item.waitingAtWellSiteHours}
                                        </TableCell>*/}
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        recapBusy: _get(state, "logData.recapBusy", false),
        driverDiary: _get(state, "logData.driverDiary", []),
        driverId: _get(state, "driverData.selectedDriver.driver.id", {})
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default withStyles(styles)(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(RecapDialog)
    )
);

import _get from "lodash/get";

export function getInitialValues(props) {
    if (props.isEdit) {
        const user = _get(props, "selected", {});
        const driver = user.driver || {};
        const setting = driver.setting || { drView: "SINGLE" };
        return {
            isEdit: !!user.uuid,
            status: driver.status,
            country: driver.licenseCountry,
            number: driver.licenseNumber && driver.licenseNumber.toLowerCase().replace(/\s/g, ''),
            state: driver.licenseState,
            alias: driver.alias,
            cargo: driver.cargo,
            regulation: driver.regulation || "ELD",
            cycleUsa: driver.cycleUsa,
            cycleCa: driver.cycleCa,

            allowEdit: driver.allowEdit,
            tracking: driver.tracking,
            personal: driver.personal,
            yardMoves: driver.yardMoves,

            cycleCountry: driver.cycleCountry,
            exemptAnnotation: driver.exemptAnnotation,
            exemptDriver: driver.exemptDriver,
            startTime: driver.startTime,
            carrierId: driver.carrierId,
            vehicleId: driver.vehicleId,
            trailerId: driver.trailerId,
            terminalId: driver.terminalId,
            driverId: driver.driverId,
            receiverEmail: driver.receiverEmail,
            equipments: driver.equipments,
            drView: setting.drView,

            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            mobile: user.mobile,
            role: user.role,
            userStatus: user.status,
            id: user.id,
            isFb: driver.isFb || false,
            kiosk: driver.kiosk || 'OFF'
        };
    } else {
        return {
            role: "DRIVER",
            alias: "",
            country: "US",
            status: "ACTIVE",
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            mobile: "",
            terminalId: props.terminalId,
            carrierId: props.carrierId,
            state: "",
            number: "",
            userStatus: "ACTIVE",
            cargo: "PROPERTY",
            regulation: "ELD",
            cycleUsa: "US70hr8days",
            cycleCa: "Can70hr7daysSouth",
            cycleCountry: "US",
            startTime: "12 AM",
            exemptDriver: "OFF",
            personal: "OFF",
            allowEdit: "OFF",
            yardMoves: "OFF",
            tracking: "ON",
            receiverEmail: "",
            isFb: false,
            kiosk: 'OFF',
            setting: { drView: "SINGLE" }
        };
    }
}

export function getDriver(params) {
    let payload = {
        licenseCountry: params.country,
        licenseNumber: params.number,
        licenseState: params.state,
        alias: params.alias,
        status: params.userStatus,
        cargo: params.cargo,
        regulation: params.regulation || "ELD",
        cycleUsa: params.cycleUsa,
        cycleCa: params.cycleCa,
        autoStatus: params.autoStatus,
        fullAddress: params.fullAddress,
        cycleCountry: params.cycleCountry,
        exemptAnnotation: params.exemptAnnotation,
        exemptDriver: params.exemptDriver,
        personal: params.personal,
        yardMoves: params.yardMoves,
        allowEdit: params.allowEdit,
        tracking: params.tracking,
        startTime: params.startTime,
        carrierId: params.carrierId,
        vehicleId: params.vehicleId,
        trailerId: params.trailerId,
        terminalId: params.terminalId,
        receiverEmail: params.receiverEmail,
        equipments: params.equipments,
        isFb: params.isFb,
        kiosk: params.kiosk || 'OFF',
        setting: {
            drView: params.drView || "SINGLE"
        }
    };

    return payload;
}

export function getPayload(params) {
    let payload = {
        terminalId: params.terminalId,
        carrierId: params.carrierId,
        email: params.email,
        password: params.password,
        firstName: params.firstName,
        lastName: params.lastName,
        status: params.status,
        mobile: params.mobile,
        role: params.role,
        isFb: params.isFb
    };

    return payload;
}

export function getScopes(props) {
    const selected = props.selected;
    const _scopes = props.scopes || [];
    const isDriver = selected.role === "DRIVER";
    const scopes = _scopes.filter(item => {
        return isDriver ? item.isDriver : item.isManager;
    });

    let all = {};

    for (let i = 0; i < scopes.length; i++) {
        const item = scopes[i];
        const name = item.name;
        const keyName = name.split("_")[1];
        all[keyName] = all[keyName] || {};
        all[keyName].items = all[keyName].items || [];
        all[keyName].items.push(item);
    }

    return Object.keys(all).map(item => {
        return {
            name: item,
            data: all[item].items
        };
    });
}

import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Formik, Form } from "formik";
import { signIn } from "../../../actions/auth/authActions";
import { getTimeZone } from "../../../actions/momentActions";
import { createCarrier, updateCarrier } from "../../../actions/carrierActions";
import { validateEdit } from "./validate";
import Grid from "@material-ui/core/Grid";
import InputBox from "../../../components/InputBox";
import SelectBox from "../../../components/SelectBox";
import Error from "../../Error";
import Success from "../../Success";

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 25,
    textAlign: "right",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export class EditAccountForm extends React.Component {
  state = {};

  componentDidMount() {}

  handleSubmit = async (values) => {
    this.setState({ errorMessage: "", successMessage: "", loading: true });

    let response = await this.props.updateAccount(values);

    if (response && response._id) {
      this.setState({
        successMessage: "Account is updated successfully",
        loading: false,
      });
      setTimeout(() => {
        this.props.handleClose();
      }, 1000);
    } else if (response && response.message) {
      this.setState({ errorMessage: response.message, loading: false });
    } else {
      if (typeof response !== String) {
        response = "Something went wrong, please try after time.";
      }
      this.setState({ errorMessage: response, loading: false });
    }
  };

  render() {
    const { classes, constants, selected } = this.props;
    const { isSuperAdmin, resellers } = this.props;
    const { errorMessage, successMessage, loading } = this.state;
    return (
      <Formik
        initialValues={selected}
        validate={validateEdit}
        onSubmit={this.handleSubmit}
        enableReinitialize={true}
      >
        {({ values, handleSubmit, setFieldValue, errors }) => {
          return (
            <Form style={{ padding: "20px 10px" }}>
              <Grid container spacing={3}>
                <SelectBox
                  md={2}
                  name="status"
                  label="Status"
                  items={constants.ALL_ACCOUNT_STATUS_OPTIONS}
                />
                <InputBox md={4} name="name" label="Account Name" />
                <InputBox md={3} name="phone" label="Phone" />
                <InputBox md={3} name="email" label="Email" />

                <InputBox md={3} name="contact.name" label="Contact name" />
                <InputBox md={3} name="contact.email" label="Contact email" />
                <InputBox md={3} name="contact.phone" label="Contact phone" />
                <InputBox md={3} name="contact.role" label="Contact role" />
                <InputBox md={3} name="stripeBuss" label="Stripe" />

                <InputBox md={9} name="notes" label="Note" />
                {isSuperAdmin && (
                  <SelectBox
                    md={12}
                    name="resellerId"
                    label="Reseller"
                    items={resellers}
                    selValue={"_id"}
                  />
                )}
              </Grid>
              {successMessage && <Success message={successMessage} />}
              {errorMessage && <Error message={errorMessage} />}
              <div className={classes.DialogActions}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  {loading ? "Saving..." : "SAVE"}
                </Button>{" "}
                <Button variant="contained" onClick={this.props.handleClose}>
                  CLOSE
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    constants: _get(state, "appData.constants", {}),
    errorMessage: _get(state, "equipmentData.error", ""),
    loading: _get(state, "accountData.accountCreateBusy", false),
    accountValues: _get(state, "form.accountForm.values", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signIn,
      createCarrier,
      updateCarrier,
      getTimeZone,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(EditAccountForm))
);

import axios from "./axios";
import _get from "lodash/get";
import * as TYPES from "./trailerActionTypes";

export const setTrailersCount = value => ({
    type: TYPES.TRAILERS_COUNT,
    value
});


export const setTrailer = value => ({
    type: TYPES.EQUIPMENTS,
    value
});

export const setTrailers = value => ({
    type: TYPES.TRAILERS,
    value
});

export const appTrailerBusy = value => ({
    type: TYPES.APP_TRAILERS_BUSY,
    value
});

export const setPagination = value => ({
    type: TYPES.TRAILERS_PAGINATION,
    value
});

export function getTrailersCount(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        dispatch(appTrailerBusy(true));

        return axios({
            url: `${BASE_URL}/manager/${accountId}/trailers/count`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data.count", 0);
                dispatch(setTrailersCount(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(appTrailerBusy(false));
                return response;
            });
    };
}


export function getTrailers(params = {}, id) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = id || _get(getState(), "authData.profile.user.accountId");
        dispatch(appTrailerBusy(true));
        dispatch(setTrailer([]));

        return axios({
            url: `${BASE_URL}/manager/${accountId}/trailers`,
            method: "get",
            params: {
                page: 0,
                limit: params.limit,
                query: params.query,
                active: params.active
            }
        })
            .then(response => {
                const _response = _get(response, "data", {});
                dispatch(setTrailers(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(appTrailerBusy(false));
                return response;
            });
    };
}

export function createTrailer(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        params.accountId = accountId;

        dispatch(appTrailerBusy(true));

        return axios({
            url: `${BASE_URL}/manager/${accountId}/trailers`,
            method: "post",
            data: params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(appTrailerBusy(false));
                return response;
            });
    };
}

export function updateTrailer(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        params.accountId = accountId;

        dispatch(appTrailerBusy(true));

        return axios({
            url: `${BASE_URL}/manager/${accountId}/trailers/${params.id}`,
            method: "put",
            data: params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(appTrailerBusy(false));
                return response;
            });
    };
}

import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Formik, Form } from "formik";
import {
  createReseller,
  updateReseller,
} from "../../actions/reseller/resellerActions";
import { validate, defaultValues } from "./validate";
import Grid from "@material-ui/core/Grid";
import InputBox from "../../components/InputBox";
import SelectBox from "../../components/SelectBox";
import Error from "../Error";
import Success from "../Success";

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export class AddResellerForm extends React.Component {
  state = {};

  componentDidMount() {}

  handleSubmit = async (values) => {
    this.setState({ errorMessage: "", successMessage: "", loading: true });

    let response;

    if (values._id) {
      response = await this.props.updateReseller(values);
    } else {
      response = await this.props.createReseller(values);
    }

    if (response && response._id) {
      this.setState({
        successMessage: "Reseller is created successfully",
        loading: false,
      });
      // this.props.getAccounts();
      setTimeout(() => {
        this.props.handleClose();
      }, 1000);
    } else if (response && response.message) {
      this.setState({ errorMessage: response.message, loading: false });
    } else {
      if (typeof response !== String) {
        response = "Something went wrong, please try after some time.";
      }
      this.setState({ errorMessage: response, loading: false });
    }
  };

  render() {
    const { classes, constants } = this.props;
    const selected = this.props.selected || {};
    const isSmallScreen = window.innerWidth < 400;
    const { errorMessage, successMessage, loading } = this.state;
    const initialValues = { ...defaultValues, ...selected };

    return (
      <Dialog
        fullScreen={isSmallScreen}
        fullWidth={true}
        maxWidth={"lg"}
        className={classes.wrapper}
        open={this.props.isAdd}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography>{"ADD ACCOUNT"}</Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={this.handleSubmit}
            enableReinitialize={true}
          >
            {({ values, handleSubmit, setFieldValue }) => {

              return (
                <Form style={{ padding: "20px 10px" }}>
                  <Grid container spacing={3}>
                    <InputBox md={3} name="name" label="Name" />
                    <SelectBox
                      md={3}
                      name="status"
                      label="Status"
                      items={constants.ALL_STATUS_OPTIONS}
                    />
                    <InputBox md={3} name="ein" label="Ein" />

                    <SelectBox
                      md={3}
                      name="bussType"
                      label="Type"
                      items={constants.BUSS_TYPE}
                    />

                    <InputBox md={3} name="contact.name" label="Name" />
                    <InputBox md={3} name="contact.email" label="Email" />
                    <InputBox md={3} name="contact.phone" label="Phone" />
                    <InputBox md={3} name="contact.role" label="Role" />

                    <InputBox md={3} name="billing.name" label="Billing Name" />
                    <InputBox
                      md={3}
                      name="billing.email"
                      label="Billing Email"
                    />
                    <InputBox
                      md={3}
                      name="billing.phone"
                      label="Billing Phone"
                    />
                    <InputBox md={3} name="billing.role" label="Billing Role" />

                    <InputBox md={4} name="stripeBuss" label="Stripe" />
                    <InputBox md={8} name="notes" label="Note" />
                  </Grid>
                  {successMessage && <Success message={successMessage} />}
                  {errorMessage && <Error message={errorMessage} />}
                  <DialogActions className={classes.DialogActions}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={loading}
                    >
                      {loading ? "Saving..." : "SAVE"}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={this.props.handleClose}
                    >
                      CLOSE
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    constants: _get(state, "appData.constants", {}),
    errorMessage: _get(state, "equipmentData.error", ""),
    loading: _get(state, "accountData.accountCreateBusy", false),
    accountValues: _get(state, "form.accountForm.values", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createReseller,
      updateReseller,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AddResellerForm))
);

import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { getCarriers, selectedCarrier } from "../../../actions/carrierActions";
import { formatDate } from "../../../actions/momentActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import CarrierTable from "./CarrierTable";
import AddCarrierForm from "./AddCarrierForm";
import ReduxButton from "../../../components/ReduxButton";

const styles = theme => ({
    addWrapper: {
        padding: 10
    },
    searchText: {
        display: "inline-block",
        marginTop: 10,
        marginLeft: 5
    },
    grow: {
        flexGrow: 1,
        textAlign: "center"
    }
});

export class Carrier extends React.Component {
    state = {
        open: false,
        carrier: null,
        searching: false

    };

    async componentDidMount() {
        await this.getCarriers();
    }

    handleOpen = () => {
        this.props.selectedCarrier({
            // name: "ABC Carrier Inc.",
            // dot: "6123123",
            // line1: "1077 S Dana Ct",
            // city: "Mountain House",
            // zip: "95051",
            state: "CA",
            country: "US"
        });
        this.setState({ open: true, isEdit: false });
    };

    getCarriers = async () => {
        this.setState({ searching: true })
        await this.props.getCarriers();
        this.setState({ searching: false })
    }

    handleClose = () => {
        this.setState({ open: false });
        this.props.getCarriers();
    };

    handleEdit = carrier => {
        this.props.selectedCarrier(carrier);
        this.setState({ open: true, isEdit: true });
    };

    render() {
        const { classes, carriers, carrierBusy } = this.props;
        const { open, isEdit } = this.state;

        return (
            <div>
                {open && (
                    <AddCarrierForm
                        open={open}
                        isEdit={isEdit}
                        handleClose={this.handleClose}
                    />
                )}
                <Grid container spacing={0} className={classes.addWrapper}>
                    <Grid item xs={6} />
                    <Grid item xs={6} style={{ textAlign: "right" }}>
                        <ReduxButton
                            width
                            onClick={this.handleOpen}
                            label={"ADD"}
                        />
                    </Grid>
                </Grid>
                {carrierBusy ? <div className={classes.grow}>
                    <CircularProgress />
                </div> :
                    <CarrierTable
                        handleEdit={this.handleEdit}
                        items={carriers}
                        formatDate={this.props.formatDate}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        carrierBusy: _get(state, "carrierData.carrierBusy", false),
        carriers: _get(state, "carrierData.carriers", [])
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getCarriers,
            selectedCarrier,
            formatDate
        },
        dispatch
    );

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Carrier)
);

import React from "react";
// import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { formatDate } from "../../actions/momentActions";
import ResellerTable from "./ResellerTable";
import Button from "@material-ui/core/Button";
import SearchBar from "./SearchBar";
import { getResellers } from "../../actions/reseller/resellerActions";
import AddResellerForm from "./AddResellerForm";

const styles = (theme) => ({
  addWrapper: {
    padding: 10,
  },
  searchText: {
    display: "inline-block",
    marginTop: 10,
    marginLeft: 5,
  },
});

export class Reseller extends React.Component {
  state = {
    isAdd: false,
    carrier: null,
    limit: 25,
    page: 0,
    accountCount: 0,
    resellers: [],
    selected: {},
  };

  async componentDidMount() {
    await this.getResellers();
  }

  getResellers = async () => {
    const { limit, page } = this.state;
    const skip = parseInt(page) * parseInt(limit);
    const resellers = await this.props.getResellers({
      limit,
      skip,
    });
    this.setState({ resellers, loading: false });
  };

  handleOpen = () => {
    this.setState({ isAdd: true });
  };

  handleClose = () => {
    this.setState({ isAdd: false, selected: null });
  };

  handleEdit = (selected) => {
    this.setState({ isAdd: true, selected });
  };

  handleChange = ({ limit, page }) => {
    this.setState({ limit, page }, this.getResellers);
  };

  timeOut = null;

  onSearchChange = (event) => {
    const query = event.target.value;
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }
    this.timeOut = setTimeout(() => {
      this.getResellers(query);
      this.timeOut = null;
    }, 1000);
  };

  // handleAccountDetails = (account) => {
  //   this.props.history.push(`/dashboard/accounts/${account._id}`);
  // };

  render() {
    const classes = this.props.classes;
    const { loading, isAdd, selected } = this.state;
    const resellers = this.state.resellers || [];

    return (
      <div>
        {isAdd && (
          <AddResellerForm
            isAdd={isAdd}
            handleClose={this.handleClose}
            selected={selected}
          />
        )}
        <Grid container className={classes.addWrapper}>
          <Grid item xs={12} sm={12} md={2}>
            <span className={classes.searchText}>
              Reseller : {resellers && resellers.length}
            </span>
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <SearchBar onChange={this.onSearchChange} />
          </Grid>
          <Grid item xs={12} sm={12} md={2} style={{ textAlign: "right" }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={this.handleOpen}
            >
              {"ADD"}
            </Button>
          </Grid>
        </Grid>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <ResellerTable
              items={resellers}
              count={this.state.accountCount}
              handleEdit={this.handleEdit}
              formatDate={this.props.formatDate}
              handleChange={this.handleChange}
              limit={this.state.limit}
              page={this.state.page}
            />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      formatDate,
      getResellers,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Reseller)
);

import _get from "lodash/get";
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import FormControl from "@material-ui/core/FormControl";
import EditIcon from "@material-ui/icons/Edit";
import {
    EVENT_CODES_OPTIONS,
} from "../../../constantsStatus";
import { EDIT_REASONS, ALL_STATES } from "../../../constants";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Alert from '@material-ui/lab/Alert';
import Grid from "@material-ui/core/Grid";
import MomentUtils from "@date-io/moment";
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment-timezone";

// function pF(value) {
//     return parseFloat(value) ? parseFloat(value) : 0;
// }

const styles = theme => ({
    root: {
        width: "100%",
        marginRight: "auto",
        marginLeft: "auto",
        overflowX: "auto"
    },
    table: {
        minWidth: 700
    },
    tableHeaderCell: {
        textAlign: "left",
        padding: 5,
        minWidth: 25
    },
    tableRowCell: {
        fontSize: "8px !important",
        padding: 5,
        font: "message-box !important"
    },
    textLeft: {
        textAlign: "left"
    },
    textCenter: {
        textAlign: "center"
    },
    textInactive: {
        textDecoration: "line-through"
    },
    upperCase: {
        textTransform: "uppercase"
    },
    font: {
        fontSize: "14px"
    },
    select: {
        minWidth: "70px"
    },
    error: {
        marginTop: "2px",
        color: "red"
    },
    odoError: {
        color: "red",
        fontSize: "12px"
    },
    dateHeaderCell: {
        textAlign: "left",
        padding: 12,
        minWidth: 50
    },
});

class ReduxTableRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            params: {},
            // driverLogs: props.driverLogs

        }
    }

    handleMenuClick = async (e, name, value) => {
        this.props.setSelectedLog(value);

        if (name === "Edit") {
            this.props.handleLogEdit(value, name);
        } else if (name === "Reassign") {
            this.props.handleReassignDrivingModal(e, true);
        } else if (name === "Edit Admin") {
            this.props.handleLogEdit(value, name, "editAdmin");
        } else if (name === "Delete Admin") {
            this.props.deleteAdminLogs(value);
        }
    };

    render() {
        const {
            classes,
            // cycleStart,
            item = {},
            isAllLog = false,
            isCoDriver = false,
            // isDebug = false,
        } = this.props;
        const eventTypeItems = isAllLog
            ? [
                "CHANGE_DUTY_STATUS",
                "INTERMEDIATE_LOG",
                "YARD_MOVES",
                "PERSONAL_USE",
                "CERTIFICATION",
                "LOGIN_LOGOUT",
                "POWERUP_SHUTDOWN"
            ]
            : [
                "CHANGE_DUTY_STATUS",
                "YARD_MOVES",
                "PERSONAL_USE"
            ];

        const recordStatusItems = isAllLog
            ? [
                "ACTIVE",
                "INACTIVE_CHANGED",
                "INACTIVE_DEACTIVATE",
                "INACTIVE_CHANGE_REQUEST",
                "INACTIVE_CHANGE_REJECTED"
            ]
            : ["ACTIVE"];

        const isCycleReset = item.cycleResetTimestamp;
        const shiftReset = item.shiftReset;
        const tableClass = isCycleReset
            ? ""
            : shiftReset
                ? ""
                : item.recordStatus === "INACTIVE_CHANGE_REQUEST"
                    ? ""
                    : item.recordStatus === "ACTIVE"
                        ? ""
                        : `${classes.textInactive}`;

        const isAllowed =
            recordStatusItems.includes(item.recordStatus) &&
            eventTypeItems.includes(item.eventType);

        if (isCycleReset || shiftReset) {
        } else if (!isAllowed || item.isLast) {
            return null;
        }
        const adminRoles = _get(this, "props.adminScope", []);
        const isAdmin = (adminRoles && adminRoles.includes("ADMIN_DS_ALL"))

        let menuItems = [];

        const noChangeItems = [
            "LOGIN_LOGOUT",
            "POWERUP_SHUTDOWN",
            "CERTIFICATION",
            "INTERMEDIATE_LOG",
            "MALFUNCTION_DIAGNOSTIC"
        ];
        const noChangeStatusItems = [
            "INACTIVE_CHANGED",
            "INACTIVE_DEACTIVATE",
            "INACTIVE_CHANGE_REJECTED"
        ];
        const reAssignEventCode = ["DRIVING", "ONDUTY_ND"];

        if (
            noChangeItems.includes(item.eventType) ||
            noChangeStatusItems.includes(item.recordStatus) ||
            isCycleReset ||
            shiftReset
        ) {
        } else if (
            item.eventType === "CHANGE_DUTY_STATUS" &&
            item.recordOrigin === "AUTOMATICALLY" &&
            reAssignEventCode.includes(item.eventCode)
        ) {
            if (isCoDriver) {
                menuItems = ["Reassign"];
            } else {
                menuItems = [];
            }
            if (!isAdmin) {
                return null
            }
        } else if (
            item.eventType === "CHANGE_DUTY_STATUS" &&
            reAssignEventCode.includes(item.eventCode)
        ) {
            if (isCoDriver) {
                menuItems = ["Reassign", "Edit"];
            } else {
                menuItems = ["Edit"];
            }
        } else {
            menuItems = ["Edit"];
        }

        // if (
        //     isDebug &&
        //     item.eventType === "CHANGE_DUTY_STATUS" &&
        //     (item.eventCode === "DRIVING" || item.eventCode === "ONDUTY_ND")
        // ) {
        //     if (isCoDriver) {
        //         if (isAdmin) {
        //             menuItems = ["Reassign", "Edit"];
        //         } else {
        //             menuItems = ["Reassign"];
        //             return null
        //         }
        //     } else {
        //         if (isAdmin) {
        //             menuItems = ["Edit"];
        //         } else {
        //             menuItems = [];
        //             return null
        //         }
        //     }
        // }

        if (item && item.id) {
            if (isAdmin) {
                // isBlocked = false;
                // isMenu = true;
                menuItems.push("Edit Admin");
                menuItems.push("Delete Admin");
            }
        }

        let firstStatus = false;
        if (item.eventTime === this.props.startOfDay(item.eventTime).toISOString() && this.props.index === 0) {
            firstStatus = true;
        }
        const time = item.eventTime ? this.props.formatDateTimeUtc(item.eventTime) : "";
        const prevLog = this.props.prevLog || {};
        let prevAutomatic = prevLog.eventCode === "DRIVING" && prevLog.recordOrigin ==="AUTOMATICALLY";

        if(item.eventCode === "DRIVING" && item.recordOrigin ==="AUTOMATICALLY"){
          prevAutomatic = true
        }

        return (

            <TableRow hover className={`${classes.table} ${tableClass}`}>

                <TableCell className={`${classes.tableRowCell}`}>
                    {item.isEdited &&
                        <EditIcon style={{ color: "lightgrey" }} />}
                </TableCell>
                <TableCell className={`${classes.tableRowCell} multi-edit-date`}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>

                        <>
                            <KeyboardDateTimePicker
                                className={classes.font}
                                value={time}
                                disabled={firstStatus || prevAutomatic}
                                inputProps={{ readOnly: true }}
                                format="MM/DD/yyyy hh:mm A"
                                onChange={(date) => {
                                    if (typeof date === "object") {
                                        const ldt = this.props.getDate(Date())
                                        let testDateUtc = moment.utc(date);
                                        const sdt = moment(testDateUtc).local();
                                        let localOff = ldt.utcOffset() * 60 * 1000
                                        if (localOff < 0) localOff = -localOff
                                        let systemOff = sdt.utcOffset() * 60 * 1000
                                        if (systemOff < 0) systemOff = -systemOff

                                        let millies = moment(date).valueOf()
                                        const convertedMillies = millies - systemOff + localOff;
                                        item.eventTime = new Date(convertedMillies).toISOString()
                                    } else {
                                        item.eventTime = this.props.formatDateTimeToUtc(item.eventTime)
                                    }
                                    this.props.multi(item)
                                }}
                            />

                        </>
                    </MuiPickersUtilsProvider>

                </TableCell>
                <TableCell className={`${classes.tableRowCell}`}>
                    {isCycleReset || shiftReset ? (
                        <div className={`${classes.cycleReset}`}>USED</div>
                    ) : (
                        <FormControl className={classes.formControl} style={{ minWidth: 100 }}>
                            <Select
                                className={classes.font}
                                value={item.eventCode || "-"}
                                onChange={(e) => {
                                    item.eventCode = e.target.value
                                    this.props.multi(item)
                                }}
                                inputProps={{
                                    name: "eventcode",
                                    id: "eventCode"
                                }}
                            >
                                <MenuItem value="-">Status</MenuItem>
                                {EVENT_CODES_OPTIONS.map((item, i) => (
                                    <MenuItem key={i} value={item.id} style={{ textTransform: "capitalize" }}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {
                                ((!item.eventCode || item.eventCode === "-") && item.isEdited) ?
                                    <p className={classes.error}>Required</p> : ""}
                        </FormControl>
                    )}
                </TableCell>
                <TableCell className={`${classes.tableRowCell}`}>{item.recordOrigin}</TableCell>
                <TableCell className={`${classes.tableRowCell}`}>

                    <TextField
                        fullWidth
                        className={classes.font}
                        style={{ borderBottom: "1px solid #7F7F7F" }}

                        placeholder="location"
                        onChange={(e) => {
                            item.location = e.target.value
                            this.setState({ location: e.target.value })
                        }}
                        onBlur={() => {
                            this.props.multi(item)
                        }}
                        value={this.state.location || item.location}
                        InputProps={{
                            disableUnderline: true,
                            className: classes.searchInput,
                        }}
                    />
                    {
                        (!item.location && item.isEdited) ?
                            <p className={classes.error}>Required</p> : ""}
                </TableCell>
                <TableCell className={`${classes.tableRowCell} ${classes.font}`}>

                    <div>
                        <TextField
                            fullWidth
                            // size="small"
                            className={classes.font}
                            style={{ borderBottom: "1px solid #7F7F7F" }}
                            placeholder="Odometer"
                            type="number"
                            onChange={(e) => {
                                item.odometer = e.target.value
                                this.setState({ odometer: e.target.value })
                            }}
                            onBlur={() => {
                                this.props.multi(item)
                            }}
                            value={this.state.odometer || item.odometer}
                            InputProps={{
                                disableUnderline: true,
                                className: classes.searchInput,
                            }}
                        />
                        {
                            (!item.odometer && item.isEdited) ?
                                <span className={classes.odoError}>Required</span> : (parseInt(item.odometer) > 9999999) ?
                                    <span className={classes.odoError}>Invalid</span> : ""}

                    </div>
                </TableCell>

                <TableCell className={`${classes.tableRowCell}`}>
                    <FormControl className={classes.formControl}>
                        <Select
                            className={`${classes.select} ${classes.font}`}
                            value={item.state || "-"}
                            onChange={(e) => {
                                item.state = e.target.value
                                this.props.multi(item)
                            }}
                            inputProps={{
                                name: "state",
                                id: "state"
                            }}
                        >
                            <MenuItem value={"-"}>State</MenuItem>
                            {ALL_STATES.map((item, i) => (
                                <MenuItem key={i} value={item.id} style={{ textTransform: "capitalize" }}>
                                    {item.id} ({item.name})
                                </MenuItem>
                            ))}
                        </Select>
                        {
                            ((!item.state || item.state === "-") && item.isEdited) ?
                                <p className={classes.error}>Required</p> : ""}
                    </FormControl>
                </TableCell>

                <TableCell className={`${classes.tableRowCell}`}>
                    <FormControl className={classes.formControl} style={{ minWidth: 100 }}>

                        <Select
                            className={`${classes.select} ${classes.font}`}
                            value={item.reason || "-"}
                            onChange={(e) => {
                                item.reason = e.target.value
                                this.props.multi(item)
                            }}
                            inputProps={{
                                name: "reason",
                                id: "reason"
                            }}
                        >
                            <MenuItem value={"-"}>Reason</MenuItem>
                            {EDIT_REASONS.map((item, i) => (
                                <MenuItem key={i} value={item.id} style={{ textTransform: "capitalize" }}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                        {
                            ((!item.reason || item.reason === "-") && item.isEdited) ?
                                <p className={classes.error}>Required</p> : ""}
                    </FormControl>
                </TableCell>
                <TableCell className={`${classes.tableRowCell}`}>
                    <div>
                        <TextField
                            className={classes.font}
                            style={{ borderBottom: "1px solid #7F7F7F" }}
                            fullWidth
                            placeholder="Notes"
                            InputProps={{
                                disableUnderline: true,
                                className: classes.searchInput,
                            }}
                            onChange={(e) => {
                                item.annotation = e.target.value
                                this.setState({ annotation: e.target.value })
                            }}
                            onBlur={() => {
                                this.props.multi(item)
                            }}
                            value={this.state.annotation || item.annotation}
                        />
                        {/* {
                            (!item.annotation && item.isEdited) ?
                                <p className={classes.error}>Required</p> : ""} */}
                    </div>
                </TableCell>
            </TableRow >

        );
    }
}

export class MultiStatusDetailTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            params: {},
            allLogs: props.allLogs

        }
    }
    componentDidMount() { }

    handleReassignDrivingModal = (e, rDOpen) => {
        this.setState({ rDOpen });
    };

    onDelete = async () => {
        this.setState({ deleting: true });
        const selected = this.state.selected;

        await this.props.deleteAdminLogs(selected);
        await this.props.searchLogStatus();

        this.setState({
            isConfirmOpen: false,
            deleting: false,
            selected: null
        });
    };

    isCoDriver = () => {
        const dailyDiary = this.props.dailyDiary || [];

        let isExist = false;

        for (let i = 0; i < dailyDiary.length; i++) {
            const coD = dailyDiary[i].coDriver || [];

            if (coD && Array.isArray(coD) && coD.length > 0) {
                isExist = true;
                break;
            }
        }
        return isExist;
    };

    render() {
        const {
            classes,
            // dailyDiary,
            cycleStart,
            isDebug
        } = this.props;

        // const { allLogs } = this.state;
        const adminRoles = _get(this, "props.adminScope", []);
        let allLogs = [];
        if (adminRoles && (adminRoles.includes("ADMIN_DS_ALL") || adminRoles.includes("ADMIN_LOGS"))) {
            allLogs = this.state.allLogs;
        } else {
            allLogs = this.state.allLogs.filter((item) => {
                return (
                    item.recordOrigin !== "AUTOMATICALLY"
                );
            });
        }
        const isCoDriver = this.isCoDriver();
        return (
            <div>
                {(Array.isArray(allLogs) && allLogs.length > 0) ?
                    <Table className={classes.table}
                        size="small"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHeaderCell}>
                                </TableCell>
                                <TableCell className={classes.dateHeaderCell}>
                                    Time
                                </TableCell>
                                <TableCell className={classes.tableHeaderCell}>
                                    Status
                                </TableCell>
                                <TableCell className={classes.tableHeaderCell}>
                                    Origin
                                </TableCell><TableCell className={classes.tableHeaderCell}>
                                    Location
                                </TableCell>
                                <TableCell className={classes.tableHeaderCell}>
                                    Odometer
                                </TableCell>

                                <TableCell className={classes.tableHeaderCell}>
                                    State
                                </TableCell>

                                <TableCell
                                    className={classes.tableHeaderCell}
                                >
                                    Reason
                                </TableCell>

                                {this.props.adminKey && (
                                    <TableCell
                                        className={classes.tableHeaderCell}
                                    >
                                        Notes
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allLogs.map((item, i) => {
                                return ((i === 0 && item.eventTime !== this.props.startOfDay(item.eventTime).toISOString()) ? "" :
                                    <ReduxTableRow
                                        getDate={this.props.getDate}
                                        item={item}
                                        prevLog={allLogs[i-1]}
                                        prevLog2={allLogs[i-2]}
                                        key={1000000 + i}
                                        index={i}
                                        classes={classes}
                                        cycleStart={cycleStart}
                                        isCoDriver={isCoDriver}
                                        isDebug={isDebug}
                                        isAllLog={this.props.isAllLog}
                                        formatTime={this.props.formatTime}
                                        searchLogStatus={
                                            this.props.searchLogStatus
                                        }
                                        setSelectedLog={
                                            this.props.setSelectedLog
                                        }
                                        handleReassignDrivingModal={
                                            this.handleReassignDrivingModal
                                        }
                                        adminKey={this.props.adminKey}
                                        formatRawDate={this.props.formatRawDate}
                                        deleteAdminLogs={selected => {
                                            this.setState({
                                                selected,
                                                isConfirmOpen: true
                                            });
                                        }}
                                        handleLogEdit={this.props.handleLogEdit}
                                        adminScope={this.props.adminScope}
                                        selectedDate={this.props.selectedDate}
                                        formatDateTimeUtc={this.props.formatDateTimeUtc}
                                        multi={this.props.multi}
                                        formatDateTimeToUtc={this.props.formatDateTimeToUtc}
                                        startOfDay={this.props.startOfDay}
                                    />
                                )
                            }
                            )
                            }
                        </TableBody>
                    </Table>
                    :
                    <Grid item xs={12}> <Alert severity={"error"} >
                        {"No logs to edit!"}
                    </Alert></Grid>
                }
            </div>
        );
    }
}

MultiStatusDetailTable.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MultiStatusDetailTable);

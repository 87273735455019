import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { getDueInvoices } from "../../actions/checkout/coActions";
import { formatDate } from "../../actions/momentActions";
import CheckoutModal from "./CheckoutModal";

export class Checkout extends React.Component {
    state = {
        invoices: [],
        loading: false
    };

    async componentDidMount() {
        await this.getDueInvoices();
    }

    getDueInvoices = async () => {
        try {
            const _invoices = await this.props.getDueInvoices({});

            if (_invoices && Array.isArray(_invoices) && _invoices.length > 0) {
                const invoices =
                    _invoices.filter(item => item.status === "open") || [];
                if (invoices && invoices[0] && invoices[0].amount_due) {
                    this.setState({ invoices, open: true });
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const open = this.state.open || false;
        const invoices = this.state.invoices || [];

        return (
            <React.Fragment>
                {open && invoices && (
                    <CheckoutModal
                        open={open}
                        loading={this.state.loading}
                        invoices={this.state.invoices}
                        getDueInvoices={this.getDueInvoices}
                        handleClose={this.handleClose}
                        formatDate={this.props.formatDate}
                    />
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        dvirs: state.dvirData.dvirAll,
        count: state.dvirData.count
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getDueInvoices,
            formatDate
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Checkout)
);

import React, { useState } from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TablePagination from "@material-ui/core/TablePagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { getFmcsaHistoryCount, getFmcsaHistory } from "../../actions/fmcsaActions";
import { formatDateTime, formatDate } from "../../actions/momentActions";
import NotFoundTableRow from "../NotFoundTableRow";
import SearchBar from "../../components/SearchBarAdmin";

const styles = theme => ({
    root: {
        width: "98%",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: theme.spacing(1),
        overflowX: "auto"
    },
    table: {
        minWidth: 700
    },
    capitalize: {
        textTransform: "capitalize"
    },
    label: {
        display: "inline-block",
        minWidth: 65
    },
    icon: {
        color: "#009be5",
        fontSize: 16,
        marginBottom: -2,
        width: 25
    },
    title: {
        fontWeight: 600,
        margin: "10px auto 5px"
    },
    EditIcon: {
        color: "#009be5",
        cursor: "pointer"
    },
    dataXLargeRow: {
        minWidth: 200
    },
    dataLargeRow: {
        minWidth: 175
    },
    dataMedRow: {
        minWidth: 125
    },
    dataSmallRow: {
        minWidth: 100
    }
});

function RenderLogs({ submissionId, logs, error }) {
    // Declare a new state variable, which we'll call "count"
    const [isVisible, setVisibility] = useState(false);
    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={"lg"}
                onClose={() => setVisibility(!isVisible)}
                aria-labelledby="simple-dialog-title"
                open={isVisible}
            >
                <DialogTitle id="log-details">Detailed Report</DialogTitle>

                <DialogContent>
                    {submissionId && <h3>Submission Id: {submissionId}</h3>}
                    {error && <h3>Errors: {error}</h3>}
                    {logs &&
                        logs.map((_item, j) => (
                            <DialogContentText key={j}>
                                {_item}
                            </DialogContentText>
                        ))}
                </DialogContent>
            </Dialog>
            <button onClick={() => setVisibility(!isVisible)}>View Logs</button>
        </div>
    );
}

class ReduxTableRow extends React.Component {
    state = {};

    render() {
        const { classes, item = {} } = this.props;
        return (
            <TableRow hover>
                <TableCell component="th" scope="row">
                    {item.driver && (
                        <div className={classes.capitalize}>
                            {item.driver.firstName} {item.driver.lastName}
                        </div>
                    )}
                    <div>{item.eldFilename}</div>
                </TableCell>
                <TableCell>
                    {item.status}
                    <div>{item.channel}</div>
                </TableCell>

                <TableCell>
                    <div>From: {this.props.formatDate(item.fromDate)}</div>
                    <div>To: {this.props.formatDate(item.toDate)}</div>
                </TableCell>
                <TableCell>
                    <div className={classes.dataLargeRow}>
                        {item.created && (
                            <div>{this.props.formatDateTime(item.created)}</div>
                        )}
                    </div>
                </TableCell>
                <TableCell>
                    <RenderLogs
                        logs={item.logs}
                        submissionId={item.submissionId}
                        error={item.error}
                    />
                </TableCell>
            </TableRow>
        );
    }
}

export class FmcsaAdmin extends React.Component {
    state = {
        limit: 10,
        page: 0
    };

    componentDidMount() {
        this.getAllFmcsa();
    }

    handleChangePage = (event, page) => {
        this.setState({ page }, this.getAllFmcsa);
    };

    handleChangeRowsPerPage = event => {
        const limit = event.target.value;
        this.setState({ limit }, this.getAllFmcsa);
    };

    getAllFmcsa = async () => {
        const { companyId, limit, page, start, end } = this.state;
        const skip = parseInt(page) * parseInt(limit);
        let payload = { accountId: companyId, skip, limit };

        if (start && end) {
            payload.to = end;
            payload.from = start;
        }
        this.setState({ loading: true })
        await this.props.getFmcsaHistory(payload);
        await this.props.getFmcsaHistoryCount(payload);
        this.setState({ loading: false })


    };

    handleSearchChange = ({ companyId, driverId, start, end }) => {
        this.setState({ companyId, driverId, start, end }, this.getAllFmcsa);
    };

    render() {
        const {
            classes,
            formatDate,
            formatDateTime,
            fmcsas = [],
            count = 0
        } = this.props;

        const { limit = 10, page = 1, loading } = this.state;

        return (
            <>
                {/* <Paper className={classes.root}> */}
                <SearchBar
                    options={{
                        isComp: true,
                        isEqp: false,
                        isDriver: false,
                        isStartDate: true,
                        isEndDate: true,
                        isTime: false
                    }}
                    onSearch={this.handleSearchChange}
                />
                <Grid container spacing={0} style={{ padding: 10 }}>
                    <Grid item xs={12} sm={12}>
                        Count {count}
                    </Grid>
                </Grid>
                {loading ? (
                    <Grid>
                        <div style={{ textAlign: "center" }}>
                            <CircularProgress />
                        </div>
                    </Grid>
                ) : (<Paper>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Driver</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Details</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Logs</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array.isArray(fmcsas) &&
                                fmcsas.map((item, i) => (
                                    <ReduxTableRow
                                        item={item}
                                        key={i}
                                        formatDate={formatDate}
                                        formatDateTime={formatDateTime}
                                        classes={classes}
                                    />
                                ))}
                        </TableBody>
                    </Table>
                    <NotFoundTableRow items={fmcsas} />
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
                        component="div"
                        count={count}
                        rowsPerPage={limit}
                        page={page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                </Paper>)}
            </>
        );
    }
}

FmcsaAdmin.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        fmcsaBusy: _get(state, "fmcsaData.fmcsaBusy", false),
        count: _get(state, "fmcsaData.historyCount", 0),
        fmcsas: _get(state, "fmcsaData.fmcsasHistory", []),
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            formatDate,
            formatDateTime,
            getFmcsaHistoryCount,
            getFmcsaHistory
        },
        dispatch
    );

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(FmcsaAdmin)
);

import { createStore, compose, applyMiddleware } from "redux";
import rootReducer from "./actions/reducers";
import thunk from "redux-thunk";
import queryString from "query-string";
import loggerMiddleware from "./middlewares/loggerMiddleware";
// import logger from 'redux-logger'
import { getProfile } from "./actions/auth/authActions";
import * as constants from "./constants";
import { getConfig } from "./actions/app/appActions";
const moment = require("moment-timezone");

moment.createFromInputFallback = function (config) {
  config._d = new Date(config._i);
};

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let token;
try {
  const values = queryString.parse(window.location.search);
  token = values.token;
} catch (e) {}

const isUnity = process.env.REACT_APP_CLIENT_APP === "Unity";
const andriodPackage = isUnity ? "motion.com.eld" : "com.asrit.smartelds";
const iosPackage = isUnity
  ? "motionseld/id6446702485"
  : "smart-elds/id1578223541";
const andriodUrl = `https://play.google.com/store/apps/details?id=${andriodPackage}`;
const iosUrl = `https://apps.apple.com/us/app/${iosPackage}`;

const initialState = {
  appData: {
    preCheck: false,
    timezone: moment.tz.guess(),
    defaultTimezone: moment.tz.guess(),
    BASE_URL: process.env.REACT_APP_CORE_API,
    STATUS_BASE_URL: process.env.REACT_APP_STATUS_BASE_URL,
    REALTIME_URL: process.env.REACT_APP_REALTIME_BASE_URL,
    LIVE_SHARE_URL: process.env.REACT_APP_LIVE_SHARE_URL,
    CO_URL: process.env.REACT_APP_CO_URL,
    S3_BUCKET: process.env.REACT_APP_S3_BUCKET,
    constants,
    isQa: process.env.REACT_APP_IS_QA,
    IFTA_KEY: process.env.REACT_APP_IFTA_API_KEY,
    clientApp: process.env.REACT_APP_CLIENT_APP,
    isUnity: isUnity,
    andriodAppUrl: andriodUrl,
    iosAppUrl: iosUrl,
  },
};

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk, loggerMiddleware))
);

if (!token) {
  store.dispatch(getConfig());
  store.dispatch(getProfile());
}

export { isUnity };
export default store;

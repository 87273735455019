import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { getUsers } from "../../actions/userActions";
import { sendNotification } from "../../actions/notificationsActions";
import Grid from "@material-ui/core/Grid";
import ReduxButton from "../../components/ReduxButton";
import Error from "../Error";
import Success from "../Success";

const styles = (theme) => ({
  textField: {
    padding: 4,
    width: "100%",
  },
  // formControl: {
  //     // margin: theme.spacing(1),
  //     minWidth: 120
  // },
  DialogActions: {
    marginTop: 40,
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export class TermsDialog extends React.Component {
  state = {
    successMessage: "",
    errorMessage: "",
    loading: false,
  };

  componentDidMount() {
    this.props.getUsers({ skip: 0, limit: 250, status: "ACTIVE" });
  }

  save = async () => {
    let { userId, title, body } = this.state;
    const { driverId } = this.props;
    userId = driverId ? driverId : userId;
    this.setState({ successMessage: "", errorMessage: "" });

    if (!userId || !title || !body) {
      this.setState({
        errorMessage: "User, title and body is required.",
      });
      return;
    }
    this.setState({ loading: true });
    let response;
    try {
      response = await this.props.sendNotification({ userId, title, body });
    } catch (e) {
      //
    }

    if (response._id) {
      this.setState({
        loading: false,
        successMessage: "Message is send successfully.",
      });
      setTimeout(() => this.props.handleClose(), 1000);
    } else {
      this.setState({
        loading: false,
        errorMessage: "Something went wrong, please try after sometime.",
      });
    }
  };

  sendSilent = async (key) => {
    let { userId } = this.state;
    const { driverId } = this.props;
    userId = driverId ? driverId : userId;
    this.setState({ successMessage: "", errorMessage: "" });

    if (!userId) {
      this.setState({
        errorMessage: "User is required.",
      });
      return;
    }
    this.setState({ loading: true });
    let response = {};
    try {
      response = await this.props.sendNotification({
        userId,
        title: key,
        body: key,
        silent: true,
        key,
      });
    } catch (e) {
      //
    }

    if (response.silent) {
      this.setState({
        loading: false,
        successMessage: "Message is send successfully.",
      });
      setTimeout(() => this.props.handleClose(), 1000);
    } else {
      this.setState({
        loading: false,
        errorMessage: "Something went wrong, please try after sometime.",
      });
    }
  };

  handleChange = (event, name) => {
    const value = event.target.value;
    this.setState({ [name]: value });
  };

  render() {
    const { classes, users, driverId, isUnity } = this.props;
    const {
      errorMessage,
      successMessage,
      loading = false,
      userId = "",
      title = "",
      body = "",
    } = this.state;

    return (
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        className={classes.wrapper}
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <h2 style={{ textAlign: "center", padding: 0 }}>
          {"Send Notification"}
        </h2>
        <DialogTitle id="alert-dialog-title" onClose={this.props.handleClose}>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Grid container>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                id="userId"
                select
                label="Select"
                disabled={driverId ? true : false}
                className={classes.textField}
                value={driverId ? driverId : userId}
                onChange={(e) => this.handleChange(e, "userId")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal"
              >
                {Array.isArray(users) &&
                  users.map((option) => {
                    if (
                      option.fcmToken ||
                      option.fcmIosToken ||
                      option.fcmWebToken
                    ) {
                    } else {
                      return null;
                    }
                    return (
                      <MenuItem key={option.id} value={option.id}>
                        {option.firstName} {option.lastName}
                        <small>
                          {option.email}
                          {` (${option.status})`}
                        </small>
                      </MenuItem>
                    );
                  })}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <TextField
                id="title"
                label="Title"
                className={classes.textField}
                value={title}
                onChange={(e) => this.handleChange(e, "title")}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                id="body"
                label="Body"
                className={classes.textField}
                value={body}
                onChange={(e) => this.handleChange(e, "body")}
                margin="normal"
              />
            </Grid>
            {driverId && (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  padding: 12,
                }}
              >
                <ButtonGroup
                  color="primary"
                  aria-label="outlined primary button group"
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      this.sendSilent("SYNC_ALL");
                    }}
                  >
                    {"SYNC ALL"}
                  </Button>
                  {/*<Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      this.sendSilent("FIREBASE_SYNC");
                    }}
                  >
                    {"FIREBASE SYNC"}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      this.sendSilent("FIREBASE_SYNC_FORCE");
                    }}
                  >
                    {"FIREBASE SYNC FORCE"}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      this.sendSilent("CLEAR_DTC");
                    }}
                  >
                    {"CLEAR DTC"}
                  </Button>*/}
                </ButtonGroup>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={12}>
              <Error top={20} message={errorMessage} />
              <Success top={20} message={successMessage} />
            </Grid>
          </Grid>
          <DialogActions className={classes.DialogActions}>
            <ReduxButton
              width
              loading={loading}
              onClick={this.save}
              marginTop={25}
              label={"Send"}
            />
            <Button onClick={this.props.handleClose} color="primary">
              {"Cancel"}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: _get(state, "authData.termsBusy", false),
    constants: _get(state, "appData.constants", {}),
    users: _get(state, "userData.users", []),
    errorMessage: _get(state, "authData.errorMessage", ""),
    isUnity: !_get(state, "appData.isUnity", false),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUsers,
      sendNotification,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(TermsDialog))
);

import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Error from "../Error";
import Success from "../Success";
import TextField from "@material-ui/core/TextField";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    minHeight: 25,
    // overflow: "scroll"
  },
  EditIcon: {
    color: "#009be5",
    cursor: "pointer",
    float: "right",
  },
  driverHeader: {
    textAlign: "center",
    marginLeft: 25,
    padding: 10,
    fontWeight: 600,
    marginBottom: "30px",
  },
  accountRow: {
    padding: 10,
    backgroundColor: "#f0f0f0",
    borderRadius: 10,
  },
  accountTitle: {
    display: "inline-block",
    minWidth: 150,
  },
});

export class SettingEditReasonDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editReasons: props.editReasons,
      errorMessage: "",
      successMessage: "",
      dcId: props.accountSettings.dcId,
      // gps: props.accountSettings.gps,
    };
  }

  componentDidMount() {}

  handleToggle = (e, item) => {
    const allItem = this.state.editReasons;
    const editReasons = allItem.map((_item) => {
      if (_item.en === item.en) {
        _item.active = e.target.checked;
      }
      return _item;
    });
    this.setState({ editReasons });
  };

  handleInputChange = (name, value) => {
    this.setState({ [name]: value });
  };

  save = async (e, items) => {
    const response = await this.props.updateAccountProperties({
      accountSettingsId: this.props.accountSettingsId,
      editReasons: items,
      // gps: this.state.gps,
      dcId: this.state.dcId,
    });

    if (response._id) {
      this.setState({
        successMessage: "Edit reasons are updated successfully.",
      });
    } else {
      this.setState({
        errorMessage: "Something went wrong, please try after sometime.",
      });
    }
  };

  render() {
    const {  dcId } = this.state;
    const { classes, accountUpdateBusy } = this.props;
    const { editReasons, errorMessage, successMessage } = this.state;

    return (
      <Paper className={classes.paper}>
        <form className={classes.root} noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                id="dcId"
                label="Dashcamera Id"
                defaultValue={dcId}
                onChange={(e) => this.handleInputChange("dcId", e.target.value)}
              />
            </Grid>
            {/*<Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="gps"
                label="Gps Id"
                defaultValue={gps}
                onChange={(e) => this.handleInputChange("gps", e.target.value)}
              />
            </Grid>*/}
          </Grid>
        </form>
        <div className={classes.driverHeader}>EDIT REASONS</div>
        <List className={classes.root}>
          <Grid>
            {Array.isArray(editReasons) &&
              editReasons.map((item, i) => (
                <ListItem key={i} role="listitem" button>
                  <ListItemIcon>
                    <Checkbox
                      onClick={(e) => this.handleToggle(e, item)}
                      checked={item.active}
                      tabIndex={-1}
                      color="primary"
                    />
                  </ListItemIcon>
                  <ListItemText primary={item.en} />
                </ListItem>
              ))}
          </Grid>
        </List>
        <Error top={20} message={errorMessage} />
        <Success top={20} message={successMessage} />
        <div className={classes.driverHeader}>
          <Button
            color="primary"
            disabled={accountUpdateBusy}
            variant="contained"
            className={classes.button}
            onClick={(e) => this.save(e, editReasons)}
          >
            {accountUpdateBusy ? "Saving..." : "SAVE"}
          </Button>
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: state,
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {})(SettingEditReasonDetail)
);

const moment = require("moment-timezone");

export const dateToday = new Date()
  .toISOString()
  .slice(0, 10)
  .replace(/-/g, "/")
  .replace("T", " ");

export const startDay = moment().startOf("day").toISOString();

export const currTime = moment().toISOString();

export function getSelectYears(count = 5) {
  const currentYear = moment().format("YYYY");

  let years = [
    {
      id: parseInt(currentYear) + 1,
      name: parseInt(currentYear) + 1,
    },
  ];
  for (var i = 0; i < count; i++) {
    years.push({
      id: parseInt(currentYear) - i,
      name: parseInt(currentYear) - i,
    });
  }
  return years;
}

export function currentTime(days) {
  return moment().add(days, "days").toISOString();
}

moment.createFromInputFallback = function (config) {
  config._d = new Date(config._i);
};

export function formatDate(date) {
  return moment(date).format("MM/D/YYYY hh:mm:ss");
}

export function formatDateOnly(date) {
  return moment(date).format("MMM Do YYYY");
}

export function findDateBetween(date, start, end) {
  const _date = moment(date).valueOf();
  const _start = moment(start).valueOf();
  const _end = moment(end).valueOf();

  return _date >= _start && _date <= _end;
}

export function isSameDay(start, end) {
  return (
    moment(start).format("MM-DD-YYYY") === moment(end).format("MM-DD-YYYY")
  );
}

export function getDateDiff(start, end) {
  const count = moment(start).diff(moment(end), "seconds");
  return count;
}

export function isPastDate(start, end) {
  if (moment(start).isValid() && moment(end).isValid()) {
    const count = moment(start).diff(moment(end), "seconds");
    return count >= 0;
  } else {
    return false;
  }
}

export function addDay(date, days) {
  if (moment(date).isValid()) {
    return moment(date).add(days, "days");
  } else {
    return null;
  }
}

export function getLatestEvent(items = [], fieldName) {
  let max_dtObj = new Date(items[0] && items[0][fieldName]);
  let latest = items[0];
  items.forEach((item = {}, index) => {
    if (new Date(item[fieldName]) > max_dtObj) {
      max_dtObj = new Date(item[fieldName]);
      latest = item;
    }
  });
  return latest;
}

export function getDuration(currTime, nextTime) {
  let roundCurr = "";
  let roundNext = "";
  const cSecs = currTime.seconds();
  const nSecs = nextTime.seconds();

  if (cSecs >= 30 && nSecs >= 30) {
    roundCurr =
      currTime.second() || currTime.millisecond()
        ? currTime.add(1, "minute").startOf("minute")
        : currTime.startOf("minute");
    roundNext =
      nextTime.second() || nextTime.millisecond()
        ? nextTime.add(1, "minute").startOf("minute")
        : nextTime.startOf("minute");
  } else if (cSecs < 30 && nSecs < 30) {
    roundCurr = currTime.startOf("minute");
    roundNext = nextTime.startOf("minute");
  } else if (cSecs >= 30 && nSecs < 30) {
    roundCurr =
      currTime.second() || currTime.millisecond()
        ? currTime.add(1, "minute").startOf("minute")
        : currTime.startOf("minute");
    roundNext = nextTime.startOf("minute");
  } else if (cSecs < 30 && nSecs >= 30) {
    roundCurr = currTime.startOf("minute");
    roundNext =
      nextTime.second() || nextTime.millisecond()
        ? nextTime.add(1, "minute").startOf("minute")
        : nextTime.startOf("minute");
  }

  const duration = moment.duration(roundNext.diff(roundCurr));
  const hours = duration.hours();
  const mins = duration.minutes();
  let total = `${hours < 10 ? "0" + hours : hours}H${
    mins < 10 ? "0" + mins : mins
  }M`;
  return total;
}
export function utcOffset(tZone) {
  const result = moment.tz(tZone).utcOffset();
  return result;
}

export function roundOff(time) {
  let temp = moment(time);
  let result = "";
  if (temp.seconds() >= 30) {
    result =
      temp.second() || temp.millisecond()
        ? temp.add(1, "minute").startOf("minute")
        : temp.startOf("minute");
  } else {
    result = temp.startOf("minute");
  }
  return result;
}

export const lastEightDays = moment().subtract(8, "days").format("YYYY/MM/DD");

export function daydiff(curr, prev) {
  var a = moment(curr);
  var b = moment(prev);
  let result = a.diff(b, "days");
  return result;
}

export function getStartOfDay(date) {
  return moment(date).startOf("day");
}

export function getEndOfDay(date) {
  return moment(date).endOf("day");
}

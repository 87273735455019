import React from "react";
import _get from "lodash/get";
import Helmet from "react-helmet";
import { BrowserRouter as Router } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Hidden from "@material-ui/core/Hidden";
import theme from "./styles/theme";
import MainNavigator from "./Navigator/MainNavigator";
import MainRoutes from "./Navigator/MainRoutes";
import { signOut, getProfile } from "./actions/auth/authActions";
import Header from "./containers/Header";
import { getAccountAuth } from "./actions/account/accountAdminActions";
import * as cookie from "react-cookie";
const cookies = new cookie.Cookies();
// import { Hidden } from "@material-ui/core";

const drawerWidth = 240;
const minDrawerWidth = 50;

const styles = {
  root: {
    display: "flex",
    minHeight: "100vh",
  },
  drawer: {
    [theme.breakpoints.up("lg")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    [theme.breakpoints.up("md")]: {
      width: 0,
    },
  },
  appContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    background: "white",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "80%",
    },
  },
};

class AppSecure extends React.Component {
  state = {
    mobileOpen: false,
    show: true,
  };

  handleDrawerToggle = () => {
    this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
  };

  handleUserIconClick = () => {
    this.props.signOut();
  };

  handleAppAccount = async () => {
    // console.log(" ------ handleAppAccount ------");
    const response = await this.props.getAccountAuth();
    // console.log("response ::::: ", response);

    cookies.set("token", response.token, {
      path: "/",
      maxAge: 43200,
    });

    await this.props.getProfile();

    setTimeout(() => {
      this.props.history.push(`/dashboard/accounts`);
      window.location.reload();
    }, 100);
  };

  render() {
    const { classes, needAutorization, accountBaseUrl, adminKey } = this.props;
    const { show } = this.state;
    const isUnity = this.props.isUnity;

    if (needAutorization) {
      // console.log("TODO: needAutorization ::::::::: ", needAutorization);
      this.props.history.push("/");
    }

    const drawerFixWidth = show ? drawerWidth : minDrawerWidth;
    const routesWidth = window.innerWidth - drawerFixWidth;

    return (
      <Router>
        <div className={classes.root}>
          {isUnity && (
            <Helmet>
              <title>ELD</title>
              <link rel="shortcut icon" href="/favicon-reseller.ico" />
              <meta
                name="description"
                content="FMCSA Registered ELD Solution"
              />
              <meta name="author" content="ELD" />
              <meta name="keyword" content="ELD, FMCSA, IFTA, HOS, Tracking" />
            </Helmet>
          )}
          <CssBaseline />
          <div className={classes.appContent}>
            <Header
              adminKey={adminKey}
              handleAppAccount={this.handleAppAccount}
              onDrawerToggle={this.handleDrawerToggle}
              handleUserIconClick={this.handleUserIconClick}
            />
            <div className="d-flex">
              <Hidden
                lgUp
                // implementation="js"
              >
                <MainNavigator
                  PaperProps={{
                    style: {
                      // width: drawerWidth
                    },
                  }}
                  variant="temporary"
                  open={this.state.mobileOpen}
                  onClose={this.handleDrawerToggle}
                  show={true}
                />
              </Hidden>
              <Hidden mdDown>
                <nav
                  className={classes.drawer}
                  style={{ width: show ? drawerWidth : minDrawerWidth }}
                >
                  <MainNavigator
                    show={show}
                    setShow={() => {
                      this.setState({ show: !show });
                    }}
                    PaperProps={{
                      style: { width: show ? drawerWidth : minDrawerWidth },
                    }}
                  />
                </nav>
              </Hidden>
              <Hidden mdDown style={{ width: routesWidth }}>
                <MainRoutes accountBaseUrl={accountBaseUrl} />
              </Hidden>
              <Hidden lgUp style={{ width: window.innerWidth }}>
                <MainRoutes accountBaseUrl={accountBaseUrl} />
              </Hidden>
            </div>
          </div>
        </div>
      </Router>
    );
  }
}

AppSecure.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isDriver: _get(state, "authData.isDriver", false),
    accountBaseUrl: _get(state, "authData.accountBaseUrl"),
    adminKey: _get(state, "authData.profile.adminId"),
    needAutorization: _get(state, "authData.needAutorization", false),
    isUnity: _get(state, "appData.isUnity", false),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signOut,
      getAccountAuth,
      getProfile,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AppSecure)
);

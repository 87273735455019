import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { Field } from "redux-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const styles = theme => ({
    dateInput: {
        padding: 5,
        marginTop: 20,
        fontSize: 16,
        border: 0,
        width: "100%",
        borderBottom: "1px solid grey"
    }
});

const renderTextField = ({ label, input, classes }) => (
    <DatePicker
        selected={input.value}
        placeholderText={label}
        onChange={input.onChange}
        className={classes.dateInput}
    />
);

class ReduxInputDate extends React.Component {
    render() {
        const { xs = 12, sm = 12, name, label, helpText, classes } = this.props;

        const _label = label ? label : name;

        return (
            <Grid
                item
                xs={xs}
                sm={sm}
                style={{ textAlign: "center", paddingTop: 13 }}
            >
                <Field
                    name={name}
                    classes={classes}
                    component={renderTextField}
                    label={_label}
                    onChange={this.onChange}
                />
                {helpText && (
                    <div style={{ textAlign: "left", fontSize: 10 }}>
                        {helpText}
                    </div>
                )}
            </Grid>
        );
    }
}

ReduxInputDate.propTypes = {
    onChange: PropTypes.func
};

export default withStyles(styles)(ReduxInputDate);

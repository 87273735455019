import _get from "lodash/get";
import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getAllDvir, getAllDvirCount } from "../../actions/dvirActions";
import {
    getStartOfDay,
    getEndOfDay,
    formatDateTime
} from "../../actions/momentActions";
import { startDay, currTime } from "../../utils/momentHelpers";
import DvirTable from "./DvirTable";
import SearchBar from "../../components/SearchBar";

export class Register extends React.Component {
    state = {
        limit: 10,
        page: 0,
        loading: false,
        start: startDay,
        end: currTime,
        driverId: "",
        vehicleId: ""
    };
    async componentDidMount() {
        await this.props.getAllDvirCount();
        await this.getAllDvir();
    }

    getAllDvir = async () => {
        this.setState({ loading: true });
        const { limit, page, driverId, start, end, vehicleId } = this.state;
        const skip = parseInt(page) * parseInt(limit);

        await this.props.getAllDvir({
            limit,
            skip,
            driverId,
            vehicleId,
            start: start ? new Date(this.props.getStartOfDay(start)) : null,
            end: end ? new Date(this.props.getEndOfDay(end)) : null
        });
        this.setState({ loading: false });

    };
    handleChange = ({ limit, page }) => {
        this.setState({ limit, page }, this.getAllDvir);
    };

    handleSearchChange = ({ driverId, vehicleId, start, end }) => {
        this.setState({ driverId, vehicleId, start, end }, this.getAllDvir);
    };

    onSearch = ({ driverId, start, end }) => {
        this.setState({ driverId, start, end }, this.getAllDvir());
    };
    render() {
        const { dvirs } = this.props;
        const { loading } = this.state;
        return (
            <Grid container spacing={0} style={{ padding: 10 }}>
                <Grid item xs={12} sm={12}>
                    <SearchBar
                        options={{
                            isEqp: true,
                            isTrailer: true,
                            isDriver: true,
                            isStartDate: true,
                            isEndDate: true,
                            isTime: false,
                            isTimeperiod: true,
                            allDrivers: true
                        }}
                        dl={true}
                        loading={loading}
                        onSearch={this.handleSearchChange}
                    />
                    {loading && (
                        <div style={{ textAlign: "center" }}>
                            <CircularProgress />
                        </div>)}

                    <DvirTable
                        items={dvirs}
                        count={this.props.count}
                        getAllDvir={this.props.getAllDvir}
                        formatDateTime={this.props.formatDateTime}
                        limit={this.state.limit}
                        page={this.state.page}
                        handleChange={this.handleChange}
                        adminKey={this.props.adminKey}
                    />
                </Grid>
            </Grid>
        );
    }
}

reduxForm({
    form: "RegisterForm"
})(Register);

const mapStateToProps = state => {
    return {
        dvirs: state.dvirData.dvirAll,
        count: state.dvirData.count,
        adminKey: _get(state, "authData.profile.adminId"),
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getAllDvir,
            getStartOfDay,
            getEndOfDay,
            formatDateTime,
            getAllDvirCount
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Register)
);

import _get from "lodash/get";
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import { withStyles } from "@material-ui/core/styles";
import PeopleIcon from "@material-ui/icons/People";
import Button from "@material-ui/core/Button";

const driverTabs = {
    baseUrl: "/dashboard",
    tabs: [
        {
            id: "Carrier",
            icon: <PeopleIcon />,
            active: true,
            url: "/settings/carrier"
        },
        {
            id: "Terminal",
            icon: <PeopleIcon />,
            active: true,
            url: "/settings/terminal"
        },
        {
            id: "Equipment",
            icon: <PeopleIcon />,
            active: true,
            url: "/settings/equipment"
        },
        // {
        //     id: "NEW TRAILER",
        //     icon: <PeopleIcon />,
        //     active: true,
        //     url: "/settings/trailer"
        // },
        {
            id: "Users",
            icon: <PeopleIcon />,
            active: true,
            url: "/settings/user"
        },
        // {
        //     id: "ACCOUNT",
        //     icon: <PeopleIcon />,
        //     active: true,
        //     url: "/settings/account"
        // },

        // { id: 'MOBILE APP', icon: <PeopleIcon />, active: true, url: '/settings/app' },
        {
            id: "Edit Reason",
            icon: <PeopleIcon />,
            active: true,
            url: "/settings/editreason"
        },
        // {
        //     id: "EXCEPTIONS",
        //     icon: <PeopleIcon />,
        //     active: true,
        //     url: "/settings/exceptions"
        // },
        {
            id: "Profile",
            icon: <PeopleIcon />,
            active: true,
            url: "/settings/profile"
        },
        {
            id: "Api",
            icon: <PeopleIcon />,
            active: true,
            url: "/settings/api"
        },
        {
            id: "Alerts",
            icon: <PeopleIcon />,
            active: true,
            url: "/settings/notification"
        },
    ]
};

const styles = theme => ({
    secondaryBar: {
        zIndex: 0
    },
    button: {
        margin: theme.spacing(1)
        // color: "white",
        // paddingBottom: 2,
        // fontSize: "0.875rem"
        // borderRadius: 0,
        // borderBottom: "3px solid trabsparent",
    },
    active: {
        // borderBottom: "3px solid white",
        // fontSize: "1rem",
        // paddingBottom: 0
    }
});

class UserNavigator extends React.Component {
    handleClick = url => {
        this.props.history.push(url);
    };

    render() {
        const { classes, accountId } = this.props;
        const baseUrl = driverTabs.baseUrl;

        return (
            <AppBar
                component="div"
                className={classes.secondaryBar}
                color="transparent"
                position="static"
                elevation={0}
            >
                <div>
                    {driverTabs.tabs.map(({ id, url }) => {
                        if (accountId) {
                            url = `${baseUrl}/${accountId}${url}`;
                        }

                        let buttonColor = "default";

                        if (url === _get(this, "props.location.pathname")) {
                            buttonColor = "primary";
                        }

                        return (
                            <Button
                                key={id}
                                color={buttonColor}
                                className={classes.button}
                                onClick={() => this.handleClick(url)}
                            >
                                {id}
                            </Button>
                        );
                    })}
                </div>
            </AppBar>
        );
    }
}

UserNavigator.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(UserNavigator));

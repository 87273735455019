import React from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Pagination from "./Pagination/Pagination";
import PageOne from "./PageOne";
import PageTwo from "./PageTwo";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const styles = {
  root: {
    position: "relative",
  },
  slide: {
    padding: 15,
    minHeight: 100,
    color: "#fff",
  },
  slide1: {
    backgroundColor: "#FEA900",
  },
  slide2: {
    backgroundColor: "#B3DC4A",
  },
  slide3: {
    backgroundColor: "#6AC0FF",
  },
};

class DemoAutoPlay extends React.Component {
  state = {
    index: 0,
  };

  handleChangeIndex = (index) => {
    this.setState({
      index,
    });
  };

  render() {
    const { index } = this.state;

    return (
      <div style={styles.root}>
        <AutoPlaySwipeableViews
          index={index}
          onChangeIndex={this.handleChangeIndex}
          enableMouseEvents
          interval={5000}
        >
          <PageOne />
          <PageTwo />
        </AutoPlaySwipeableViews>
        <div>
          <Pagination
            dots={2}
            index={index}
            onChangeIndex={this.handleChangeIndex}
          />
        </div>
      </div>
    );
  }
}

export default DemoAutoPlay;

import _get from "lodash/get";
import React, { useEffect, useState, useCallback } from "react";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
  Polyline,
  InfoWindow,
} from "react-google-maps";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import StopIcon from "@material-ui/icons/Stop";

function getIntials(item) {
  const driver = item.driver || {};
  const fName = driver.firstName
    ? driver.firstName.slice(0, 1).toUpperCase()
    : "";
  const lName = driver.lastName
    ? driver.lastName.slice(0, 1).toUpperCase()
    : "";

  return fName + lName;
}

function RenderMarker(props) {
  const item = props.item || {};
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (props.dId) {
      if (props.dId === item.uqId) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }
  });

  const address = "";
  const _time = "";
  const time = "";

  return (
    <Marker
      position={{ lat: props.lat, lng: props.lng }}
      prerender={false}
      label={`${props.index + 1}`}
    ></Marker>
  );
}

const Map = (props) => {
  const selIndex = props.selIndex;
  const _allStatus = props.allStatus;
  const [progress, setProgress] = useState(null);
  let initialDate;
  let interval = null;

  let start = selIndex - 15;
  let end = selIndex + 15;

  if (start < 0) {
    start = 0;
  }
  if (end > _allStatus.length) {
    end = _allStatus.length;
  }

  const allStatus = _allStatus.slice(start, end);

  let zoom = 10;
  let center = {
    lat: 37.658428,
    lng: -121.876999,
  };

  function zoomToMarkers(map) {
    if (allStatus && allStatus.length && !progress) {
      const bounds = new window.google.maps.LatLngBounds();
      allStatus.forEach((child) => {
        if (child.coordinates && child.coordinates[0] && child.coordinates[1])
          bounds.extend(
            new window.google.maps.LatLng(
              parseFloat(child.coordinates[0]),
              parseFloat(child.coordinates[1])
            )
          );
      });
      map && map.fitBounds(bounds);
    }
  }

  return (
    <Card variant="outlined">
      <div className="gMapCont">
        <GoogleMap zoom={zoom} center={center} ref={zoomToMarkers}>
          {allStatus.map((item, idx) => (
            <RenderMarker
              key={`id-${idx}`}
              lat={parseFloat(item.coordinates[0])}
              lng={parseFloat(item.coordinates[1])}
              driver={{}}
              index={idx}
              item={item}
            />
          ))}
        </GoogleMap>
      </div>
    </Card>
  );
};

export default withScriptjs(withGoogleMap(Map));

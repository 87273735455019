const INPUTS = {
  signInLabel: "Correo electrónico",
  signInPlaceholder: "Correo electrónico",
  signInError: "Requerida",

  passwordLabel: "Contraseña",
  passwordPlaceholder: "Contraseña",
  passwordError: "Requerida",

  errorInvalidEmailPwd: "Correo electrónico o contraseña no válidos",
};

const BUTTONS = {
  logInLabel: "Acceso",
  forgetPwdLabel: "Contraseña olvidada",
};

export { INPUTS, BUTTONS };

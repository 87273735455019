import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const ITEM_HEIGHT = 48;

export default function LongMenu({
    selectedItem,
    prevLog,
    prevLog2,
    selectedLabel,
    labels = [],
    index = "",
    handleMenuClick = () => { }
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose(item, selectedItem, index) {
        setAnchorEl(null);
        handleMenuClick(null, item, selectedItem, index, prevLog, prevLog2);
    }

    return (
        <div>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: 200
                    }
                }}
            >
                {labels.map(label => (
                    <MenuItem
                        key={label}
                        selected={label === selectedLabel}
                        onClick={() => handleClose(label, selectedItem, index)}
                    >
                        {label}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
// import Input from "../../components/Input";
import Error from "../Error";
import Success from "../Success";

const styles = theme => ({
    wrapper: {},
    DialogActions: {
        marginTop: 20
    },
    AlignCenter: {
        textAlign: "center"
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

export class ConfirmActionModal extends React.Component {
    state = {
        loading: false
    };

    componentDidMount() {
        this.setState({ errorMessage: "", successMessage: "" });
    }

    render() {
        const {
            label,
            message,
            loading,
            classes,
            btnLabel = "DELETE",
            btnLoading = "Deleting..."
        } = this.props;

        const { errorMessage, successMessage } = this.state;
        const isSmallScreen = window.innerWidth < 400;

        return (
            <Dialog
                fullScreen={isSmallScreen}
                fullWidth={true}
                maxWidth={"md"}
                className={classes.wrapper}
                open={!!this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    onClose={this.props.handleClose}
                >
                    <Typography>{label}</Typography>
                    <IconButton
                        aria-label="Close"
                        className={classes.closeButton}
                        onClick={this.props.handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <Grid container spacing={4}>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            className={classes.AlignCenter}
                        >
                            <h2>{message}</h2>
                        </Grid>
                        {errorMessage || successMessage ? (
                            <Grid item xs={12} sm={12} md={12}>
                                <Error message={errorMessage} />
                                <Success message={successMessage} />
                            </Grid>
                        ) : null}
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            className={classes.AlignCenter}
                        >
                            <Button
                                onClick={this.props.handleConfirm}
                                color="secondary"
                                autoFocus
                                variant="contained"
                                disabled={loading}
                            >
                                {loading ? btnLoading : btnLabel}
                            </Button>
                            <br />
                            <Button
                                onClick={this.props.handleClose}
                                color="primary"
                                autoFocus
                            >
                                CANCEL
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }
}

export default withStyles(styles)(ConfirmActionModal);

import VIN from "vehicle-identification-number";

const validate = values => {
    const errors = {};
    const requiredFields = ["name", "dot","line1", "city", "state", "zip", "country"];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = `${field} is required`;
        }
    });
    if (
        values.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = "Invalid email address";
    } else if (values.vin) {
        try {
            VIN.parse(values.vin);
        } catch (e) {
            errors.vin = `VIN is not valid.`;
        }
    }
    return errors;
};

export default validate;

import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { getDrivers } from '../../actions/driverActions';

const styles = theme => ({});

export class UserAll extends React.Component {

  componentDidMount() {
    // this.props.getDrivers();
  }

  render() {
    return (<div>
      <h3>View settings APP</h3>
    </div>)
  }
}


const mapStateToProps = state => {
  return {
    store: state
  }
};

const mapDispatchToProps = dispatch => bindActionCreators({
    getDrivers
  }, dispatch
)

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(UserAll));

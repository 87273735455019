import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ProfileCard from "./ProfileCard";
import AccountCard from "./AccountCard";

const styles = theme => ({});

export class Detail extends React.Component {
    render() {
        return (
            <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={1} />
                <Grid item xs={12} sm={12} md={5}>
                    <ProfileCard />
                </Grid>
                <Grid item xs={12} sm={12} md={1} />
                <Grid item xs={12} sm={12} md={4}>
                    <AccountCard />
                </Grid>
                <Grid item xs={12} sm={12} md={1} />
            </Grid>
        );
    }
}

export default withStyles(styles)(Detail);

import React from 'react';
import _get from "lodash/get";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import WorkIcon from '@material-ui/icons/Work';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import CircularProgress from "@material-ui/core/CircularProgress";
import { getAdminOverview } from "../../actions/adminDashActions";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';


const styles = theme => ({

    root: {
        width: "98%",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: theme.spacing(1),
        overflowX: "auto"

    }, card: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,

    },


    appContent: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    mainContent: {
        flex: 1,
        padding: '48px 36px 0',
        background: '#eaeff1',
    },
});

class Dashboard extends React.Component {
    state = {
        loading: false
    };
    async componentDidMount() {
        this.setState({ loading: true })
        await this.props.getAdminOverview();
        this.setState({ loading: false })

    }

    render() {
        const { classes, overview } = this.props;
        const accounts = _get(overview, "accounts", "");
        const equipments = _get(overview, "equipments", "");
        const drivers = _get(overview, "drivers", "");
        const { loading } = this.state;

        return (
            <>
                {loading ? (<Grid>
                    <div style={{ textAlign: "center", marginTop: "20px" }}>
                        <CircularProgress />
                    </div>
                </Grid>
                ) : (<>
                    <Grid style={{ display: "flex", flexDirection: "column" }}>
                        <Grid item xs={4} >
                            <Card className={classes.root} style={{ margin: "20px 20px" }}>
                                <CardContent className={classes.card} >
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <PeopleAltIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Accounts" secondary={accounts} />
                                    </ListItem>
                                </CardContent>
                            </Card>
                        </Grid>


                        <Grid item xs={4}>
                            <Card className={classes.root} style={{ margin: "20px 20px" }}>
                                <CardContent>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <Avatar />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Drivers" secondary={drivers} />
                                    </ListItem>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={4}>
                            <Card className={classes.root} style={{ margin: "20px 20px" }}>
                                <CardContent>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <WorkIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Equipments" secondary={equipments} />
                                    </ListItem>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </>)}
            </>
        );
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        overview: _get(state, "adminOverview.overviewAll", false),
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getAdminOverview
        },
        dispatch
    );

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Dashboard)
);

import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, Form } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import InputBox from "../../components/InputBox";
import SelectBox from "../../components/SelectBox";
import Error from "../Error";

const useStyles = makeStyles((theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  wrapperButton: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  saveButton: {
    minWidth: 150,
    marginTop: 20,
  },
}));

export default function ConfirmDialog(props) {
  const drivers = props.drivers || [];
  const classes = useStyles();
  const [error, setError] = React.useState("");

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Update shipping document or trailer"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`Note:- This will update shipping document or trailer selected logs`}
        </DialogContentText>
        <Formik
          initialValues={{ trailer: "", shippingDoc: "" }}
          validate={() => {}}
          onSubmit={(values) => {
            const data = {};
            setError("");

            if (values.trailer) {
              data.trailer = values.trailer;
            }
            if (values.shippingDoc) {
              data.shippingDoc = values.shippingDoc;
            }
            if (values.driverId) {
              data.driver = drivers.find(
                (_item) => _item.id === values.driverId
              );
            }
            if (data.shippingDoc || data.trailer || data.driver) {
              props.onSave(data);
            } else {
              setError("Shipper or trailer or driver is required.");
            }
          }}
          enableReinitialize={true}
        >
          {({ handleSubmit, handleChange }) => {
            return (
              <Form style={{ padding: "20px 10px" }}>
                <Grid container spacing={3}>
                  <InputBox md={6} name="trailer" label="Trailer" />
                  <InputBox md={6} name="shippingDoc" label="Shipping Doc" />
                  <SelectBox
                    md={12}
                    name={"driverId"}
                    label="Driver"
                    selValue={"id"}
                    selLabel={"firstName"}
                    selLabelTwo={"lastName"}
                    selLabelThree={"email"}
                    items={drivers}
                  />
                </Grid>

                {error && <Error message={error} />}
                <div className={classes.wrapperButton}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={props.loading}
                    size="small"
                    className={classes.saveButton}
                  >
                    {props.loading ? "Saving..." : "SAVE"}
                  </Button>
                  <br />
                  <Button variant="link" onClick={props.onClose} size="small">
                    CLOSE
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

import _get from "lodash/get";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
    textAlign: "center",
    fontWeight: 500,
    padding: 0,
  },
  paperDriver: {
    color: "grey",
    height: 14,
  },
  paperOffDuty: {
    color: "#000",
    height: 14,
  },
  paperOnDuty: {
    color: "#009ddd",
    height: 14,
  },
  paperDriving: {
    color: "#3fca3f",
    height: 14,
  },
  paperSleep: {
    color: "#f5cb42",
    height: 14,
  },
}));

export default function DriverRecap(props) {
  const classes = useStyles();

  const total = _get(props, "data.drivers.length", "0");
  const offDuty = _get(props, "data.offDuty", "0");
  const onDuty = _get(props, "data.onDuty", "0");
  const driving = _get(props, "data.driving", "0");
  const sleeper = _get(props, "data.sleeper", "0");

  return (
    <div className={classes.root}>
      <Paper>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <div className={classes.paperDriver}>
              {`Drivers `}
              <span>{total}</span>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={classes.paperOffDuty}>
              {`Off Duty `}
              <span>{offDuty}</span>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={classes.paperOnDuty}>
              {`On Duty `}
              <span>{onDuty}</span>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={classes.paperDriving}>
              {`Driving `}
              <span>{driving}</span>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={classes.paperSleep}>
              {`Sleeping `}
              <span>{sleeper}</span>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

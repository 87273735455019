import _get from "lodash/get";
import React, { useState } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { BrowserRouter as Router, Route } from "react-router-dom";
import LogIn from "./containers/LogIn";
import AppSecure from "./AppSecure";
import { onMessageListener } from "./firebase";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";

function App(props) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState({});
  const isUnity = props.isUnity

  const handleClose = (event) => {
    setOpen(false);
  };

  onMessageListener()
    .then((payload) => {
      setMessage(payload.notification);
      setOpen(true);
    })
    .catch((err) => console.log("failed: ", err));
  return (
    <div>
      {isUnity && (
        <Helmet>
          <title>ELD Login</title>
          <link rel="shortcut icon" href="/favicon-reseller.ico" />
          <meta name="description" content="FMCSA Registered ELD Solution" />
          <meta name="author" content="ELD"/>
          <meta name="keyword" content="ELD, FMCSA, IFTA, HOS, Tracking"/>
        </Helmet>
      )}
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity="warning"
        >
          <AlertTitle>{message.title}</AlertTitle>
          {message.body}
        </Alert>
      </Snackbar>
      {props.preCheck ? (
        <Router>
          <div>
            <Route exact path="/" component={LogIn} />
            <Route path="/dashboard" component={AppSecure} />
          </div>
        </Router>
      ) : (
        <Router>
          <div>
            <Route exact path="/" component={LogIn} />
          </div>
        </Router>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  preCheck: state.appData.preCheck,
  isUnity: _get(state, "appData.isUnity", false),
});

export default connect(mapStateToProps, {})(App);

import React from "react";
import moment from "moment-timezone";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm } from "redux-form";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { getDrivers } from "../../actions/driverActions";
import { sendFmcsa } from "../../actions/fmcsaActions";
import ReduxInputDate from "../../components/ReduxInputDate";
import ReduxInput from "../../components/ReduxInput";
import ReduxSelect from "../../components/ReduxSelect";
import Success from "../Success";
import Error from "../Error";
import { sorting } from "../../utils/sortingHelpers";

const styles = (theme) => ({
  root: {
    width: "98%",
    marginRight: "auto",
    marginLeft: "auto",
    padding: theme.spacing(1),
    overflowX: "auto",
  },
  addButtonWrapper: {
    width: "99%",
    textAlign: "right",
    marginTop: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "200px",
    textAlign: "left",
  },
  grow: {
    flexGrow: 1,
    textAlign: "center",
  },
  button: {
    minWidth: 135,
    marginTop: 2,
    marginRight: 5,
  },
});

export class SearchBar extends React.Component {
  state = {
    driverId: "all",
    from: new Date(),
    to: new Date(),
    loading: false,
  };

  componentDidMount() {
    this.props.getDrivers({ skip: 0, limit: 250 });
  }

  handleChange = (event, name) => {
    this.setState(
      {
        [name]: event.target.value,
      },
      this.searchChange
    );
  };

  searchChange = () => {
    const { driverId } = this.state;
    this.props.handleChange({
      driverId: driverId !== "all" ? driverId : null,
    });
  };

  validate = (values) => {
    let errorDriver = "";
    let errorFromDate = "";
    let errorToDate = "";
    let errorComment = "";

    if (!values.driverId) {
      errorDriver = "Driver is required. ";
    }
    if (!values.from) {
      errorFromDate = "From date is required. ";
    }
    if (!values.to) {
      errorToDate = "To date is required. ";
    }
    if (!values.comments) {
      errorComment = "Comment is required. ";
    } else if (values.comments.length > 60) {
      errorComment = "Comment is invalid. ";
    }

    if (errorDriver || errorFromDate || errorToDate || errorComment) {
      this.setState({
        errorDriver,
        errorFromDate,
        errorToDate,
        errorComment,
      });
      return;
    } else {
      this.setState({
        errorDriver: "",
        errorFromDate: "",
        errorToDate: "",
        errorComment: "",
      });
      return values;
    }
  };

  sendToFmcsa = (values, isEmail) => {
    values.download = false;
    if (this.validate(values)) {
      this.sendFmcsa(values);
    }
  };

  download = (values) => {
    if (this.validate(values)) {
      values.download = true;
      this.sendFmcsa(values);
    }
  };

  emailToFmcsa = (values, isEmail) => {
    values.channel = "WEBEMAIL";

    if (this.validate(values)) {
      this.sendFmcsa(values, "WEBEMAIL");
    }
  };

  sendFmcsa = async (values, channel) => {
    try {
      this.setState({ errorMessage: "", successMessage: "" });

      values.accountId = this.props.accountId;
      values.channel = channel || "WS";
      const response = await this.props.sendFmcsa(values);
      const ValidationError = _get(
        response,
        "fmcsaResponse.SubmitResult.Errors.ValidationError"
      );
      console.log("ValidationError ", ValidationError);

      if (values.download) {
        return;
      }
      if (response && response.fmcsaResponse) {
        this.setState({
          successMessage: "Logs are successfully submitted.",
        });
      } else {
        this.setState({
          errorMessage: "Something went wrong, please try after some time.",
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  getFileName = (name, lic) => {
    const _fFive = name.substring(0, 5);
    const _fLen = _fFive.length;
    const fFive =
      _fLen === 4
        ? `${_fFive}_`
        : _fLen === 3
        ? `${_fFive}__`
        : _fLen === 2
        ? `${_fFive}___`
        : _fFive;
    const lLic = lic.slice(-2);
    const _licNos = lic
      ? lic
          .toString()
          .split("")
          .reduce((a, b) => a + parseInt(b), 0)
          .toString()
          .slice(-2)
      : "";

    const licNos = _licNos.length === 1 ? `0${_licNos}` : _licNos;
    const today = moment().format("MMDDYY");
    const fileName = `${fFive}${lLic}${licNos}${today}-000000000.csv`;
    return fileName;
  };

  onDriverChange = (event) => {
    const { drivers } = this.props;
    const driverId = event.target.value;
    const driver = drivers.find((item) => item.id === driverId) || {};
    const lastName = driver.lastName;
    const licenseNumber = driver.licenseNumber;
    const fileName = this.getFileName(lastName, licenseNumber);
    this.props.change("fileName", fileName);
  };

  render() {
    const { classes, drivers, loading, handleSubmit } = this.props;
    const {
      errorDriver,
      errorFromDate,
      errorToDate,
      successMessage,
      errorMessage,
      errorComment,
    } = this.state;

    const driversArray = sorting(drivers, "firstName");
    // const driversArray = _driversArray.filter(item => {
    //     console.log('item.userStatus = ', item.userStatus);
    //     return item.userStatus === "ACTIVE";
    // });

    return (
      <Paper className={classes.root}>
        <form noValidate autoComplete="off">
          <Grid container spacing={0}>
            {this.props.adminKey && (
              <Grid item xs={12} sm={12} md={12} align="right">
                <Button
                  size={"small"}
                  onClick={this.props.viewHistory}
                  color="primary"
                  variant="contained"
                  className={classes.button}
                >
                  {"HISTORY"}
                </Button>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={12} align="left">
              <div style={{ color: "red" }}>{errorDriver}</div>
              <div style={{ color: "red" }}>{errorFromDate}</div>
              <div style={{ color: "red" }}>{errorToDate}</div>
              <div style={{ color: "red" }}>{errorComment}</div>
            </Grid>
            <Success message={successMessage} />
            <Error message={errorMessage} />
            <Grid item xs={12} sm={12} md={2}>
              <ReduxSelect
                name="driverId"
                label="Select Driver"
                keyName={"id"}
                valueName={"firstName"}
                valueTwoName={"lastName"}
                valueThreeName={"licenseNumber"}
                valueFourName={"status"}
                onChange={this.onDriverChange}
                items={driversArray}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <ReduxInputDate name="from" label="From Date" />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <ReduxInputDate name="to" label="To Date" />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <ReduxInput name="comments" label={`Comments`} />
            </Grid>
            <Grid item xs={12} sm={12} md={3} align="center">
              <div style={{ marginTop: 30 }}>
                {" "}
                <Button
                  size={"small"}
                  disabled={loading}
                  onClick={handleSubmit(this.sendToFmcsa)}
                  color="primary"
                  variant="contained"
                  className={classes.button}
                >
                  {"SEND TO FMCSA"}
                </Button>
                {this.props.adminKey && (
                  <Button
                    size={"small"}
                    disabled={loading}
                    onClick={handleSubmit(this.emailToFmcsa, true)}
                    color="primary"
                    variant="contained"
                    className={classes.button}
                  >
                    {"EMAIL TO FMCSA"}
                  </Button>
                )}
                {this.props.adminKey && (
                  <Button
                    size={"small"}
                    disabled={loading}
                    onClick={handleSubmit(this.download)}
                    color="primary"
                    variant="contained"
                    className={classes.button}
                  >
                    {"DOWNLOAD"}
                  </Button>
                )}
              </div>
            </Grid>
          </Grid>
        </form>
      </Paper>
    );
  }
}

SearchBar = reduxForm({
  form: "fmcsaSearchBarForm",
})(SearchBar);

const mapStateToProps = (state) => {
  return {
    loading: _get(state, "fmcsaData.fmcsaBusy"),
    accountId: _get(state, "authData.profile.user.accountId"),
    searchValues: _get(state, "form.fmcsaSearchBarForm.values", {}),
    drivers: _get(state, "driverData.drivers", []),
    adminKey: !!_get(state, "authData.profile.adminId"),
    isDebug: _get(
      state,
      "authData.profile.accountProperties.settings.debug",
      true
    ),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getDrivers, sendFmcsa }, dispatch);

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SearchBar)
);

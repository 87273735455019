import React from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";

export default function PageOne() {
  return (
    <React.Fragment>
      <Container
        maxWidth="sm"
        style={{
          backgroundColor: "white",
          height: "450px",
          textAlign: "center",
        }}
      >
        <img
          style={{ width: 300, marginTop: 25 }}
          src="/assets/images/dash-camera.jpg"
          alt="Dashcamera"
        />
        <Typography variant="h4">Integrated Video is here.</Typography>
        <Typography
          variant="subtitle1"
          style={{ marginTop: 25, marginBottom: 25 }}
        >
          Osrit Dashcam helps you improve driver behavior and mitigate risks to
          your business by providing visual context of harsh driving events.
        </Typography>
        <Typography component="span">To know more, please visit at </Typography>

        <Button color="primary" href="https://www.truckdashcamera.com/">
          www.truckdashcamera.com
        </Button>
      </Container>
    </React.Fragment>
  );
}

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import DutyStatus from "../../../components/DutyStatus";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";

function pF(value) {
  return parseFloat(value) ? parseFloat(value) : 0;
}

const styles = (theme) => ({
  root: {
    width: "98%",
    marginRight: "auto",
    marginLeft: "auto",
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  tableHeaderCell: {
    textAlign: "left",
    padding: 10,
    minWidth: 55,
  },
  tableRowCell: {
    fontSize: "12px !important",
    padding: 10,
    font: "message-box !important",
  },
  textLeft: {
    textAlign: "left",
  },
  textCenter: {
    textAlign: "center",
  },
  textInactive: {
    textDecoration: "line-through",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  font: {
    fontSize: "14px",
  },
  select: {
    minWidth: "70px",
  },
  error: {
    marginTop: "2px",
    color: "red",
  },
  odoError: {
    color: "red",
    fontSize: "12px",
  },
  dateHeaderCell: {
    textAlign: "left",
    padding: 12,
    minWidth: 50,
  },
});

class ReduxTableRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {},
      // driverLogs: props.driverLogs
    };
  }

  render() {
    const { classes, item = {}, isAllLog = false } = this.props;
    const eventTypeItems = isAllLog
      ? [
        "CHANGE_DUTY_STATUS",
        "INTERMEDIATE_LOG",
        "YARD_MOVES",
        "PERSONAL_USE",
        "CERTIFICATION",
        "LOGIN_LOGOUT",
        "POWERUP_SHUTDOWN",
      ]
      : ["CHANGE_DUTY_STATUS", "YARD_MOVES", "PERSONAL_USE"];

    const recordStatusItems = isAllLog
      ? [
        "ACTIVE",
        "INACTIVE_CHANGED",
        "INACTIVE_DEACTIVATE",
        "INACTIVE_CHANGE_REQUEST",
        "INACTIVE_CHANGE_REJECTED",
      ]
      : ["ACTIVE"];

    const isAllowed = recordStatusItems.includes(item.recordStatus) && eventTypeItems.includes(item.eventType);

    if (!isAllowed || item.isLast || item.eventTime === this.props.startOfDay(item.eventTime).toISOString()) {
      return null;
    }

    return (
      <TableRow hover className={`${classes.table}`}>
        <TableCell className={`${classes.tableRowCell}`}>
          <Checkbox
            defaultChecked={item.isEdited}
            color={"primary"}
            checked={item.isEdited || false}
            onChange={(e) => {
              item.isEdited = e.target.checked;
              this.props.multi(item);
            }}
          />
        </TableCell>
        <TableCell className={`${classes.tableRowCell}`}>
          <div>
            <strong>{this.props.formatTime(item.eventTime)}</strong>
            <div>{this.props.formatRawDate(item.eventTime)}</div>
          </div>
        </TableCell>
        <TableCell className={`${classes.tableRowCell}`}>
          <DutyStatus
            status={item}
            eventType={item.eventType}
            eventCode={item.eventCode}
            annotation={item.annotation}
            eventSubType={item.eventSubType}
          />
        </TableCell>
        <TableCell className={`${classes.tableRowCell}`}>
          <div>{item.location}</div>
        </TableCell>
        <TableCell className={`${classes.tableRowCell}`}>
          {item.odometer && <div>{pF(item.odometer).toFixed(2)} Miles</div>}
        </TableCell>

        <TableCell className={`${classes.tableRowCell}`}>
          <div>
            <TextField
              className={classes.font}
              style={{ borderBottom: "1px solid #7F7F7F" }}
              fullWidth
              placeholder="Notes"
              InputProps={{
                disableUnderline: true,
                className: classes.searchInput,
              }}
              onChange={(e) => {
                item.annotation = e.target.value;
                this.setState({ annotation: e.target.value });
              }}
              onBlur={() => {
                this.props.multi(item);
              }}
              value={this.state.annotation || item.annotation}
            />
            {!item.annotation && item.isEdited ? (
              <p className={classes.error}>Required</p>
            ) : ""}
          </div>
        </TableCell>
      </TableRow>
    );
  }
}

export class MultiStatusDetailTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {},
      allLogs: props.allLogs,
    };
  }

  render() {
    const { classes, onDriverSelect, } = this.props;
    const { allLogs } = this.state;
    // const isCoDriver = this.isCoDriver();

    const eventTypeItems = ["CHANGE_DUTY_STATUS", "YARD_MOVES", "PERSONAL_USE"]
    const recordStatusItems = ["ACTIVE"];

    return (
      <div>
        <Grid item xs={12} sm={12} md={6}>
          <FormControl
            className={classes.formControl}
            style={{ minWidth: 100 }}
          >
            <Select
              className={classes.font}
              defaultValue="-"
              onChange={(e) => {
                onDriverSelect(e.target.value);
                this.props.multi();
              }}
              inputProps={{
                name: "driver",
                id: "driver",
              }}
            >
              <MenuItem value="-">Select Driver</MenuItem>
              {this.props.drivers.map((item, i) => (
                <MenuItem
                  key={i}
                  value={item.id}
                  style={{ textTransform: "capitalize" }}
                >
                  {`${item.firstName} ${item.lastName}`}
                </MenuItem>
              ))}
            </Select>
            {/* {((!item.eventCode || item.eventCode === "-") && item.isEdited) ? <p className={classes.error}>Required</p> : ""} */}
          </FormControl>
        </Grid>

        {Array.isArray(allLogs) && allLogs.length > 0 ? (
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderCell}>
                  <Button size="small" variant="outlined" onClick={() => {
                    allLogs.forEach((item) => {
                      const isAllowed = recordStatusItems.includes(item.recordStatus) && eventTypeItems.includes(item.eventType);
                      if (!isAllowed || item.isLast || item.eventTime === this.props.startOfDay(item.eventTime).toISOString()) {
                        return;
                      }
                      item.isEdited = true;
                      this.props.multi(item);
                    })
                  }}> Select All</Button>
                </TableCell>
                <TableCell className={classes.dateHeaderCell}>Time</TableCell>
                <TableCell className={classes.tableHeaderCell}>Status</TableCell>
                <TableCell className={classes.tableHeaderCell}>Location</TableCell>
                <TableCell className={classes.tableHeaderCell}>Odometer</TableCell>
                {this.props.adminKey && (<TableCell className={classes.tableHeaderCell}>
                  Notes </TableCell>)}
              </TableRow>
            </TableHead>
            <TableBody>
              {allLogs.map((item, i) => (
                <ReduxTableRow
                  getDate={this.props.getDate}
                  item={item}
                  key={1000000 + i}
                  index={i}
                  classes={classes}
                  formatTime={this.props.formatTime}
                  searchLogStatus={this.props.searchLogStatus}
                  setSelectedLog={this.props.setSelectedLog}
                  adminKey={this.props.adminKey}
                  formatRawDate={this.props.formatRawDate}
                  selectedDate={this.props.selectedDate}
                  formatDateTimeUtc={this.props.formatDateTimeUtc}
                  multi={this.props.multi}
                  formatDateTimeToUtc={this.props.formatDateTimeToUtc}
                  startOfDay={this.props.startOfDay}
                />
              ))}
            </TableBody>
          </Table>
        ) : (
          <Grid item xs={12}>
            {" "}
            <Alert severity={"error"}>{"No logs to reassign!"}</Alert>
          </Grid>
        )}
      </div>
    );
  }
}

MultiStatusDetailTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MultiStatusDetailTable);

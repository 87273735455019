import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import Button from "@material-ui/core/Button";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import NotFoundTableRow from "../NotFoundTableRow";
import IconButton from "@material-ui/core/IconButton";
import CopyIcon from "@material-ui/icons/FileCopy";

const styles = theme => ({
    root: {
        width: "98%",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: theme.spacing(1),
        overflowX: "auto"
    },
    table: {
        minWidth: 700
    },
    tableHead: {
        // padding: 5,
        // minWidth: 130
    },
    tableRowTitle: {
        display: "inline-block"
        // width: 95
    },
    capitalize: {
        textTransform: "capitalize"
    },
    saveIcon: {
        color: "#009be5",
        cursor: "pointer"
    }
});

class ReduxTableRow extends React.Component {
    copyToClip = (e, url) => {
        let textField = document.createElement("textarea");
        textField.innerText = url;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        textField.remove();
    };

    render() {
        const { classes, item = {}, shareUrl } = this.props;
        const expiry = item.expiry;
        const driver = item.driver || {};
        const vehicle = item.vehicle || {};
        const acId = item.accountSerial;

        const url = `${shareUrl}/${acId}/live/${item.code}`;
        return (
            <TableRow hover>
                <TableCell>
                    <Button
                        color="primary"
                        style={{ justifyContent: "left" }}
                        onClick={e => this.props.onSelect(item)}
                    >
                        {driver.id && (
                            <div className={classes.capitalize}>
                                {`${driver.firstName} ${driver.lastName}`}
                            </div>
                        )}
                        {vehicle.id && (
                            <div className={classes.capitalize}>
                                {`${vehicle.name}`}
                            </div>
                        )}
                    </Button>
                </TableCell>
                {/* <TableCell>{`${item.type}`}</TableCell> */}
                <TableCell>{`${item.status}`}</TableCell>
                <TableCell>
                    {`${this.props.formatDate(expiry)}`}
                    {` ${this.props.formatTime(expiry)}`}
                </TableCell>
                <TableCell>
                    {url}
                    <IconButton
                        aria-label="copy"
                        onClick={e => this.copyToClip(e, url)}
                    >
                        <CopyIcon color="primary" />
                    </IconButton>
                </TableCell>
                <TableCell>
                    {`${this.props.formatDate(item.createdDate)}`}
                    {` ${this.props.formatTime(item.createdDate)}`}
                </TableCell>
            </TableRow>
        );
    }
}

export class ReduxTable extends React.Component {
    state = {
        order: "asc"
    };

    handleChangePage = (event, page) => {
        const { limit } = this.props;
        this.props.handleChange({ page, limit });
    };

    handleChangeRowsPerPage = event => {
        const limit = event.target.value;
        const { page } = this.props;
        this.props.handleChange({ page, limit });
    };

    render() {
        const { classes, items = [], count = 1000 } = this.props;
        const { page, limit } = this.props;

        return (
            <React.Fragment>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Detail</TableCell>
                            {/* <TableCell>Type</TableCell> */}
                            <TableCell>Status</TableCell>
                            <TableCell>Expiry</TableCell>
                            <TableCell>Link</TableCell>
                            <TableCell>Created</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(items) &&
                            items.map((item, i) => (
                                <ReduxTableRow
                                    item={item}
                                    key={i}
                                    classes={classes}
                                    onSelect={this.props.onSelect}
                                    shareUrl={this.props.shareUrl}
                                    formatDate={this.props.formatDate}
                                    formatTime={this.props.formatTime}
                                />
                            ))}
                    </TableBody>
                </Table>
                <NotFoundTableRow items={items} />
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
                    component="div"
                    count={count}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
            </React.Fragment>
        );
    }
}

ReduxTable.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ReduxTable);

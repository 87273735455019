export const RECORD_ORIGIN = {
    AUTOMATICALLY: "Automatic",
    EDITED_ENTERED: "Edited Entered",
    EDIT_REQUEST: "Edit Request",
    UNIDENTIFIED: "Unindentified"
};

export const RECORD_ORIGIN_TYPES = {
    AUTOMATICALLY: "AUTOMATICALLY",
    EDITED_ENTERED: "EDITED_ENTERED",
    EDIT_REQUEST: "EDIT_REQUEST",
    UNIDENTIFIED: "UNIDENTIFIED"
};

export const RECORD_STATUS = {
    ACTIVE: "Active",
    INACTIVE_DEACTIVATE: "Inactive Deactivate",
    INACTIVE_CHANGED: "Inactive changed",
    INACTIVE_CHANGE_REQUEST: "Inactive change request",
    INACTIVE_CHANGE_REJECTED: "Inactive change rejected"
};

export const RECORD_STATUS_TYPE = {
    ACTIVE: "ACTIVE",
    INACTIVE_DEACTIVATE: "INACTIVE_DEACTIVATE",
    INACTIVE_CHANGED: "INACTIVE_CHANGED",
    INACTIVE_CHANGE_REQUEST: "INACTIVE_CHANGE_REQUEST",
    INACTIVE_CHANGE_REJECTED: "INACTIVE_CHANGE_REJECTED"
};

export const EVENT_TYPE = {
    CHANGE_DUTY_STATUS: "CHANGE_DUTY_STATUS",
    INTERMEDIATE_LOG: "INTERMEDIATE_LOG",
    PERSONAL_USE_AND_YARD_MOVES: "PERSONAL_USE_AND_YARD_MOVES",
    CERTIFICATION: "CERTIFICATION",
    LOGIN_LOGOUT: "LOGIN_LOGOUT",
    POWERUP_SHUTDOWN: "POWERUP_SHUTDOWN",
    MALFUNCTION_DIAGNOSTIC: "MALFUNCTION_DIAGNOSTIC"
};

export const EVENT_CODES = {
    OFFDUTY: "Off Duty",
    BREAK: "Break",
    SLEEPER: "Sleeper",
    DRIVING: "Driving",
    ONDUTY_ND: "On Duty ND",
    CERTIFICATION: "Certification",
    CLEAR: "Clear"
};

export const EVENT_CODES_CIR = {
    OFFDUTY: "OFF",
    BREAK: "Break",
    SLEEPER: "SB",
    DRIVING: "D",
    ONDUTY_ND: "ON",
    CERTIFICATION: "Certification",
    CLEAR: "Clear"
};
export const EVENT_CODES_TYPES = {
    OFFDUTY: "OFFDUTY",
    BREAK: "BREAK",
    SLEEPER: "SLEEPER",
    DRIVING: "DRIVING",
    ONDUTY_ND: "ONDUTY_ND",
    CERTIFICATION: "CERTIFICATION"
};

export const ALL_EVENT_CODES_OPTIONS = [
    { id: "OFFDUTY", name: "Off Duty" },
    { id: "BREAK", name: "Break" },
    { id: "SLEEPER", name: "Sleeper" },
    { id: "DRIVING", name: "Driving" },
    { id: "ONDUTY_ND", name: "On Duty ND" },
    { id: "CERTIFICATION", label: "Certification" }
];

export const EVENT_CODES_OPTIONS = [
    { id: "OFFDUTY", name: "Off Duty" },
    { id: "SLEEPER", name: "Sleeper" },
    { id: "DRIVING", name: "Driving" },
    { id: "ONDUTY_ND", name: "On Duty ND" }
];

export const DIAGNOSTIC_MALFUNCTION = {
    P: "Power compliance malfunction",
    E: "Engine synchronization compliance malfunction",
    T: "Timing compliance malfunction",
    L: "Positioning compliance malfunction",
    R: "Data recording compliance malfunction",
    S: "Data transfer compliance malfunction",
    O: "Other ELD detected malfunction",
    1: "Power data diagnostic",
    2: "Engine synchronization data diagnostic",
    3: "Missing required data elements data diagnostic",
    4: "Data transfer data diagnostic",
    5: "Unidentified driving records data diagnostic",
    6: "Vin Mismatch diagnostic event"
};

export const RECORD_ORIGIN_OPTIONS = [
    { id: "AUTOMATICALLY", name: "Automatically" },
    { id: "EDITED_ENTERED", name: "Edited Entered" },
    { id: "EDIT_REQUEST", name: "Edit Request" },
    { id: "UNIDENTIFIED", name: "Unidentified" }
];

export const RECORD_STATUS_OPTIONS = [
    { id: "ACTIVE", name: "Active" },
    { id: "INACTIVE_CHANGED", name: "InActive Changes" },
    { id: "INACTIVE_DEACTIVATE", name: "InActive Deactivate" },
    { id: "INACTIVE_CHANGE_REQUEST", name: "InActive Change Request" },
    { id: "INACTIVE_CHANGE_REJECTED", name: "InActive Change Rejected" }
];

export const EVENT_TYPE_OPTIONS = [
    { id: "CHANGE_DUTY_STATUS", name: "Change Duty Status" },
    { id: "INTERMEDIATE_LOG", name: "Intermediate Log" },
    { id: "YARD_MOVES", name: "Yard Moved" },
    { id: "PERSONAL_USE", name: "Personal Use" },
    { id: "CERTIFICATION", name: "Certification" },
    { id: "LOGIN_LOGOUT", name: "LogIn LogOut" },
    { id: "POWERUP_SHUTDOWN", name: "PowerUp/ShutDown" },
    { id: "MALFUNCTION_DIAGNOSTIC", name: "Malfunction Diagnostic" }
];

export const SUB_EVENT_TYPE_OPTIONS = [
    { id: "status", name: "Status" },
    { id: "personal", name: "Personal" },
    { id: "remark", name: "Remark" },
    { id: "yardMove", name: "Yard Move" },
    { id: "location", name: "Location" },
    { id: "certification", name: "Certification" },
    { id: "logIn", name: "Log In" },
    { id: "logOut", name: "Log Out" },
    { id: "powerUp", name: "Power Up" },
    { id: "powerDown", name: "Power Down" },
];

export const COORD_CODE_OPTIONS = [
    { id: "A", name: "Automatic" },
    { id: "X", name: "Not connected to a CMV" },
    { id: "M", name: "Manual location" },
    { id: "E", name: "Malfunction" }
];

import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Formik, Form } from "formik";
import { signIn } from "../../actions/auth/authActions";
import { getTimeZone } from "../../actions/momentActions";
import { createUser } from "../../actions/adminDashActions";
import { getResellers } from "../../actions/reseller/resellerActions";
import { validate } from "./validate";
import Grid from "@material-ui/core/Grid";
import InputBox from "../../components/InputBox";
import SelectBox from "../../components/SelectBox";
import Error from "../Error";
import Success from "../Success";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import CheckBox from "../../components/CheckBox";
import Alert from "@material-ui/lab/Alert";

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 25,
    textAlign: "right",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export class AddUserForm extends React.Component {
  state = {
    resellers: [],
  };

  componentDidMount() {}

  async componentDidMount() {
    await this.getResellers();
  }

  getResellers = async () => {
    const resellers = await this.props.getResellers();
    this.setState({ resellers });
  };

  handleSubmit = async (values) => {
    if (values.resellerId) {
      values.role = "PARTNER";
    }

    let response = await this.props.createUser(values);
    if (response && response._id) {
      this.setState({
        successMessage: "User is updated successfully",
        loading: false,
      });
      setTimeout(() => {
        this.props.handleClose();
      }, 1000);
    } else if (response && response.message) {
      this.setState({ errorMessage: response.message, loading: false });
    } else {
      if (typeof response !== String) {
        response = "Something went wrong, please try after time.";
      }
      this.setState({ errorMessage: response, loading: false });
    }
  };

  render() {
    const { classes, constants } = this.props;
    const resellers = this.state.resellers;
    const { errorMessage, successMessage, loading } = this.state;
    const isSmallScreen = window.innerWidth < 500;
    return (
      <Dialog
        fullScreen={isSmallScreen}
        fullWidth={true}
        maxWidth={"md"}
        className={classes.wrapper}
        open={!!this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" onClose={this.props.handleClose}>
          <Typography>{"Add Support User"}</Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Alert severity="warning">
            {
              "Note:-  This is only for adding new support user. Support user will have access to all your account and carrier details."
            }
          </Alert>
          <Formik
            initialValues={{
              role: "ADMIN",
            }}
            validate={validate}
            onSubmit={this.handleSubmit}
            enableReinitialize={true}
          >
            {({ values, handleSubmit, setFieldValue, errors }) => {
              return (
                <Form style={{ padding: "20px 10px" }}>
                  <Grid container spacing={3}>
                    <InputBox md={4} name="firstName" label="First Name" />
                    <InputBox md={4} name="lastName" label="Last Name" />
                    <InputBox md={4} name="mobile" label="Mobile" />
                    <InputBox md={4} name="email" label="Email" />
                    <InputBox md={4} name="password" label="Password" />
                    <CheckBox md={4} name="adminAccess" label={"Super Admin"} />
                    <SelectBox
                      md={4}
                      name="resellerId"
                      label="Reseller"
                      selLabel={"name"}
                      selValue={"_id"}
                      items={resellers}
                      onChange={(e) => setFieldValue("adminAccess", false)}
                    />
                  </Grid>
                  {successMessage && <Success message={successMessage} />}
                  {errorMessage && <Error message={errorMessage} />}
                  <div className={classes.DialogActions}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={loading}
                    >
                      {loading ? "Saving..." : "SAVE"}
                    </Button>{" "}
                    <Button
                      variant="contained"
                      onClick={this.props.handleClose}
                    >
                      CLOSE
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    constants: _get(state, "appData.constants", {}),
    errorMessage: _get(state, "equipmentData.error", ""),
    loading: _get(state, "accountData.accountCreateBusy", false),
    accountValues: _get(state, "form.accountForm.values", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createUser,
      getTimeZone,
      getResellers,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AddUserForm))
);

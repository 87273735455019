import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import NotFoundTableRow from "../NotFoundTableRow";
import DutyStatus from "../../components/DutyStatus";

const styles = theme => ({
    root: {
        width: "98%",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: theme.spacing(1),
        overflowX: "auto"
    },
    table: {
        minWidth: 700
    },
    tableHead: {
        // padding: 5,
        // minWidth: 130
    },
    tableRowTitle: {
        display: "inline-block"
        // width: 95
    },
    capitalize: {
        textTransform: "capitalize"
    },
    saveIcon: {
        color: "#009be5",
        cursor: "pointer"
    }
});

class ReduxTableRow extends React.Component {
    render() {
        const { classes, item = {} } = this.props;
        const driver = item.driver;
        const vehicle = item.vehicle;

        return (
            <TableRow hover>
                <TableCell>
                    <div>{this.props.formatTime(item.eventTime)}</div>
                    {this.props.formatDate(item.eventTime)}
                </TableCell>
                <TableCell>
                    <div className={classes.capitalize}>
                        {driver && (
                            <div>
                                {driver.firstName} {driver.lastName}
                            </div>
                        )}
                    </div>
                </TableCell>
                <TableCell>
                    <DutyStatus
                        status={item}
                        eventType={item.eventType}
                        eventCode={item.eventCode}
                        annotation={item.annotation}
                        eventSubType={item.eventSubType}
                    />
                </TableCell>
                <TableCell>{vehicle ? vehicle.name : ""}</TableCell>
                <TableCell>
                    {item.annotation ? <div>{item.annotation}</div> : ""}
                    {item.reason ? <div>{item.reason}</div> : ""}
                </TableCell>
            </TableRow>
        );
    }
}

export class ReduxTable extends React.Component {
    state = {
        order: "asc"
    };

    handleChangePage = (event, page) => {
        const { limit } = this.props;
        this.props.handleChange({ page, limit });
    };

    handleChangeRowsPerPage = event => {
        const limit = event.target.value;
        const { page } = this.props;
        this.props.handleChange({ page, limit });
    };

    render() {
        const { classes, items = [], count = 1000 } = this.props;
        const { page, limit } = this.props;

        return (
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Driver</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Vehicle</TableCell>
                            <TableCell>Reason</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(items) &&
                            items.map((item, i) => (
                                <ReduxTableRow
                                    item={item}
                                    key={i}
                                    classes={classes}
                                    formatDate={this.props.formatDate}
                                    formatTime={this.props.formatTime}
                                />
                            ))}
                    </TableBody>
                </Table>
                <NotFoundTableRow items={items} />
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
                    component="div"
                    count={count}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
            </Paper>
        );
    }
}

ReduxTable.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ReduxTable);

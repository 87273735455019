import React from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";

export default function PageOne() {
  return (
    <React.Fragment>
      <Container
        maxWidth="sm"
        style={{
          backgroundColor: "white",
          height: "450px",
          textAlign: "center",
        }}
      >
        <img
          style={{ width: 300, marginTop: 25 }}
          src="/assets/images/osrit.png"
          alt="Osrit"
        />
        <Typography variant="h4">Cloud Trucking Software | TMS.</Typography>
        <Typography
          variant="subtitle1"
          style={{ marginTop: 25, marginBottom: 25 }}
        >
          Osrit Cloud Software helps you easily dispatch FTL or LTL loads,
          customers, brokers, drivers, payroll, invoicing, etc from one place !
        </Typography>
        <Typography component="span">To know more, please visit at </Typography>

        <Button color="primary" href="https://www.osrit.com/">
          www.osrit.com
        </Button>
      </Container>
    </React.Fragment>
  );
}

import React from "react";
import _get from "lodash/get";
// import moment from "moment-timezone";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { getResellerById } from "../../actions/reseller/resellerActions";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
// import Button from "@material-ui/core/Button";
// import IconButton from "@material-ui/core/IconButton";
// import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
// import CircularProgress from "@material-ui/core/CircularProgress";
// import { formatDate } from "../../actions/momentActions";

const styles = (theme) => ({
  root: {
    margin: 15,
  },
  title: {
    textAlign: "center",
    textTransform: "uppercase",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  statusButton: {
    margin: "0 2vh 0 2vh",
    float: "right",
  },
});

function RenderItem(props) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={4} md={3}>
        {props.item}
      </Grid>
      <Grid item xs={8} md={9}>
        {props.value}
      </Grid>
    </Grid>
  );
}

export class ResellerDetail extends React.Component {
  state = {
    loading: true,
    isEditOpen: false,
    dataLoading: false,
    reseller: null,
  };

  async componentDidMount() {
    await this.getResellerById();
  }

  getResellerById = async () => {
    const id = this.props.match.params.resellerId;
    const reseller = await this.props.getResellerById({ id });
    this.setState({ reseller, loading: false });
  };

  render() {
    const classes = this.props.classes;
    const reseller = this.state.reseller || {};
    const contact = reseller.contact || {};
    const billing = reseller.billing || {};
    return (
      <Card className={classes.root}>
        <CardActionArea>
          <CardContent className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12} md="12" className={classes.title}>
                <Typography gutterBottom variant="h5" component="h2">
                  {reseller.name}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" component="h4">
                  {"Detail"}
                </Typography>
                <RenderItem item="Status" value={reseller.status} />
                <RenderItem item="Type" value={reseller.bussType} />
                <RenderItem item="Created" value={reseller.created} />
                <RenderItem item="Edited" value={reseller.edited} />
                <br />
                <Typography variant="h6" component="h4">
                  {"Contact"}
                </Typography>
                <RenderItem item="Name" value={contact.name} />
                <RenderItem item="Phone" value={contact.phone} />
                <RenderItem item="Email" value={contact.email} />
                <RenderItem item="Role" value={contact.role} />
                <br />
                <Typography variant="h6" component="h4">
                  {"Billing"}
                </Typography>
                <RenderItem item="Name" value={billing.name} />
                <RenderItem item="Phone" value={billing.phone} />
                <RenderItem item="Email" value={billing.email} />
                <RenderItem item="Role" value={billing.role} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" component="h4">
                  {"Login Users"}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    accountBaseUrl: _get(state, "authData.accountBaseUrl"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getResellerById,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ResellerDetail))
);

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import SmsIcon from "@material-ui/icons/Sms";
import NotFoundTableRow from "../../NotFoundTableRow";
import { sorting } from "../../../utils/sortingHelpers";
import { Link } from "react-router-dom";

const styles = theme => ({
    root: {
        width: "98%",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: theme.spacing(1),
        overflowX: "auto"
    },
    table: {
        minWidth: 700
    },
    tableRowTitle: {
        display: "inline-block",
        width: 50
    },
    tableRow: {
        height: 30
    },
    tableCell: {
        // fontSize: 10
    },
    capitalize: {
        textTransform: "capitalize"
    },
    dl: {
        fontSize: 10,
        fontWeight: 500
    },
    EditIcon: {
        color: "#009be5",
        cursor: "pointer"
    },
    access: {
        maxWidth: 150
    }
});

class ReduxTableRow extends React.Component {
    render() {
        const { classes, item = {}, accountBaseUrl } = this.props;
        const driver = item.driver || {};

        return (
            <TableRow hover className={classes.tableRow}>
                <TableCell
                    className={`${classes.tableCell} ${classes.capitalize}`}
                >
                    {item.firstName} {item.lastName}
                    <div className={classes.dl}>
                        {driver.licenseState && (
                            <div>
                                {`Lic: `}
                                {driver.licenseNumber}
                                {` (${driver.licenseState})`}
                            </div>
                        )}
                    </div>
                </TableCell>
                <TableCell className={classes.tableCell}>
                    <div>{`Email: ${item.email}`}</div>
                    {item.mobile ? <div>{`Ph: ${item.mobile}`}</div> : null}
                </TableCell>
                <TableCell className={classes.tableCell}>
                    {item.status}
                    <div>{item.role}</div>
                </TableCell>

                <TableCell className={`${classes.tableCell} ${classes.access}`}>
                    {item.scopes && Array.isArray(item.scopes) && (
                        <div>
                            {item.scopes
                                .map(_item => ` ${_item.name}`)
                                .toString()}
                        </div>
                    )}
                </TableCell>
                <TableCell className={classes.tableCell}>
                    {/* {driver.regulation || "ELD"} */}
                    <div>{driver.cargo}</div>
                </TableCell>
                <TableCell className={classes.tableCell}>
                    {driver.driverId && item.role === "DRIVER" && (
                        <Link
                            to={`${accountBaseUrl}/status/diary/${driver.driverId
                                }`}
                        >
                            DUTY LOGS
                        </Link>
                    )}
                </TableCell>
                <TableCell className={classes.tableCell}>
                    <SmsIcon
                        className={classes.EditIcon}
                        onClick={() => this.props.handleSms(item)}
                    />{" "}
                    <EditIcon
                        className={classes.EditIcon}
                        onClick={() => this.props.handleEdit(item)}
                    />
                </TableCell>
            </TableRow>
        );
    }
}

export class ReduxTable extends React.Component {
    state = {
        order: "asc"
    };

    handleChangePage = (event, page) => {
        const { limit } = this.props;
        this.props.handleChange({ limit, page });
    };

    handleChangeRowsPerPage = event => {
        const limit = event.target.value;
        const { page } = this.props;
        this.props.handleChange({ limit, page });
    };

    handleSort = field => {
        const { page, limit } = this.props.pagination;
        const order = this.state.order === "desc" ? "asc" : "desc";

        this.setState({ order });
        this.props.handleChange({ page, limit, order: `${field} ${order}` });
    };

    render() {
        const { classes, items = [], accountBaseUrl } = this.props;
        const { page, limit, count } = this.props;
        const itemArray = sorting(items, "firstName");

        return (
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Contact</TableCell>
                            <TableCell>Roles</TableCell>
                            <TableCell>Access</TableCell>
                            <TableCell >Cargo</TableCell>
                            <TableCell>HOS</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(itemArray) &&
                            itemArray.map((item, i) => (
                                <ReduxTableRow
                                    item={item}
                                    key={i}
                                    classes={classes}
                                    handleSms={this.props.handleSms}
                                    handleEdit={this.props.handleEdit}
                                    formatDate={this.props.formatDate}
                                    viewDetails={this.props.viewDetails}
                                    accountBaseUrl={accountBaseUrl}
                                />
                            ))}
                    </TableBody>
                </Table>
                <NotFoundTableRow items={items} />
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={count}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
            </Paper>
        );
    }
}

ReduxTable.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ReduxTable);

import React from "react";
import _get from "lodash/get";
import html2pdf from "html2pdf.js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { getDriverSignById } from "../../actions/driverActions";
import {
  formatDateTime,
  formatDate,
  formatTime,
} from "../../actions/momentActions";
import { pdfOptions } from "../../helpers/helpers";

const styles = (theme) => ({
  wrapper: {},
  table: { border: "1px solid #ccc", marginBottom: 25 },
  DialogActions: {
    marginTop: 40,
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export class DvirDialog extends React.Component {
  state = {
    email: "",
    carrier: null,
    successMessage: "",
    errorMessage: "",
    loading: false,
  };

  componentDidMount() {
    this.loadSign();
  }

  loadSign = async (e) => {
    const driverId = _get(this, "props.selected.driverId");
    try {
      const response = await this.props.getDriverSignById({ driverId });
      if (response.carrier) {
        const signature = _get(response, "sign.data");
        this.setState({ carrier: response.carrier, signature });
      }
    } catch (e) {
      console.log("e :::: ", e);
    }
  };

  createPdf = async () => {
    try {
      this.setState({ loading: true });
      const vehicleName = _get(this, "props.selected.vehicleName");
      const insType = _get(this, "props.selected.inspectionType");
      const _startTime = _get(this, "props.selected.startTime");
      const startTime = this.props.formatDateTime(_startTime);
      const fileName = `dvir-${vehicleName}-${insType}-${startTime}.${"pdf"}`.replace(
        / /g,
        ""
      );

      const element = document.getElementById("dvirPdf");

      pdfOptions.filename = fileName;
      pdfOptions.margin = [0.3, 0.1];
      await html2pdf().set(pdfOptions).from(element).toPdf().save();
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false });
      console.log(e);
    }
  };

  render() {
    const { classes } = this.props;
    const { errorMessage, successMessage, loading } = this.state;
    const signature = this.state.signature;
    const carrier = this.state.carrier || {};
    const selected = this.props.selected || {};
    const areas = selected.areas || [];
    const templateId = selected.templateId || "Tractor";
    const vehicleType = templateId.toUpperCase().includes("TRACTOR")
      ? "Tractor"
      : "Trailer";
    // console.log("signature :: ", signature);
    return (
      <Dialog
        fullScreen
        className={classes.wrapper}
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" onClose={this.props.onClose}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              {"DVIR"}
            </Grid>
            <Grid item xs={6} md={6}>
              <div style={{ textAlign: "right", padding: 0 }}>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={this.createPdf}
                  disabled={loading}
                >
                  {loading ? "Downloading..." : "DOWNLOAD"}
                </Button>{" "}
                <Button
                  size="small"
                  color="secondary"
                  variant="contained"
                  onClick={this.props.onClose}
                >
                  {"CLOSE"}
                </Button>
              </div>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <div style={{ maxWidth: 700, margin: "auto" }} id={"dvirPdf"}>
            <h2 style={{ textAlign: "center" }}>{carrier.name}</h2>
            <h3 style={{ textAlign: "center" }}>
              DRIVER'S VEHICLE INSPECTION REPORT (DVIR)
            </h3>
            <h5 style={{ textAlign: "center" }}>
              AS REQUIRED BY THE D.O.T. FEDERAL MOTOR CARRIER SAFETY REGULATIONS
            </h5>
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell>
                      DVIR Number:- {`${selected.dvirSerial}`}
                    </TableCell>
                    <TableCell>Location:- {`${selected.location}`}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Carrier:- {carrier.name}</TableCell>
                    <TableCell>
                      Date:- {`${this.props.formatDate(selected.startTime)}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>DOT:- {carrier.dot}</TableCell>
                    <TableCell>Odometer:- {`${selected.odometer}`}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Driver:- {`${selected.driverName}`}</TableCell>
                    <TableCell style={{ textTransform: "capitalize" }}>
                      {`${vehicleType}`}:- {`${selected.vehicleName}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Start Inspection:-{" "}
                      {`${this.props.formatTime(selected.startTime)}`}
                    </TableCell>
                    <TableCell>
                      End Inspection :-{" "}
                      {`${this.props.formatTime(selected.endTime)}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ textTransform: "capitalize" }}>
                      Inspection Type:- {`${selected.inspectionType}`}
                    </TableCell>
                    <TableCell style={{ textTransform: "capitalize" }}>
                      Inspector:- {`${selected.inspectorType}`}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Inspection Areas</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell style={{ maxWidth: 100 }}>Note</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {areas.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>
                        {row.value === "1" ? "PASS" : "FAIL"}
                      </TableCell>
                      <TableCell style={{ maxWidth: 100 }}>
                        {row.note}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ textAlign: "center" }}>
              <h5>
                I declare that this asset has been inspected in accordance with
                the applicable requirements.
              </h5>
              <Grid container spacing={0}>
                <Grid item xs={12} md={12}>
                  <img
                    src={`data:image/jpeg;base64,${signature}`}
                    style={{ width: 120 }}
                    alt="Signature"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <h5>Driver Signature</h5>
                </Grid>
              </Grid>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: _get(state, "authData.termsBusy", false),
    constants: _get(state, "appData.constants", {}),
    errorMessage: _get(state, "authData.errorMessage", ""),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDriverSignById,
      formatDateTime,
      formatTime,
      formatDate,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DvirDialog))
);

import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import UserAddEditForm from "./Forms/UserAddEdit";
import SmsForm from "./Forms/SmsForm";
import {
    getUsers,
    getUserCount,
    selectedUser,
    getScopes,
    updateUserScope
} from "../../../actions/userActions";
import ReduxButton from "../../../components/ReduxButton";
import SearchInput from "../../../components/SearchInput";
import UserTable from "./UserTable";
import { formatDate } from "../../../actions/momentActions";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = theme => ({
    addButtonWrapper: {
        width: "99%",
        textAlign: "right",
        marginTop: 10
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: "100px",
        textAlign: "left"
    },
    grow: {
        flexGrow: 1,
        // textAlign: "center",
        float: "left",
        marginTop: "10px",
        marginBottom: "10px",

    },
    loader: {
        flexGrow: 1,
        textAlign: "center",
        marginTop: "20px",
    }
});

export class User extends React.Component {
    state = {
        open: false,
        selTab: 0,
        page: 0,
        limit: 10,
        searching: false,
        showAll: false
    };

    async componentDidMount() {
        await this.getUsers();
        await this.getUserCount();
        await this.getScopes();
    }

    getUserCount = async () => {
        await this.props.getUserCount();
    };

    getScopes = async () => {
        const scopes = await this.props.getScopes();
        this.setState({ scopes });
    };

    getUsers = async () => {
        const { page, limit, query, showAll } = this.state;
        const skip = parseInt(page) * parseInt(limit);
        const payload = {
            limit,
            skip,
            query
        };
        if (!showAll) {
            payload.status = "ACTIVE";
        }

        this.setState({ searching: true });
        await this.props.getUsers(payload);
        this.setState({ searching: false });
    };

    handleClose = async () => {
        this.setState({ isAdd: false, selected: null });
        await this.getUserCount();
        await this.getUsers();
    };

    handleAddClose = async () => {
        this.setState({ isAdd: false, selected: null }, async () => {
            await this.getUserCount();
            await this.getUsers();
        })
    };

    handleEdit = selected => {
        this.props.selectedUser(selected);
        this.setState({ isAdd: true, isEdit: true, selected });
    };

    handleSms = selected => {
        this.setState({ openSms: true, selected });
    };

    handleChange = ({ limit, page, order }) => {
        this.setState({ limit, page, order, query: "" }, this.getUsers);
    };

    onSearch = query => {
        const { page, limit, order } = this.state;
        this.setState({ limit, page, order, query }, this.getUsers);
    };

    viewDetails = driver => {
        const { accountBaseUrl } = this.props;
        this.props.history.push(
            `${accountBaseUrl}/settings/user/${driver.driverId}`
        );
    };

    switchChange = () => {
        const { showAll } = this.state;
        this.setState({ showAll: !showAll }, () => {
            this.getUsers()
        })
    }
    render() {
        const { classes, userBusy } = this.props;
        const { isAdd, isEdit, openSms, selected, showAll } = this.state;

        return (
            <div>
                {isAdd && (
                    <UserAddEditForm
                        open={isAdd}
                        isEdit={isEdit}
                        selected={selected}
                        scopes={this.state.scopes}
                        isAdmin={this.props.isAdmin}
                        handleClose={this.handleAddClose}
                        updateUserScope={this.props.updateUserScope}
                    />
                )}

                <AppBar position="static" color="default">
                    <Toolbar>
                        <div className={classes.grow}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={showAll}
                                        onChange={this.switchChange}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Show all"
                            />
                        </div>
                        <div style={{ float: "right" }}>
                            <SearchInput
                                onChange={this.onSearch}
                                query={this.state.query}
                            />
                        </div>
                        <div>
                            <ReduxButton
                                width
                                onClick={() => {
                                    this.setState({
                                        isAdd: true,
                                        isEdit: false,
                                        selected: null
                                    });
                                }}
                                label={"ADD"}
                            />
                        </div>
                    </Toolbar>
                </AppBar>
                {
                    userBusy ? <div className={classes.loader}>
                        <CircularProgress />
                    </div> : <>
                        {openSms && (
                            <SmsForm
                                open={openSms}
                                selected={selected}
                                handleClose={() => this.setState({ openSms: false })}
                            />
                        )}
                        <UserTable
                            items={this.props.users}
                            limit={this.state.limit}
                            page={this.state.page}
                            count={this.props.count}
                            formatDate={this.props.formatDate}
                            handleEdit={this.handleEdit}
                            handleSms={this.handleSms}
                            handleChange={this.handleChange}
                            viewDetails={this.viewDetails}
                            accountBaseUrl={this.props.accountBaseUrl}
                        />
                    </>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userType: "ALL",
        count: _get(state, "userData.count", 0),
        users: _get(state, "userData.users", []),
        userBusy: _get(state, "userData.userBusy", false),
        accountBaseUrl: _get(state, "authData.accountBaseUrl"),
        isAdmin: !!_get(state, "authData.profile.adminId")
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getUsers,
            getUserCount,
            selectedUser,
            getScopes,
            formatDate,
            updateUserScope
        },
        dispatch
    );

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(User)
);

import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import {
    getAccountProperties,
    updateAccountProperties
} from "../../actions/app/appActions";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import SettingEditReasonDetail from "./SettingEditReasonDetail";

const styles = theme => ({
    root: {
        padding: theme.spacing(1),
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(1),
        minHeight: 200,
        // overflow: "scroll"
    },
    grow: {
        flexGrow: 1,
        textAlign: "center"
    }
});

export class SettingEditReason extends React.Component {
    async componentDidMount() {
        await this.props.getAccountProperties();
    }

    render() {
        const { classes, accountBusy, accountUpdateBusy } = this.props;
        const accountSettings = _get(this, "props.accountSettings", {});
        const editReasons = _get(this, "props.accountSettings.editReasons", []);
        const accountSettingsId = _get(this, "props.accountSettings._id");

        return (
            <div className={classes.root}>
                <Grid>
                    <Grid item xs={12}>
                        <div className={classes.grow}>
                            {accountUpdateBusy && <CircularProgress />}
                        </div>
                        {!accountBusy && editReasons.length && (
                            <SettingEditReasonDetail
                                accountSettings={accountSettings}
                                accountUpdateBusy={accountUpdateBusy}
                                editReasons={editReasons}
                                accountSettingsId={accountSettingsId}
                                getAccountProperties={
                                    this.props.getAccountProperties
                                }
                                updateAccountProperties={
                                    this.props.updateAccountProperties
                                }
                            />
                        )}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        accountBusy: _get(state, "appData.accountBusy", false),
        accountUpdateBusy: _get(state, "appData.accountUpdateBusy", false),
        accountSettings: _get(state, "appData.accountSettings", {})
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getAccountProperties,
            updateAccountProperties
        },
        dispatch
    );

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SettingEditReason)
);

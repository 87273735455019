import { React, useState } from "react";
import _get from "lodash/get";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import CircularProgress from "@material-ui/core/CircularProgress";
// import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { syncRedis } from "../../actions/adminDashActions"
import { Button } from "@material-ui/core";

const styles = theme => ({
    paper: {
        margin: 15,
        overflow: "hidden"
    },
    searchBar: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    searchInput: {
        fontSize: theme.typography.fontSize
    },
    block: {
        display: "block"
    },
    addUser: {
        marginRight: theme.spacing(1)
    },
    contentWrapper: {
        margin: "40px 16px"
    }
});

function Content(props) {
    const { classes } = props;
    const [loading, setLoading] = useState(false);

    async function getSyncRedis() {
        setLoading(true)
        await props.syncRedis();
        setLoading(false)
    }

    const adminRoles = _get(props, "adminScope", []);
    return (
        <>
            {adminRoles && adminRoles.includes("ADMIN_ALL") &&
                (
                    <Paper className={classes.paper}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs style={{ margin: "50px" }}>
                                <Button color="primary"
                                    variant="contained"
                                    disabled={loading}
                                    onClick={getSyncRedis}>
                                    {loading ? "SYNCING REALTIME" : "SYNC REALTIME"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                )}
        </>
    );
}

const mapStateToProps = state => {
    return {
        classes: PropTypes.object.isRequired,
        adminScope: _get(state, "authData.profile.scopes"),

    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            syncRedis
        },
        dispatch
    );

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Content)
);

import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Formik, Form } from "formik";
import { signIn } from "../../actions/auth/authActions";
import { getTimeZone } from "../../actions/momentActions";
import { updateUser } from "../../actions/adminDashActions";
import { validate, validateEdit } from "./validate";
import Grid from "@material-ui/core/Grid";
import InputBox from "../../components/InputBox";
import SelectBox from "../../components/SelectBox";
import Error from "../Error";
import Success from "../Success";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import CheckBox from "../../components/CheckBox";

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 25,
    textAlign: "right",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export class AddUserForm extends React.Component {
  state = {};

  componentDidMount() {}

  handleSubmit = async (values) => {
    let response = await this.props.updateUser(values);
    if (response && response._id) {
      this.setState({
        successMessage: "User is updated successfully",
        loading: false,
      });
      setTimeout(() => {
        this.props.handleClose();
      }, 1000);
    } else if (response && response.message) {
      this.setState({ errorMessage: response.message, loading: false });
    } else {
      if (typeof response !== String) {
        response = "Something went wrong, please try after time.";
      }
      this.setState({ errorMessage: response, loading: false });
    }
  };

  render() {
    const { classes, constants } = this.props;
    const selected = this.props.selected || {};
    const { errorMessage, successMessage, loading } = this.state;
    const isSmallScreen = window.innerWidth < 500;

    return (
      <div>
        <Formik
          initialValues={selected}
          validate={validateEdit}
          onSubmit={this.handleSubmit}
          enableReinitialize={true}
        >
          {({ values, handleSubmit, setFieldValue, errors }) => {
            return (
              <Form style={{ padding: "20px 10px" }}>
                <Grid container spacing={3}>
                  <InputBox md={4} name="firstName" label="First Name" />
                  <InputBox md={4} name="lastName" label="Last Name" />
                  <InputBox md={4} name="mobile" label="Mobile" />
                  <InputBox md={4} name="email" label="Email" />
                  <InputBox md={4} name="password" label="Password" />
                  <CheckBox md={4} name="adminAccess" label={"Super Admin"} />
                </Grid>
                {successMessage && <Success message={successMessage} />}
                {errorMessage && <Error message={errorMessage} />}
                <Grid
                  item
                  md={12}
                  xs={12}
                  style={{
                    marginTop: 15,
                    textAlign: "center",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                  >
                    {loading ? "Saving..." : "SAVE"}
                  </Button>{" "}
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    constants: _get(state, "appData.constants", {}),
    errorMessage: _get(state, "equipmentData.error", ""),
    loading: _get(state, "accountData.accountCreateBusy", false),
    accountValues: _get(state, "form.accountForm.values", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUser,
      getTimeZone,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AddUserForm))
);

import React from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  root: {
    width: "98%",
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: theme.spacing(1.5),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  capitalize: {
    textTransform: "capitalize",
  },
  icon: {
    color: "#009be5",
    fontSize: 16,
    marginBottom: -2,
    width: 25,
  },
  dataSmallRow: {
    minWidth: 90,
  },
});

class ReduxTableRow extends React.Component {
  render() {
    const { adminAccess, classes } = this.props;
    let _item = _get(this.props, "item", {});

    return (
      <TableRow hover>
        <TableCell component="th" scope="row">
          <div className={classes.dataSmallRow}>{_item.email || ""}</div>
        </TableCell>
        <TableCell>
          <div className={classes.dataSmallRow}>{_item.error}</div>
        </TableCell>
        <TableCell>
          <div className={classes.dataSmallRow}>
            {this.props.formatDateTime(_item.time)}
          </div>
        </TableCell>
        {adminAccess && <TableCell>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => this.props.onDelete(_item)}
          >
            {"Delete"}
          </Button>
        </TableCell>}
      </TableRow>
    );
  }
}

export class auditTable extends React.Component {
  state = { open: false, selectedAccount: null, order: "asc" };

  render() {
    const { eld, adminAccess, classes } = this.props;
    const items = this.props.items || {};
    const _allItems = Object.keys(items);
    const allItems = _allItems.map((_item) => {
      const _data = items[_item];
      return {
        id: _item,
        ..._data[0],
      };
    });
    // let allItems = []
    // Object.values(items).forEach(e => {
    //     if (Array.isArray(e)) {
    //         allItems = [...allItems, ...e]
    //     } else {
    //         allItems = [...allItems, e]
    //     }
    // })
    return (
      <React.Fragment>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Error</TableCell>
                <TableCell>Time</TableCell>
                {adminAccess && <TableCell></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {allItems.map((_item, i) => (
                <ReduxTableRow
                  item={_item}
                  key={i}
                  eld={eld}
                  classes={classes}
                  adminAccess={adminAccess}
                  onDelete={this.props.onDelete}
                  formatDateTime={this.props.formatDateTime}
                />
              ))}
            </TableBody>
          </Table>
          {/* <NotFoundTableRow items={items} /> */}
          {/* <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
                        component="div"
                        count={count}
                        rowsPerPage={limit}
                        page={page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    /> */}
        </Paper>
      </React.Fragment>
    );
  }
}

auditTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(auditTable);

import React from "react";
import _get from "lodash/get";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RoleForm from "./RoleForm";
import EditUserForm from "./EditUserForm";
import { getAdminScopes } from "../../actions/adminDashActions";

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  AlignCenter: {
    textAlign: "center",
    display: "flex",
    justifyContent: "space-evenly",
  },
  AlignBtn: {
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  agriExm: {
    border: "1px solid grey",
    textTransform: "uppercase",
    flexBasis: "25%",
    flexShrink: 0,
    padding: 4,
    backgroundColor: "white",
    textAlign: "center",
    color: "black",
    display: "inline-block",
    width: 110,
    borderRadius: 5,
    fontWeight: 500,
  },
});

export function formatScopes(props) {
  const scopes = props.scopes || [];
  let all = {};

  for (let i = 0; i < scopes.length; i++) {
    const item = scopes[i];
    const name = item.name;
    const keyName = name.split("_")[1];
    all[keyName] = all[keyName] || {};
    all[keyName].items = all[keyName].items || [];
    all[keyName].items.push(item);
  }

  return Object.keys(all).map((item) => {
    return {
      name: item,
      data: all[item].items,
    };
  });
}

export class ConfirmActionModal extends React.Component {
  state = {
    loading: false,
    message: "",
  };

  async componentDidMount() {
    await this.getScopes();
  }

  getScopes = async () => {
    const _scopes = await this.props.getAdminScopes();
    if (_scopes) {
      const scopes = formatScopes({
        selected: this.props.selected,
        scopes: _scopes,
      });
      this.setState({ scopes });
    }
  };

  render() {
    const { classes } = this.props;
    const isSmallScreen = window.innerWidth < 500;
    const isAdmin = _get(this, "props.selected.role") === "ADMIN";
    return (
      <Dialog
        fullScreen={isSmallScreen}
        fullWidth={true}
        maxWidth={"xl"}
        className={classes.wrapper}
        open={!!this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" onClose={this.props.handleClose}>
          <Typography>{"Edit User"}</Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {isAdmin && (
            <EditUserForm
              selected={this.props.selected}
              allScopes={this.state.scopes}
              handleClose={this.props.handleClose}
            />
          )}
          {isAdmin && <Typography>{"Edit Access"}</Typography>}
          <RoleForm
            selected={this.props.selected}
            allScopes={this.state.scopes}
            handleClose={this.props.handleClose}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    constants: _get(state, "appData.constants", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getAdminScopes }, dispatch);

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmActionModal))
);

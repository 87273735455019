import _get from "lodash/get";
import * as TYPES from "../auth/authActionTypes";
const defaultState = {
  appBusy: false,
  preCheck: true,
  isAdmin: false,
  isDriver: false,
  isManager: false,
  termsBusy: false,
  needAutorization: false,
  accountBaseUrl: "",
};

export default function auth(state = defaultState, action) {
  switch (action.type) {
    case "PROFILE":
      const roles = _get(action, "value.roles", []);
      const scopes = _get(action, "value.scopes", []);
      const adminId = _get(action, "value.adminId");
      const superAdminAccess = _get(action, "value.adminAccess");
      const isAdmin = roles.includes("ADMIN");
      const isManager = roles.includes("MANAGER");
      const isManagerSor = isAdmin && adminId;
      const isDriver = roles.includes("DRIVER");
      const isAdminDs =
        (superAdminAccess &&
          roles.includes("ADMIN") &&
          scopes.includes("ADMIN_DS_ALL")) ||
        false;
      const isAdminSupport =
        roles.includes("ADMIN") && scopes.includes("ADMIN_SP_ALL");
      const isAdminReseller =
        roles.includes("ADMIN") && scopes.includes("ADMIN_RS_ALL");
      const accountId = `${_get(action, "value.user.accountId")}`;
      const driverId = `${_get(action, "value.user.driverId")}`;
      const adminAccess = _get(action, "value.user.adminAccess", false);

      return {
        ...state,
        isAdmin,
        isManager,
        isManagerSor,
        isDriver,
        profile: action.value,
        driverId,
        accountId,
        adminAccess,
        isAdminSupport,
        isAdminReseller,
        isAdminDs,
        needAutorization: false,
        accountBaseUrl: `/dashboard/${accountId}`,
      };
    case "SIGNIN_ERROR":
      return {
        ...state,
        signInError: action.value,
      };
    case "NEED_AUTORIZATION":
      return {
        ...state,
        needAutorization: action.value,
      };
    case TYPES.TERMS_BUSY:
      return {
        ...state,
        termsBusy: action.value,
      };
    default:
      return state;
  }
}

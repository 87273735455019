import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { Formik, Form } from "formik";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InputDateBox from "../../../components/InputDateBox";
import SelectBox from "../../../components/SelectBox";
import { validate } from "./validate";
import Error from "../../Error";
import Success from "../../Success";
import { addDay, formatDateTimeUtc } from "../../../actions/momentActions";
import { ALL_STATUS_OPTIONS } from "../../../constants";
import { getDrivers } from "../../../actions/driverActions";
import { getCarriers } from "../../../actions/carrierActions";
import { getEquipments } from "../../../actions/equipmentActions";
import {
    createLiveShares,
    updateLiveShares,
    getLiveSharesById
} from "../../../actions/liveShareActions";

const styles = theme => ({
    wrapper: {},
    DialogActions: {
        marginTop: 20
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    wrapperButton: {
        padding: theme.spacing(2),
        textAlign: "center"
    }
});

export class AddModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errorMessage: "",
            successMessage: ""
        };
    }

    componentDidMount() {
        this.props.getDrivers({ skip: 0, limit: 250 });
        this.props.getEquipments({ skip: 0, limit: 250 });
        this.props.getCarriers();
    }

    getCarrier = carrierId => {
        const carriers = this.props.carriers || [];
        const sel = carriers.find(item => item.id === carrierId) || {};
        return {
            name: sel.name,
            dot: sel.dot,
            city: sel.city,
            state: sel.state,
            id: sel.id
        };
    };

    handleSubmit = async values => {
        let response;

        if (values.driverId) {
            const drivers = this.props.drivers || [];
            const sel = drivers.find(item => item.id === values.driverId) || {};
            values.carrier = this.getCarrier(sel.carrierId);
            values.driver = {
                firstName: sel.firstName,
                lastName: sel.lastName,
                id: sel.id
            };
        }
        if (values.vehicleId) {
            const equipments = this.props.equipments || [];
            const sel =
                equipments.find(item => item.id === values.vehicleId) || {};
            values.carrier = this.getCarrier(sel.carrierId);
            values.vehicle = {
                name: sel.name,
                id: sel.id
            };
        }

        this.setState({ errorMessage: "", successMessage: "", loading: true });

        if (values._id) {
            response = await this.props.updateLiveShares(values);
        } else {
            response = await this.props.createLiveShares(values);
        }

        if (response && response.status) {
            this.setState({
                successMessage: "Saved successfully.",
                loading: false
            });
            setTimeout(() => this.props.onDone(), 1000);
        } else {
            this.setState({
                errorMessage:
                    "Something went wrong, please try after sometime.",
                loading: false
            });
        }
    };

    render() {
        const { classes, accountSerial } = this.props;
        const selected = this.props.selected || {};
        const _drivers = this.props.drivers || [];
        const _equipments = this.props.equipments || [];
        const { errorMessage, successMessage, loading } = this.state;
        const isSmallScreen = window.innerWidth < 400;
        const drivers = _drivers.filter(item => item.status === "ACTIVE");
        const initialValues = selected._id
            ? {
                ...selected,
                driverId: selected.driver && selected.driver.id,
                carrierId: selected.carrier && selected.carrier.id,
                vehicleId: selected.vehicle && selected.vehicle.id
            }
            : {
                accountSerial,
                status: "ACTIVE",
                driverId: "",
                vehicleId: "",
                expiry: this.props.formatDateTimeUtc(
                    this.props.addDay(new Date(), 7)
                )
            };

        const equipments = _equipments.filter(
            item => item.active && item.assetType === "VEHICLE"
        );

        return (
            <Dialog
                fullScreen={isSmallScreen}
                fullWidth={true}
                maxWidth={"md"}
                className={classes.wrapper}
                open={!!this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    onClose={this.props.handleClose}
                >
                    <Typography>{"Live Share"}</Typography>
                    <IconButton
                        aria-label="Close"
                        className={classes.closeButton}
                        onClick={this.props.handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <Formik
                        initialValues={initialValues}
                        validate={validate}
                        onSubmit={this.handleSubmit}
                        enableReinitialize={true}
                    >
                        {({ values, handleSubmit, setFieldValue }) => {
                            return (
                                <Form style={{ padding: "20px 10px" }}>
                                    <Grid container spacing={3}>
                                        <SelectBox
                                            md={3}
                                            name="driverId"
                                            label="Driver"
                                            items={drivers}
                                            selLabel={"firstName"}
                                            selLabelTwo={"lastName"}
                                            onChange={() =>
                                                setFieldValue("vehicleId", "")
                                            }
                                        />
                                        <SelectBox
                                            md={3}
                                            name="vehicleId"
                                            label="Vehicle"
                                            items={equipments}
                                            onChange={() =>
                                                setFieldValue("driverId", "")
                                            }
                                        />
                                        <SelectBox
                                            md={2}
                                            name="status"
                                            label="Status"
                                            items={ALL_STATUS_OPTIONS}
                                        />
                                        <InputDateBox
                                            md={4}
                                            name="expiry"
                                            label="Expiry"
                                        />
                                    </Grid>
                                    {successMessage && (
                                        <Success message={successMessage} />
                                    )}
                                    {errorMessage && (
                                        <Error message={errorMessage} />
                                    )}
                                    <DialogActions
                                        className={classes.DialogActions}
                                    >
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={loading}
                                        >
                                            {loading ? "Saving..." : "SAVE"}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={this.props.handleClose}
                                        >
                                            CLOSE
                                        </Button>
                                    </DialogActions>
                                </Form>
                            );
                        }}
                    </Formik>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        carrier: _get(state, "authData.profile.carrier", {}),
        drivers: _get(state, "driverData.drivers", []),
        carriers: _get(state, "carrierData.carriers", []),
        equipments: _get(state, "equipmentData.equipments", []),
        accountSerial: _get(state, "authData.profile.account.accountSerial")
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            addDay,
            formatDateTimeUtc,
            getDrivers,
            getCarriers,
            getEquipments,
            createLiveShares,
            updateLiveShares,
            getLiveSharesById
        },
        dispatch
    );

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AddModal)
);

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
// import PersonIcon from "@material-ui/icons/Person";
// import PhoneIcon from "@material-ui/icons/LocalPhone";
// import EmailIcon from "@material-ui/icons/Email";
import NotFoundTableRow from "../../NotFoundTableRow";

const styles = theme => ({
    root: {
        width: "98%",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: theme.spacing(1),
        overflowX: "auto"
    },
    table: {
        minWidth: 700
    },
    capitalize: {
        textTransform: "capitalize"
    },
    icon: {
        color: "#009be5",
        fontSize: 16,
        marginBottom: -2,
        width: 25
    },
    title: {
        fontWeight: 600,
        margin: "10px auto 5px"
    },
    EditIcon: {
        color: "#009be5",
        cursor: "pointer"
    },
    dataXLargeRow: {
        minWidth: 200
    },
    dataLargeRow: {
        minWidth: 175
    },
    dataMedRow: {
        minWidth: 125
    },
    dataSmallRow: {
        minWidth: 100
    }
});

class ReduxTableRow extends React.Component {
    render() {
        const { classes, item = {} } = this.props;
        return (
            <TableRow hover>
                <TableCell component="th" scope="row">
                    <div
                        className={`${classes.dataLargeRow} ${classes.capitalize}`}
                        onClick={e => this.props.handleEdit(item)}
                    >
                        <strong>{item.name}</strong>
                    </div>
                </TableCell>
                <TableCell>{item.active ? "ACTIVE" : "IN ACTIVE"}</TableCell>
                <TableCell>{item.timezone}</TableCell>
                <TableCell>
                    <div className={classes.dataLargeRow}>
                        {item.line1 && (
                            <div className={classes.capitalize}>
                                {item.line1}
                            </div>
                        )}
                        {item.line1 && (
                            <div className={classes.capitalize}>
                                {item.city}, {item.state}-{item.zip}
                            </div>
                        )}
                    </div>
                </TableCell>
                {/*<TableCell>
                    <div className={classes.dataSmallRow}>
                        {item.created && (
                            <div>
                                Created: {this.props.formatDate(item.created)}
                            </div>
                        )}
                        {item.edited && (
                            <div>
                                Edited: {this.props.formatDate(item.edited)}
                            </div>
                        )}
                    </div>
                </TableCell>*/}
                <TableCell>
                    <EditIcon
                        className={classes.EditIcon}
                        onClick={e => this.props.handleEdit(item)}
                    />
                </TableCell>
            </TableRow>
        );
    }
}

export class TerminalTable extends React.Component {
    render() {
        const { eld, classes, items = [], handleEdit } = this.props;

        return (
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Terminal</TableCell>
                            <TableCell>Address</TableCell>
                            {/*<TableCell>Created/Edited</TableCell>*/}
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(items) &&
                            items.map((item, i) => (
                                <ReduxTableRow
                                    item={item}
                                    key={i}
                                    eld={eld}
                                    classes={classes}
                                    handleEdit={handleEdit}
                                    formatDate={this.props.formatDate}
                                />
                            ))}
                    </TableBody>
                </Table>
                <NotFoundTableRow items={items} />
            </Paper>
        );
    }
}

TerminalTable.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TerminalTable);

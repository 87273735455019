import React from "react";
import _get from "lodash/get";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getAccountAuth } from "../../actions/account/accountAdminActions";
import { getProfile } from "../../actions/auth/authActions";
import { updateUserAccount } from "../../actions/account/accountAdminActions";
import { getAccountDetails } from "../../actions/account/accountAdminActions";
import { getResellerById } from "../../actions/reseller/resellerActions";
import UpdateButton from "./UpdateButton";
import { formatDate } from "../../actions/momentActions";
import { syncRedis } from "../../actions/adminDashActions";
import EditPlanForm from "./Forms/EditPlanForm";
import AddUserForm from "./Forms/AddUserForm";
import * as cookie from "react-cookie";

const cookies = new cookie.Cookies();
const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  statusButton: {
    margin: "0 2vh 0 2vh",
    float: "right",
  },
});

function RenderUsers({
  user = {},
  handleLogin,
  accountAuthBusy,
  updateUserAccount,
  getAccountDetails,
  loading,
}) {
  const status = _get(user, "status", "");
  let active = false;
  if (status === "ACTIVE") {
    active = true;
  } else {
    active = false;
  }

  return (
    <Grid item xs={12} md={4}>
      <Paper style={{ marginTop: 10, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <p
              style={{
                fontWeight: "500",
                fontSize: "15px",
                margin: "0px",
              }}
            >
              {user.firstName} {user.lastName}
            </p>
            <div>
              <small>{user.email}</small>
            </div>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={accountAuthBusy || loading || !active}
              style={{ margin: "2vh", float: "right" }}
              onClick={(e) => handleLogin(user)}
            >
              Log In
            </Button>
            <UpdateButton
              active={active}
              accountAuthBusy={accountAuthBusy}
              user={user}
              updateUserAccount={updateUserAccount}
              getAccountDetails={getAccountDetails}
            />
          </Grid>
          <Grid item xs={12} />
        </Grid>
      </Paper>
    </Grid>
  );
}

function RenderDetails(props = {}) {
  const userActive = props.userActive;
  const userInActive = props.userInActive;

  const driverActive = props.driverActive;
  const driverInActive = props.driverInActive;

  const eqpActive = props.eqpActive;
  const eqpInActive = props.eqpInActive;
  const eqpAllowed = props.eqpAllowed;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <strong>{`Details`}</strong>
      </Grid>
      <Grid item xs={4}>
        <span
          style={{ display: "block" }}
        >{`Active Managers: ${userActive}`}</span>
        <span>{`Inactive Managers: ${userInActive}`}</span>
      </Grid>

      <Grid item xs={4}>
        <span
          style={{ display: "block" }}
        >{`Active Drivers: ${driverActive}`}</span>
        <span>{`Inactive Drivers: ${driverInActive}`}</span>
      </Grid>
      <Grid item xs={4}>
        <span>{`Allowed Vehicles: ${eqpAllowed}`}</span>
        <span
          style={{ display: "block" }}
        >{`Active  Vehicles: ${eqpActive}`}</span>
        <span
          style={{ display: "block" }}
        >{`Inactive  Vehicles: ${eqpInActive}`}</span>
      </Grid>
    </Grid>
  );
}

function RenderPlan(props) {
  const plan = props.plan || {};
  const dues = _get(plan, "dues", []);
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <h3 style={{ margin: "0px" }}>
          Plan Info
          <Button
            style={{ color: "#009ce5" }}
            onClick={() => props.isEdit(true)}
          >
            EDIT
          </Button>
        </h3>
        <Grid container>
          <Grid item xs={6}>
            <div>
              {`Api Access: `}
              {plan.api ? "Enabled" : "Disabled"}
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>
              {`Ifta Access: `}
              {plan.ifta ? "Enabled" : "Disabled"}
            </div>
            {plan.plan && Array.isArray(plan.plan) && (
              <div>
                {`Plan: `}
                {plan.plan.annual ? "Annual" : ""}
                {plan.plan.monthly ? "Monthly" : ""}
              </div>
            )}
          </Grid>
          {props.notes && (
            <Grid item xs={12}>
              <h4>Notes: {props.notes}</h4>
            </Grid>
          )}
        </Grid>

        <Typography>
          <span>Dues</span>
        </Typography>
      </Grid>
      <Grid container>
        {dues.length > 0
          ? dues.map((item, idx) => {
              let textCol = "black";
              if (moment(item.date) < moment()) {
                textCol = "red";
              } else if (moment(item.date) <= moment().endOf("month")) {
                textCol = "orange";
              }
              return (
                <Grid item xs={4} key={`${idx}`}>
                  <div style={{ color: textCol }}>
                    {`${idx + 1}. Truck# `}
                    {item.truck} {"is due on "}
                    {props.formatDate(item.date)}{" "}
                  </div>
                </Grid>
              );
            })
          : "No dues found!"}
      </Grid>
    </Grid>
  );
}

export class ViewAccountDetail extends React.Component {
  state = {
    loading: true,
    isEditOpen: false,
    dataLoading: false,
  };

  async componentDidMount() {
    const accountId = _get(this, "props.match.params.accountId");
    await this.handleDetails(accountId);
  }

  handleDetails = async (accountId) => {
    this.setState({ loading: true });
    const selectedAccountData = await this.props.getAccountDetails({
      accountId,
    });
    const resellerId = _get(selectedAccountData, "account.resellerId");
    if (resellerId) {
      this.handleReseller(resellerId);
    }
    this.setState({ selectedAccountData, loading: false });
  };

  handleReseller = async (id) => {
    try {
      const reseller = await this.props.getResellerById({ id });
      console.log("reseller :: ", reseller);
      this.setState({ syncing: false });
    } catch (e) {
      console.log(e);
    }
  };

  handleLogin = async (user) => {
    this.setState({ loading: true });

    const selectedAccountData = this.state.selectedAccountData || {};
    const selectedAccount =
      this.props.selectedAccount && this.props.selectedAccount._id
        ? this.props.selectedAccount
        : selectedAccountData.account || {};

    const accountId = selectedAccount._id;
    const carrierId = selectedAccount.carrierId;
    const terminalId = selectedAccount.terminalId;

    const response = await this.props.getAccountAuth({
      accountId,
      carrierId,
      terminalId,
      userId: user.id,
    });

    cookies.set("token", response.token, {
      path: "/",
      maxAge: 43200,
    });

    await this.props.getProfile();

    // setTimeout(() => {
    const { accountBaseUrl } = this.props;
    this.props.history.push(`${accountBaseUrl}/status/view`);
    // }, 100);
    this.setState({ loading: false });
  };

  isEdit = (value) => {
    this.setState({ isEditOpen: value });
  };

  handleAddFormSave = async () => {
    const accountId = _get(this, "props.match.params.accountId");
    await this.handleDetails(accountId);
    this.setState({ openAddForm: false, isEditOpen: false });
  };

  sync = async () => {
    this.setState({ syncing: true });
    const accountId = _get(this, "props.match.params.accountId");
    await this.props.syncRedis({ accountId });
    this.setState({ syncing: false });
  };

  render() {
    const { classes, accountAuthBusy } = this.props;
    const { openAddForm, isEditOpen, loading, syncing } = this.state;
    const selectedAccountData = this.state.selectedAccountData || {};
    const selectedAccount = selectedAccountData.account || {};

    let adminStatus = {
      activeDriver: 0,
      inactiveDriver: 0,
      activeUser: 0,
      inactiveUser: 0,
    };
    let equipStatus = {
      activeVehicle: 0,
      inactiveVehicle: 0,
    };
    if (!selectedAccount) {
      return null;
    }

    const admins = selectedAccountData.admins || [];
    const equipments = selectedAccountData.equipments || [];
    const plan = selectedAccount.plan || {};

    const eqpAllowed = (plan.vehicles && plan.vehicles.allowed) || 0;
    Array.isArray(equipments) &&
      equipments.forEach((item) => {
        if (item.assetType === "VEHICLE") {
          if (item.active) {
            equipStatus.activeVehicle += 1;
          } else {
            equipStatus.inactiveVehicle += 1;
          }
        }
      });

    Array.isArray(admins) &&
      admins.forEach((item) => {
        if (item.role === "DRIVER") {
          if (item.status === "ACTIVE") {
            adminStatus.activeDriver += 1;
          } else {
            adminStatus.inactiveDriver += 1;
          }
        } else {
          if (item.status === "ACTIVE") {
            adminStatus.activeUser += 1;
          } else {
            adminStatus.inactiveUser += 1;
          }
        }
      });

    return (
      <div>
        {openAddForm && (
          <AddUserForm
            open={openAddForm}
            handleClose={() => {
              this.setState({ openAddForm: false });
            }}
            handleSave={this.handleAddFormSave}
          />
        )}

        {isEditOpen && (
          <EditPlanForm
            open={isEditOpen}
            userPlan={plan}
            handleClose={() => this.setState({ isEditOpen: false })}
            handleSave={this.handleAddFormSave}
          />
        )}
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <div id="alert-dialog-title" style={{ textAlign: "center" }}>
              <p
                style={{
                  marginTop: 0,
                  fontWeight: 600,
                  fontSize: "17px",
                }}
              >
                {selectedAccount.name}
                <Button
                  disabled={syncing}
                  style={{ color: "#009ce5" }}
                  onClick={() => this.sync(true)}
                >
                  {syncing ? "Syncing..." : "Sync"}
                </Button>
              </p>
              <IconButton
                style={{ marginTop: 5 }}
                aria-label="Close"
                className={classes.closeButton}
                onClick={this.props.handleClose}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div style={{ marginTop: 25 }}>
              <RenderDetails
                userActive={adminStatus.activeUser}
                userInActive={adminStatus.inactiveUser}
                driverActive={adminStatus.activeDriver}
                driverInActive={adminStatus.inactiveDriver}
                eqpActive={equipStatus.activeVehicle}
                eqpInActive={equipStatus.inactiveVehicle}
                eqpAllowed={eqpAllowed}
              />
              <RenderPlan
                plan={plan}
                notes={selectedAccountData?.account?.notes}
                isEdit={this.isEdit}
                formatDate={this.props.formatDate}
              />
              <Grid
                item
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "30px",
                }}
              >
                <Typography>
                  <span>Managers</span>
                </Typography>

                <Button
                  variant="contained"
                  color="primary"
                  className={classes.addUser}
                  onClick={() => this.setState({ openAddForm: true })}
                >
                  {`ADD USER`}
                </Button>
              </Grid>
              <Grid container spacing={2}>
                {admins.map((user, i) => {
                  if (user.role !== "MANAGER") {
                    return null;
                  }
                  return (
                    <RenderUsers
                      accountAuthBusy={accountAuthBusy}
                      handleLogin={this.handleLogin}
                      classes={classes}
                      key={i}
                      user={user}
                      changeStatus={this.changeStatus}
                      loading={this.state.loading}
                      getAccountDetails={this.props.getAccountDetails}
                      updateUserAccount={this.props.updateUserAccount}
                      handleClose={this.props.handleClose}
                    />
                  );
                })}
              </Grid>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    accountBaseUrl: _get(state, "authData.accountBaseUrl"),
    selectedAccount: _get(state, "accountData.selectedAccount", {}),
    accountAuthBusy: _get(state, "accountData.accountAuthBusy", false),
    selectedAccountData: _get(state, "accountData.selectedAccountData", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAccountAuth,
      getProfile,
      updateUserAccount,
      getAccountDetails,
      formatDate,
      syncRedis,
      getResellerById,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewAccountDetail))
);

import React, { useState, useCallback } from "react";
import Button from "@material-ui/core/Button";
import { useDropzone } from "react-dropzone";

function ImageUpload({ imageUrl, imageName = "Logo", onChange = () => {} }) {
    const [imageData, setImageData] = useState();
    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach(file => {
            const reader = new FileReader();

            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            reader.onload = () => {
                const binaryStr = reader.result;
                setImageData(URL.createObjectURL(file));
                onChange(file, binaryStr);
            };
            reader.readAsArrayBuffer(file);
        });

        // eslint-disable-next-line
    }, []);
    const { getRootProps, getInputProps } = useDropzone({
        onDrop
    });

    return (
        <div {...getRootProps()} style={{ textAlign: "center" }} id="testt">
            {imageData ? (
                <img
                    style={{ maxWidth: 100 }}
                    id={imageName}
                    src={imageData}
                    alt={imageName}
                />
            ) : (
                <div>
                    <img
                        style={{ maxWidth: 100 }}
                        id={imageName}
                        src={imageUrl}
                        alt={imageName}
                    />
                    <div>
                        <Button color="primary">
                            <input {...getInputProps()} />
                            {"Select"}
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ImageUpload;
